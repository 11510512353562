import './App.css';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import DashboardRouter from './DashboardRouter';
import ErrorComponent from './Errorbar';
import { useEffect, useState } from 'react';
import ProtectedRoute from './ProtectedRoutes';
import SignIn from './SignIn';
// import { initInstance } from './Services/signin/axios';
import { useDispatch, useSelector } from 'react-redux';
import { env , jwttoken, emailid} from './env';
// import env from "./env";
import { setauth } from './Services/signin/signin.slice';
import Login from './Login';
import ForgotpassRequest from './ForgotpassRequest';
import Forgotpass from './Forgotpass';
import CollaborationActive from './CollaborationActive';
import { initDispatch } from './Services/signin/axios';
import { selectschemaactive } from './Services/schemas/schema.slice';


function App() {

    const [initialized, setInitialized] = useState(false);

    const [workspaceinit, setWorkspaceinit] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>{
       if(!initialized){
            const auth = localStorage.getItem("auth");
            const defaultworkspace = localStorage.getItem("defaultworkspace");
            const username = localStorage.getItem("email");
            if(auth&&defaultworkspace!=""){
                dispatch(setauth({
                    auth: auth,
                    workspaceId: defaultworkspace,
                    email: username
                }))
            }
            initDispatch(dispatch);
            setInitialized(true);
       } 
    },[])

  

    // useEffect(()=>{
    //     if(workspaceinit){

    //     }
    // },[])



    return (
        <div className="App">
        <div className="app-main-container">
            <ErrorComponent></ErrorComponent>
            <div className="container">
            <BrowserRouter>
                <Routes>
                <Route path="/signin" element={<SignIn></SignIn>}></Route>
                <Route path="/signin/:source" element={<SignIn></SignIn>}></Route>
                <Route path="/login" element={<Login></Login>}></Route>
                <Route path="/forgotpass" element={<ForgotpassRequest></ForgotpassRequest>}></Route>
                <Route path="/changepassword/:forgottoken" element={<Forgotpass></Forgotpass>}></Route>
                <Route path="/make/collaboration/:collaborationId" element={<CollaborationActive></CollaborationActive>}></Route>
                <Route path="/*" element={
                                            <ProtectedRoute>
                                                    <DashboardRouter></DashboardRouter>
                                            </ProtectedRoute>
                                            }></Route>
                
                </Routes>
            </BrowserRouter>
            </div>
        </div>
        </div>
    );
}

export default App;
