import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../Services/error.slice";
import { IconButton } from "@mui/material";
import * as _ from "lodash";
import {conditionalActions} from "../Settings";
import {ValueCompareToField, ValuetoCompareField} from "../Motors/blocks/ConditionalStageBlock";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import { selectAppTemplates, getAppTemplates, setElement } from "../../Services/apptemplates/apptemplates.slice";

const RouteCondition = ({
    route,
    setRoute,
    templateid
})=>{
    const setConditions = (conditions)=>{
        let routeCopy = {...route,
                         conditions: conditions
                        }
        setRoute(routeCopy);
    }


    const selectCondition = (blockindex, conditionindex, condition)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      condition: condition
                                                     }
        setConditions(conditionsCopy);
    }

    const setGetValuetocompare = (blockindex, conditionindex)=>{
        return (val, type)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
            let allconditions = [...conditionalActions];
            let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type==type})
            let options = [...allconditions[selectedtypeindex].operators];
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                          valuetocompare:{"action": "get", "val": val.schemaval},
                                                          options: options,
                                                          "comparetotype": type
                                                         }
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValuetocompare = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
        let allconditions = [...conditionalActions];
        let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type=="string"})
        let options = [...allconditions[selectedtypeindex].operators];
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      valuetocompare:{"action": "const", "val": val},
                                                      options: options,
                                                      "comparetotype": "string"
                                                    }
        setConditions(conditionsCopy);
    }

    const getValueToCompare = (blockindex, conditionindex)=>{
        if(route.conditions[blockindex][conditionindex].valuetocompare.val){
            return route.conditions[blockindex][conditionindex].valuetocompare.val;
        }else{
            return ""
        }
        
    }

    const setGetValueCompareTo = (blockindex, conditionindex)=>{
        return (val)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                            valuecompareto:{"action": "get", "val": val.schemaval}
                                                         }    
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValueCompareTo = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                        valuecompareto:{"action": "const", "val": val}
                                                     }
        setConditions(conditionsCopy);
    }

    const getValueCompareTo = (blockindex, conditionindex)=>{
        if(route.conditions[blockindex][conditionindex].valuecompareto.val){
            return route.conditions[blockindex][conditionindex].valuecompareto.val
        }else{
            return "";
        }
    }

    const deleteConditionBlock = (i)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
        conditionsCopy.splice(i,1);
        setConditions(conditionsCopy);
    }

    const deleteCondition = (blockindex, conditionindex)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
        let conditionBlockCopy= [...conditionsCopy[blockindex]];
        conditionBlockCopy.splice(conditionindex, 1);
        conditionsCopy[blockindex] = conditionBlockCopy;
        setConditions(conditionsCopy);    
    }

    const addConBlock = ()=>{
        let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
        conditionsCopy.push([]);
        setConditions(conditionsCopy);
    }

    const addCondition = (blockindex)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(route.conditions));
        conditionsCopy[blockindex].push(
            {
                "valuetocompare":"",
                "tocompareopen":false,
                "comparetotype": "any",
                "options":[],
                "condition": "",
                "valuecompareto":"",
                "comparetoopen":false
            }   
        )
        setConditions(conditionsCopy);
    }

    return (
        <>
            {route.conditions.map((conblock, i)=>{
                return (
                    <>
                        {i==0?
                            <div>Only apply if</div>:
                            <div className="or-block-desc">
                                <div>Or apply if</div>
                                <div>
                                    <IconButton onClick={()=>{deleteConditionBlock(i)}}>
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                        }
                        {conblock.map((con, j)=>{
                            return(
                                    <div className="condition-line">

                                        <div>
                                            <ValuetoCompareField
                                                i={i}
                                                j={j}
                                                setConstValuetocompare={setConstValuetocompare}
                                                setGetValuetocompare={setGetValuetocompare}
                                                pipelinestage={0}
                                                pipelinetype={""}
                                                getValueToCompare={getValueToCompare}
                                                source={"appprops"} 
                                                templateid={templateid}                                           
                                            >
                                            </ValuetoCompareField>
                                        </div>
                                        <div>
                                            <FormControl sx={{width: 200}}>
                                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={con.condition}
                                                    label="Condition"
                                                    onChange={(e)=>{selectCondition(i,j, e.target.value)}}
                                                >
                                                    {con.options.map((op)=>{
                                                        return(
                                                            <MenuItem value={op.value}>{op.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <ValueCompareToField
                                                i={i}
                                                j={j}
                                                getValueCompareTo={getValueCompareTo}
                                                setConstValueCompareTo={setConstValueCompareTo}
                                                setGetValueCompareTo={setGetValueCompareTo}
                                                pipelinestage={undefined}
                                                pipelinetype={""}
                                                templateid={templateid}
                                                source={"appprops"}
                                            >
                                            </ValueCompareToField>
                                        </div>
                                        <div className="close-button" onClick={()=>{deleteCondition(i,j)}}>
                                            <span class="material-symbols-outlined">
                                                close
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {i==(route.conditions.length-1)?
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addConBlock()}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            Or
                                    </div>
                                </div>
                            </>:
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                </div>
                            </>
                        }
                        <div style={{height:"2px" , backgroundColor:"#eee", "width": "100%"}}></div>
                    </>
                )
            })}
        
        </>
    )
}

const RouteBlock = ({
                        route, 
                        setRoute,
                        templateid,
                        removeRoute
                    })=>{

    const dispatch = useDispatch();

    const {__source, __id} = useParams();

    const setPath = (val)=>{
        if(val.startsWith("/")){
            let routeCopy = {...route,
                path: val
               }
            setRoute(routeCopy);
        }else{
            dispatch(showError("Path should startwith a /"))
        }
    }

    const setParam = (val, index)=>{
        let paramsCopy = [...route.params];
        paramsCopy[index] = val;
        let routeCopy = {...route,
                         params: paramsCopy
                        }
        setRoute(routeCopy);
    }

    const addParam = ()=>{
        let paramsCopy = [...route.params];
        paramsCopy.push("");
        let routeCopy = {...route,
                         params: paramsCopy
                        }
        setRoute(routeCopy)
    }

    const removeParam = (index)=>{
        let paramsCopy = [...route.params];
        paramsCopy.splice(index, 1);
        let routeCopy = {...route,
                         params: paramsCopy
                        }
        setRoute(routeCopy);
    }

    const setFallback = (val)=>{
        let routeCopy = {...route,
                         fallback: val
                        }
        setRoute(routeCopy);
    }

    const apptemplates = useSelector(selectAppTemplates);


    useEffect(()=>{
        if(__source==undefined){
            return
        }
        dispatch(getAppTemplates(__source))
    },[__source])


    const selectTemplate = (val)=>{
        let templateIndex = _.findIndex(apptemplates, (template)=>{return template._id==val});
        let routeCopy = {...route,
                         template: val,
                         templatename: apptemplates[templateIndex]?.name
                        }
        setRoute(routeCopy)
    }

    const changeRouteType = (val)=>{
        let routeCopy = {...route,
                         type: val
                        }
        setRoute(routeCopy)
    }


    return (
        <div style={{padding: "5px" , boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <div>
                    path: <input value={route.path} onChange={(e)=>{setPath(e.target.value)}}></input>
                </div>
                <div>
                    <IconButton onClick={()=>{changeRouteType("block")}}>
                        <span className={route.type=="block"?"material-symbols-outlined active":'material-symbols-outlined'}>stack</span>
                    </IconButton>
                    <IconButton onClick={()=>{changeRouteType("conditional")}}>
                        <span className={route.type=="conditional"?"material-symbols-outlined active":"material-symbols-outlined"}>device_hub</span>
                    </IconButton>
                    <IconButton onClick={()=>{removeRoute()}}>
                        <span className="material-symbols-outlined">
                            delete
                        </span>
                    </IconButton>
                </div>
            </div>
            <div>
                    template: 
                    <select
                        value={route.template}
                        onChange={(e)=>{
                            selectTemplate(e.target.value)
                        }}

                    >
                        <option>
                        </option>
                        {apptemplates.map((temp)=>{
                            return (
                                <option value={temp._id}>
                                    {temp.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
            <div>
                <div>
                    params:
                </div> 
                {route.params.map((param, index)=>{
                    return (
                        <div style={{padding:"5px", display: "flex"}}>
                            <input value={param} onChange={(e)=>{setParam(e.target.value, index)}}></input>
                            <IconButton onClick={()=>{removeParam(index)}}>
                                <span className="material-symbols-outlined">
                                    delete
                                </span>
                            </IconButton>
                        </div>
                    )
                })}
                <div style={{display: "flex", justifyContent: "center"}}>
                    <IconButton onClick={()=>{addParam()}}>
                        <span className="material-symbols-outlined">
                            add
                        </span>
                    </IconButton>
                </div>
                
            </div>
            {route.type=="conditional"&&
                <div>
                    <RouteCondition
                        route={route}
                        setRoute={setRoute}
                        templateid={templateid}
                    >
                    </RouteCondition>
                </div>
            }
            {route.type=="conditional"&&
                <div>
                    fallback path: <input value={route.fallback} onChange={(e)=>{setFallback(e.target.value)}}></input>
                </div>
            }

        </div>
    )
}

const RouterProps = ({
    element,
    position,
    templateid
})=>{

    // const [routes, setRoutes] = useState([{
    //     "type": "block",
    //     "path": "/",
    //     "params":[],
    //     "conditions":[[]],
    //     "fallback": "/",
    //     "template": ""
    // }]);


    const dispatch = useDispatch();


     const setRoutes = (routesCopy)=>{
        let elementCopy = {...element,
                            routes: routesCopy
                            }
        dispatch(setElement(
            {
                element: elementCopy,
                position: position
            }
        ))
        
        
     }


    const setRoute = (index)=>{
        return (route)=>{
            let routesCopy = [...element.routes];
            routesCopy[index] = route;
            setRoutes(routesCopy);
        }
    }

    const addRoute = ()=>{
        let routesCopy = [...element.routes];
        routesCopy.push({
            "type": "block",
            "path": "/",
            "params":[],
            "conditions":[[]],
            "fallback": "/"
        })
        setRoutes(routesCopy);
    }

    const removeRoute = (index)=>{
        return ()=>{
            let routesCopy = [...element.routes];
            routesCopy.splice(index, 1);
            setRoutes(routesCopy)
        }
    }


    return (
        <div>
            {element.routes.map((route, index)=>{
                return (
                    <RouteBlock
                        route={route}
                        setRoute={setRoute(index)}
                        removeRoute = {removeRoute(index)}
                    >
                    </RouteBlock>
                )
            })}
            <div style={{display: "flex", justifyContent: "center"}}>
                <IconButton onClick={()=>{addRoute()}}>
                    <span className="material-symbols-outlined">
                        add
                    </span>
                </IconButton>
            </div>
        </div>
    )
}

export default RouterProps;