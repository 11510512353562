import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
            getTemplatesApi, 
            getTemplateDetailsApi, 
            updateTextTemplateApi, 
            createTemplateApi, 
            deleteTemplateApi, 
            getInterfaceTemplatesApi, 
            getTemplateSchemaApi, 
            deployTemplateApi, 
            getInterfaceNonJsTemplatesApi
        } from "./template.service";
import { showError } from "../error.slice";

export const getTemplates = createAsyncThunk(
    "templates/gettemplates",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getTemplatesApi(payload);
            return res;
        }catch(err){
            throw err;
        }
    }
)

export const deployTemplate = createAsyncThunk(
    "templates/deploytemplate",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deployTemplateApi(payload.id);
            dispatch(getTemplates(payload.workspace));
            dispatch(showError("Template deployed successfully."))
            return res;
        }catch(error){
            dispatch(showError("Error deploying templates."));
            throw error;
        }
    }
)

export const getTextTemplate = createAsyncThunk(
    "templates/gettexttemplate",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getTemplateDetailsApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error getting templates."));
            throw err;
        }
    }
)

export const updateTextTemplate = createAsyncThunk(
    "templates/updatetexttemplate",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateTextTemplateApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error updating templating."));
            throw err;
        }
    }
)

export const createTemplate = createAsyncThunk(
    "templates/createtemplates",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createTemplateApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error creating template."));
            throw err;
        }
    }
)

export const deleteTemplate = createAsyncThunk(
    "templates/deletetemplate",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteTemplateApi(payload);
            dispatch(showError("Template deleted successfully."));
            return res;
        }catch(err){
            dispatch(showError("Error deleting template."));
            throw err;
        }
    }
)

export const getInterfaceTemplates = createAsyncThunk(
    "templates/getinterfacetemplates",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getInterfaceTemplatesApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting templates."));
            throw error;
        }
    }
)

export const getTemplateSchema = createAsyncThunk(
    "templates/gettemplatesschema",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getTemplateSchemaApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting templates schema."));
            throw error;
        }   
    }
)

export const getInterfaceNonJsTemplates = createAsyncThunk(
    "templates/getinterfaacenonjstemplates",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getInterfaceNonJsTemplatesApi(payload);
            return res;
        }catch(error){
            dispatch(showError(""));
            throw error;
        }
    }
)

const templateSlice = createSlice({
    "name": "templates",
    "initialState": {
        "templates":[],
        "nonjstemplates": [],
        "texttemplate": null,
        "templatecreated": false,
        "templatedeleted": false,
        "interfaces":[],
        "interfaceschema":[],
        "templateworkspacepermission": ""

    },
    reducers:{
        resetTemplatecreated: (state, action)=>{
            state.templatecreated = action.payload;
        },
        resetTemplatedeleted: (state, action)=>{
            state.templatedeleted = false;
        },
        resetTextTemplate: (state, action)=>{
            state.texttemplate = null;
        },
        resetNonjsTemplates: (state, action)=>{
            state.nonjstemplates = [];
        }
    },
    "extraReducers":(builder)=>{
        builder.addCase(getTemplates.fulfilled, (state, action)=>{
            state.templates = action.payload.templatelist;
            state.templateworkspacepermission = action.payload.access;
        })
        .addCase(getTextTemplate.fulfilled, (state, action)=>{
            state.texttemplate = action.payload;
        })
        .addCase(createTemplate.fulfilled, (state, action)=>{
            state.templatecreated = action.payload;
        })
        .addCase(updateTextTemplate.fulfilled, (state, action)=>{

        })
        .addCase(deleteTemplate.fulfilled, (state, action)=>{
            state.templatedeleted = true;
        })
        .addCase(getInterfaceTemplates.fulfilled, (state, action)=>{
            state.interfaces = action.payload
        })
        .addCase(getTemplateSchema.fulfilled,(state, action)=>{
            state.interfaceschema = action.payload;
        })
        .addCase(getInterfaceNonJsTemplates.fulfilled, (state, action)=>{
            state.nonjstemplates = action.payload;
        })
        .addCase(deployTemplate.fulfilled,(state,action)=>{

        })
    }
})

export const {resetTemplatecreated, resetTemplatedeleted, resetTextTemplate} = templateSlice.actions;

export const selectTemplates = (state)=> state.templates.templates;
export const selectTextTemplate = (state) => state.templates.texttemplate;
export const selectTextTemplateCreated = (state) => state.templates.templatecreated;
export const selectTemplateDeleted = (state) => state.templates.templatedeleted;
export const selectInterfaceTemplates = (state) => state.templates.interfaces;
export const selectInterfaceschema = (state) => state.templates.interfaceschema;
export const selectTempateworkspacepermission = (state) => state.templates.templateworkspacepermission;
export const selectNonjstemplates = (state) => state.templates.nonjstemplates;

export default templateSlice.reducer;