import { createSlice, createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import { 
        createWebsiteApi, 
        createWebsiteTemplateApi, 
        deleteWebsiteApi, 
        getAvailableHostsApi, 
        getWebsiteCountApi, 
        getWebsiteCreateOptionsApi, 
        getWebsiteDetailsApi, 
        getWebsiteListApi, 
        getWebsiteTemplatesApi, 
        updateWebsiteApi,
        downloadFavicon,
        getWebsiteCreateWorkspaceOptionsApi,
        getWebsiteCreateWebsiteOptionsApi
    } from "./website.service";

export const getAvailableHosts = createAsyncThunk(
    "website/getavailablehosts",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getAvailableHostsApi();
            return res;
        }catch(error){
            dispatch(showError("Error getting website host"));
        }
    }
)

export const createWebsite = createAsyncThunk(
    "website/createwebsite",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createWebsiteApi(payload);
            dispatch(getWebsiteList(payload.workspace));
            return res;
        }catch(error){
            dispatch(showError("Error creating websites"));
            throw error;
        }
    }
)

export const getWebsiteList = createAsyncThunk(
    "website/getwebsitelist",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteListApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting wesbites"));
            throw error;
        }
    }
)


export const getWebsiteCount = createAsyncThunk(
    "website/getwebsitecount",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteCountApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting website count"));
            throw error;
        }
    }
)

export const getWebsiteDetails = createAsyncThunk(
    "website/getwebsitedetails",
    async(payload, {dispatch, rejectWithValue})=>{
        try{    
            let res = await getWebsiteDetailsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting webiste details"));
            throw error;
        }
    }
)

export const deleteWebsite = createAsyncThunk(
    "website/deletewebsite",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteWebsiteApi(payload.id);
            dispatch(getWebsiteList(payload.workspace));
            return res;
        }catch(error){
            dispatch(showError("Error deleting website"))
        }
    }
)

export const updateWebsite = createAsyncThunk(
    "website/updatewebsite",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateWebsiteApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating website, please refresh and update again !!!"));
            throw error;
        }
    }
)

export const updateWebsitedetails = createAsyncThunk(
    "website/updatewebsitedetails",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateWebsiteApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating the website details"));
            throw error;
        }
    }
)

export const getWebsiteTemplates = createAsyncThunk(
    "website/getwebsitetemplates",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteTemplatesApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting templates!!!"));
            throw error;
        }
    }
)

export const getWebsiteCreateOptions = createAsyncThunk(
    "website/getwebsitecreateoptions",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteCreateOptionsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting creating website options"));
            throw error;
        }
    }
)

export const getWebsiteCreateWorkspaceOptions = createAsyncThunk(
    "website/getwebsitecreateworkspaceoptions",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteCreateWorkspaceOptionsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting workspace create options"));
            throw error;
        }
    }
)

export const getWebsiteCreateWebsiteOptions = createAsyncThunk(
    "website/getwebsitecreatewebsiteoptions",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteCreateWebsiteOptionsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting website create options"));
            throw error;            
        }
    }
)

export const createWebsiteTemplate = createAsyncThunk(
    "website/createwebsotetemplate",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createWebsiteTemplateApi(payload);
            dispatch(getWebsiteTemplates(payload.source));
            return res;
        }catch(error){
            dispatch(showError("Error creating template"));
            throw error;
        }
    }
)

export const saveFavicon = createAsyncThunk(
    "website/savefavicon",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let websitedetail = {...payload.websitedetail}
            let url = await downloadFavicon({url: websitedetail.favicon, name: payload.faviconname});
            websitedetail = {...websitedetail,
                                 favicon: url
                                }
            dispatch(updateWebsitedetails(websitedetail));
            return {
                "status": true
            }

        }catch(error){
            dispatch(showError("Error saving favicon"));
            throw error;
        }
    }
)


const websiteSlice = createSlice({
    name: "website",
    initialState:{
        "availablehosts":[],
        "createwebsiteres": null,
        "websites":[],
        websiteworkspacepermission: "",
        websiteworkspaceshared: true,
        "websitecount": 0,
        "websitedetails": null,
        "websitedeleted": null,
        "websitetemplates": [],
        "websiteupdated": null,
        "websitecreateoptions": null,
        "websitetemplatecreated": null,
        "websitecreateworkspaceoptions": [],
        "websitecreatewebsiteoptions": []
    },
    reducers:{
        "resetcreatewebsiteres": (state, action)=>{
            state.createwebsiteres = null;
        },
        "resetwebsitedeleted": (state, action)=>{
            state.websitedeleted = null;
        },
        "resetwebisteupdated": (state, action)=>{
            state.websiteupdated = null;
        },
        "resetwebsitecreateoption": (state, action)=>{
            state.websitecreateoptions = null;
        },
        "resetwebsitetemplatecreated": (state, action)=>{
            state.websitetemplatecreated = null;
        },
        resetwebsitecreateworkspaceoptions: (state, action) => {
            state.websitecreateworkspaceoptions = [];
        },
        resetwebsitecreatewebsiteoptions: (state, action) => {
            state.websitecreatewebsiteoptions = [];
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getAvailableHosts.fulfilled, (state, action)=>{
            state.availablehosts = action.payload;
        })
        builder.addCase(createWebsite.fulfilled, (state, action)=>{
            state.createwebsiteres = action.payload;
        })
        builder.addCase(getWebsiteList.fulfilled, (state, action)=>{
            state.websites = action.payload.websitelist;
            state.websiteworkspacepermission = action.payload.access;
            state.websiteworkspaceshared = action.payload.shared;
        })
        builder.addCase(getWebsiteCount.fulfilled, (state, action)=>{
            state.websitecount = action.payload.count;
        })
        builder.addCase(getWebsiteDetails.fulfilled, (state, action)=>{
            state.websitedetails = action.payload;
        })
        builder.addCase(deleteWebsite.fulfilled, (state, action)=>{
            state.websitedeleted = true;
        })
        builder.addCase(getWebsiteTemplates.fulfilled, (state, action)=>{
            state.websitetemplates = action.payload;
        })
        builder.addCase(updateWebsitedetails.fulfilled,(state, action)=>{
            state.websiteupdated = true;
        })
        builder.addCase(getWebsiteCreateOptions.fulfilled,(state, action)=>{
            state.websitecreateoptions = action.payload;
        })
        builder.addCase(createWebsiteTemplate.fulfilled, (state, action)=>{
            state.websitetemplatecreated = true;
        })
        builder.addCase(saveFavicon.fulfilled, (state, action)=>{

        })
        builder.addCase(getWebsiteCreateWorkspaceOptions.fulfilled, (state, action)=>{
            state.websitecreateworkspaceoptions = action.payload;
        })
        builder.addCase(getWebsiteCreateWebsiteOptions.fulfilled, (state, action)=>{
            state.websitecreatewebsiteoptions = action.payload;
        })
    }
})


export const selectAvailableHosts = (state)=> state.website.availablehosts;

export const selectCreateWebsiteRes = (state) => state.website.createwebsiteres;

export const selectWebsiteList = (state) => state.website.websites;

export const selectWebsiteCount = (state) => state.website.websitecount;

export const selectWebsiteDetails = (state) => state.website.websitedetails;

export const selectWebsitedeleted = (state) => state.website.websitedeleted;

export const selectWebsiteTemplates = (state) => state.website.websitetemplates; 

export const selectWebsiteUpdated  = (state) => state.website.websiteupdated; 

export const selectWebsiteCreateOption = (state) => state.website.websitecreateoptions;

export const selectWebsitetemplateCreated = (state) => state.website.websitetemplatecreated;

export const selectWebsiteworkspacepermission = (state) => state.website.websiteworkspacepermission;

export const selectWebsiteworkspaceshared = (state) => state.website.websiteworkspaceshared;

export const selectWebsitecreateworkspaceoptions = (state) => state.website.websitecreateworkspaceoptions;

export const selectWebsitecreatewebsiteoptions = (state) => state.website.websitecreatewebsiteoptions;

export const {
                resetcreatewebsiteres,
                resetwebsitedeleted,
                resetwebisteupdated,
                resetwebsitecreateoption,
                resetwebsitetemplatecreated,
                resetwebsitecreatewebsiteoptions,
                resetwebsitecreateworkspaceoptions
            } = websiteSlice.actions;

export default websiteSlice.reducer;