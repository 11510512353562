import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { TextField, Button, IconButton } from "@mui/material";
import Toiler from "./Images/logo1.png";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, resetchangepassword, selectpasswordchanged } from "./Services/signin/signin.slice";
import { showError } from "./Services/error.slice";


const Forgotpass = ()=>{

    const {forgottoken} =useParams();

    const [password, setPassword] = useState("");

    const [passwordc, setpasswordc] = useState("");

    const [passwordvisibility, setpasswordvisibility] = useState("password");

    const [passwordmatched, setpasswordmatched] = useState(false);

    useEffect(()=>{
        if(password==""){
            return
        }
        if(password==passwordc){
            setpasswordmatched(true)
        }
    },[password, passwordc])


    const dispatch = useDispatch();

    const changepasswordaction = ()=>{
        let payload = {
            "password": password,
            "requestId": forgottoken
        }
        dispatch(changePassword(payload));
    }


    useEffect(()=>{
        dispatch(resetchangepassword())
    },[])


    const passwordchanged = useSelector(selectpasswordchanged);

    const navigate = useNavigate();

    useEffect(()=>{
        if(passwordchanged){
            dispatch(showError("Password has been changed"));
            navigate("/main");
        }
    },[passwordchanged])


    const navigatetomain = ()=>{
        navigate("/main")
    }

    return (
        <div style={{display: "flex", justifyContent:"center", marginTop: 200 }}>
            <div style={{width: 500}}>
                <div style={{display: "flex", justifyContent:"center", marginBottom: 20}} onClick={()=>{navigatetomain()}}>
                    <img src={Toiler}></img>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div>
                        <div className="toiler-signup-inputs">
                            <TextField label="Password" type={passwordvisibility} value={password} onChange={(e)=>{setPassword(e.target.value)}}></TextField>
                            {passwordvisibility=="text"&&
                                <IconButton onClick={()=>{setpasswordvisibility("password")}} style={{position: "absolute", right: "0px", margin: "10px"}}>
                                    <span className="material-symbols-outlined">
                                        visibility
                                    </span>
                                </IconButton>
                            }
                            {passwordvisibility=="password"&&
                                <IconButton onClick={()=>{setpasswordvisibility("text")}} style={{position: "absolute", right: "0px", margin: "10px"}}>
                                    <span className="material-symbols-outlined">
                                        visibility_off
                                    </span>
                                </IconButton>
                            }
                        </div>
                        <div className="toiler-signup-inputs">
                            <TextField label="Confirm Password" type="password" value={passwordc} onChange={(e)=>{setpasswordc(e.target.value)}}></TextField>
                                <IconButton style={{position: "absolute", right: "0px", margin: "10px"}}>
                                    <span className={passwordmatched?"material-symbols-outlined checked":"material-symbols-outlined"}>
                                        check
                                    </span>
                                </IconButton>
                        </div>
                        <div className="toiler-signup-button">
                            <div>
                                <Button 
                                    disabled={!passwordmatched} 
                                    style={{color: "#3A07CD"}}
                                    onClick={()=>{changepasswordaction()}}
                                >Change Password</Button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default Forgotpass;