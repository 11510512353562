import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import {
        testActionApi, 
        createPipelineApi, 
        updatePipelineApi, 
        getPipelinebySchemaApi, 
        getPiplelineByIdApi,
        deletePipelineByIdApi,
        uploadFileApi,
        getPipelineTriggersBySchemaApi,
        testPipelineApi,
        deployPipelineApi,
        activatePipelineApi,
        pausePipelineApi,
        getInputStageApi,
        runPipelineApi,
        reorderpipelines,
        updatePipelineApiF,
        getNextSchemaApi
       }
from "./pipeline.service";

import {executeLoop} from "./server/pipeline";
import { createTempVarSnapshot } from "./server/upipeline";

import {
            setTemplateVariable, 
            getAppObjectVal, 
            getLocalStorageSchema, 
            getSessionStorage, 
            generateLoopVaraibleP
        } from "../apptemplates/apptemplates.slice";

import * as _ from "lodash";
import {Delineatedpipeline, parseSchemaKey,getValfromPipe, replaceSchemaKeyIndex, getParentRef, repopulatePipeline, getOutput, setDelineatedPipelinekey} from "../../apps/utils";
import { loading, loadingdone } from "../loader/loader.slice";
import { generateSchema } from "./server/utils";

const getLastStageIndex = (laststage)=>{
    let stageindex = laststage.stageindex;
    if(laststage.type=="conditional"){
        stageindex = laststage.conditions[(laststage.conditions.length-1)].pipeline[(laststage.conditions[(laststage.conditions.length-1)].pipeline.length-1)].stageindex;
    }
    if(laststage.type=="loop"){
        stageindex = laststage.loop[laststage.loop.length-1].stageindex;
    }
    return stageindex+1;
}

const getKeystobeUpdated = (schemamapping,schema, keystobeupdated)=>{
    for(let i=0; i< schemamapping.length; i++){
        if(Array.prototype.isPrototypeOf(schemamapping[i])){
            getKeystobeUpdated(schemamapping[i].mapping, schema[i].subschema, keystobeupdated);
        }else{
            if(schema[i].type=="array"){
                if(schemamapping[i].mapping.action=="const"){
                    let submapping = [...schemamapping[i].mapping.mapping];
                    for(let j=0; j < submapping.length; j++){
                        if(submapping[j].action=="get"){
                            keystobeupdated.push(submapping[j].val);
                        }
                    }
                }else{
                    keystobeupdated.push(schemamapping[i].mapping.val);
                }
            }else{
                if(schemamapping[i].mapping?.action=="get"){
                    keystobeupdated.push(schemamapping[i].mapping.val);
                }
            }
        }
    }
}

const updateMappingMap = (stage, mappingMap, oldindex, newindex)=>{
    //READ INPUT MAPPING AND CHANGE THE MAPPING
    if(Array.prototype.isPrototypeOf(stage.inputschemamapping)){
        let inputschemamapping = [...stage.inputschemamapping];
        let inputschema = [...stage.inputschema];
        let keystobeupdated = [];
        getKeystobeUpdated(inputschemamapping, inputschema, keystobeupdated);
        for(let i=0; i< keystobeupdated.length; i++){
            try{
                if(keystobeupdated[i]!=""){

                    let stages = mappingMap[keystobeupdated[i]].stages;
                    // let indexIndex = _.findIndex(stages, (st)=>{return st.stageindex==oldindex});
                    for(let i=0; i<stages.length; i++){
                        if(stages[i].stageindex==oldindex){
                            stages[i] = {...stages[i],
                                        stageindex: newindex
                                        }
                        }
                    }
                    // stages[indexIndex] = {...stages[indexIndex],
                    //                         stageindex: newindex
                    //                      };
                    mappingMap[keystobeupdated[i]] = {...mappingMap[keystobeupdated[i]],
                                                    stages: stages
                                                    }

                }
                
            }catch(error){
                console.log(error);
            }
        }
    }
}

const findChangeInConditions = (conditions, newconditions, mappingmap)=>{
    let change = 0;
    let added = false;
    if(conditions.length>newconditions.length){
        added = false;
        for(let i=0; i<conditions.length; i++){
            let cindex = _.findIndex(newconditions, (nc)=>{return nc.name==conditions[i].name});
            if(cindex==-1){
                change = change+conditions[i].pipeline.length;
                for(let j=i; j< newconditions.length; j++){
                    modifyDownstreamStageIndex(newconditions[j].pipeline,mappingmap,0,-1*change);
                }
            }
        }
    }else if(conditions.length<newconditions.length){
        added=true;
        for(let i=0; i< newconditions.length; i++){
            let cindex = _.findIndex(conditions, (cc)=>{return cc.name==newconditions[i].name});
            if(cindex==-1){
                change = change+newconditions[i].pipeline.length;
                if(change==1){
                    if(newconditions[i].pipeline[0].stageindex==undefined){
                        newconditions[i].pipeline[0] = {...newconditions[i].pipeline[0],
                                                        stageindex: newconditions[i-1].pipeline[newconditions[i-1].pipeline.length-1].stageindex+1
                                                        };
                    }
                }
                for(let j=i+1; j< newconditions.length; j++){
                   modifyDownstreamStageIndex(newconditions[j].pipeline, mappingmap,0, (1)*change);
                }
            }
        }
    }
    if(added==false){
        change = -1*change;
    }
    return change
}

const findStageByStageIndex = (stageindex, epipeline)=>{
    let returnableStage = null;
    for(let i=0; i< epipeline.length; i++){
        let stage = epipeline[i];
        if(stage.stageindex==stageindex){
            returnableStage = stage;
            break;
        }else if(stage.type=="loop"){
            returnableStage = findStageByStageIndex(stageindex,stage.loop);
            if(returnableStage!=null){
                break;
            }
        }else if(stage.type=="conditional"){
            let conditionalfound = false;
            for(let j = 0;j < stage.conditions.length; j++){
                returnableStage = findStageByStageIndex(stageindex, stage.conditions[j].pipeline);
                if(returnableStage!=null){
                    conditionalfound = true;
                    break;
                }   
            }
            if(conditionalfound){
                break;
            }
        }
    }
    return returnableStage;
}

const updatePipelineStageOutput = (
    pipeline,
    effects,
    dispatch
)=>{

        for(let i=0; i< effects.length; i++){
            let stageindex = effects[i].stageindex;
            let indexarr = [];
            let position = [];
            let stageCopy;
            if(stageindex!="template"){
                indexarr = getPipelineBreakPoints(pipeline, stageindex, "main", indexarr);
                let updatetime = new Date();
                stageCopy = {...indexarr[indexarr.length-1].stage,
                                status: true,
                                updatetime: updatetime.toString()
                            };
                for(let i=0; i<indexarr.length; i++){
                    position.push(indexarr[i].index)
                }

            }
            if(effects[i].error){
                Delineatedpipeline[effects[i].stageindex] = {
                                                                "sync": 2,
                                                                "error": effects[i].message,
                                                                "outputschema": null,
                                                                "loopschema": null,
                                                                "type": null
                                                            }
            }else{
                Delineatedpipeline[effects[i].stageindex] = {
                                                                "sync": 0,
                                                                "error": effects[i].error,
                                                                "outputschema": effects[i].outputschema,
                                                                "loopschema": effects[i].loopschema,
                                                                "current_index": effects[i].current_index
                                                            }
            }
            if(position.length>0){
                dispatch(setStage({position: position, stage: stageCopy}));
            }
    }

}

const updateSchemaKey = (inputschemamapping, inputschema, oldval, newval)=>{
    for(let i=0; i< inputschemamapping.length; i++){
        if(Array.prototype.isPrototypeOf(inputschemamapping[i].mapping)){      
            updateSchemaKey(inputschemamapping[i].mapping, inputschema[i].subschema, oldval, newval);
        }else{
            try{

                if(inputschema[i].type=="array"){
                    if(inputschemamapping[i].mapping.action=="const"){
                        for(let j=0; j< inputschemamapping[i].mapping.mapping.length; j++) {
                            if(inputschemamapping[i].mapping.mapping[j].val==oldval){
                                let submappingcopy = [...inputschemamapping[i].mapping.mapping];
                                submappingcopy[j] = {...submappingcopy[j],
                                    val: newval
                                   }
                                let mappingCopy = {...inputschemamapping[i].mapping,
                                                   mapping: submappingcopy  
                                                  }
                                inputschemamapping[i] = {...inputschemamapping[i],
                                                         mapping: mappingCopy
                                                        }
                            }
                        }
                    }else{
                        if(inputschemamapping[i].mapping.val==oldval){
                            let mappingCopy = {...inputschemamapping[i].mapping,
                                                val: newval
                                                }
                            inputschemamapping[i] = mappingCopy;
                        }
                    }
                }else{
                    if(inputschemamapping[i].mapping.val==oldval){
                        let mappingCopy = {...inputschemamapping[i].mapping,
                                            val: newval
                                          }
                        inputschemamapping[i] = {...inputschemamapping[i],
                                                 mapping: mappingCopy
                                                };
                    }
                }

            }catch(error){
                throw error;
            }
            
        }
    }
}

const updateStageByStageIndex = (stageindex, epipeline, oldkey, newkey)=>{
    let stageupdated = false;
    for(let i=0; i< epipeline.length; i++){
        let stage = epipeline[i];
        if(stage.stageindex==stageindex.stageindex){
            updateSchemaKey(stage.inputschemamapping, stage.inputschema, oldkey, newkey);
            stageupdated = true;
            break;
        }else{
            if(stage.type=="loop"){
                let res = updateStageByStageIndex(stageindex, stage.loop);
                if(res){
                    stageupdated = true;
                    break;
                }
            }else if(stage.type=="conditional"){
                let constageupdated = false;
                for(let j=0; j< stage.conditions.length; j++){
                    let res = updateStageByStageIndex(stageindex, stage.conditions[j].pipeline);
                    if(res){
                        constageupdated = true;
                        break;
                    }
                }
                if(constageupdated){
                    stageupdated = true;
                    break;
                }
            }
        }
    }
    return stageupdated;
}

const updateSchemaMappingsIndexes = (pipeline,mappingMap,oldindex, newindex)=>{
    // let indexestobeupdated = [];
    let schemakeys = Object.keys(mappingMap);
    for(let i=0; i< schemakeys.length; i++){
        let vals = parseSchemaKey(schemakeys[i]);
        if(vals[0]==oldindex){
            let stageindexes = mappingMap[schemakeys[i]].stages;
            let newkey = replaceSchemaKeyIndex(schemakeys[i],newindex);
            for(let j=0; j< stageindexes.length; j++){
                updateStageByStageIndex(stageindexes[j], pipeline, schemakeys[i], newkey);
            }
            // change the mappingmap key
            mappingMap[newkey] = {...mappingMap[schemakeys[i]]
                                 };
            delete mappingMap[schemakeys[i]];
        }
    }
}

const getDependantStagesInt = (mappingMap, stageindex)=>{
        let mappingmapcopy = mappingMap;
        let schemakeys = Object.keys(mappingmapcopy);
        let dependants = [];
        try{
            if(stageindex!=undefined){
                for(let i=0; i< schemakeys.length; i++){
                    if(schemakeys[i]!=""&&schemakeys[i]!=undefined){
                        let vals = parseSchemaKey(schemakeys[i]);
                        if(vals[0]==stageindex){
                            if(mappingmapcopy[schemakeys[i]].stages!=undefined&&mappingmapcopy[schemakeys[i]].stages.length>0){
                                for(let j =0; j < mappingmapcopy[schemakeys[i]].stages.length; j++){
                                    let depindex = _.findIndex(dependants, (dep)=>{ return mappingmapcopy[schemakeys[i]].stages[j].stageindex==dep});
                                    if(depindex==-1){
                                        dependants.push(mappingmapcopy[schemakeys[i]].stages[j].stageindex)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }catch(error){
            dependants = [];
        }
        
        return dependants;
}

const removeStageDependance = (stage, mappingMap)=>{
    let inputschemamapping = [...stage.inputschemamapping];
    let inputschema = [...stage.inputschemamapping];
    let keystobeupdated = [];
    getKeystobeUpdated(inputschemamapping, inputschema, keystobeupdated);
    for(let i=0; i< keystobeupdated.length; i++){
        try{
            let stages = mappingMap[keystobeupdated[i]].stages;
            let loop = true;
            while(loop){
                let oldindexIndex = _.findIndex(stages, (st)=>{return st.stageindex==stage.stageindex});
                if(oldindexIndex>-1){
                    stages.splice(oldindexIndex,1)
                }else{
                    loop = false;
                }
            }
            if(stages.length>0){
                mappingMap[keystobeupdated[i]] = {...mappingMap[keystobeupdated[i]],
                    stages: stages
                   }    
            }else{
                delete mappingMap[keystobeupdated[i]]
            }
            // mappingMap[keystobeupdated[i]] = {...mappingMap[keystobeupdated[i]],
            //                                   stages: stages
            //                                  }
        }catch(error){
            console.log(error);
            throw error;
        }
    }
}

const performChangeActionOnPipeline = (
                                        stage, 
                                        pipeline, 
                                        mappingMap,
                                        change,
                                        stagestobeexecuted
                                        )=>{
    let schemakeys = Object.keys(mappingMap);
    let dependants = [];
    for(let i=0; i< schemakeys.length; i++){
        let vals = parseSchemaKey(schemakeys[i]);
        if(vals[0]==stage.stageindex){
            let stages = mappingMap[schemakeys[i]].stages;
            for(let j=0; j < stages.length; j++){
                updateStageByStageIndex(stages[j], pipeline, schemakeys[i], "");
                stagestobeexecuted.push(stages[j].stageindex+change);
            }
            delete mappingMap[schemakeys[i]];
        }
    }
}

const modifyDownstreamStageIndex = (pipeline, mappingMap, index, change, delineatedpipelinechanges)=>{

    for(let i=index+1; i< pipeline.length; i++){
        updateSchemaMappingsIndexes(pipeline, mappingMap, pipeline[i].stageindex, pipeline[i].stageindex+change);
        updateMappingMap(pipeline[i], mappingMap, pipeline[i].stageindex, pipeline[i].stageindex+change);
        delineatedpipelinechanges.push({
            "oldindex": pipeline[i].stageindex,
            "newindex": pipeline[i].stageindex+change
        })
        pipeline[i] = {...pipeline[i],
                       stageindex: pipeline[i].stageindex+change
                      }
        if(pipeline[i].type=="conditional"){
            let conditions = pipeline[i].conditions;
            for(let j =0; j<conditions.length; j++){
                modifyDownstreamStageIndex(conditions[j].pipeline, mappingMap,-1, change, delineatedpipelinechanges);
            }
        }
        if(pipeline[i].type=="loop"){
           modifyDownstreamStageIndex(pipeline[i].loop, mappingMap,-1, change, delineatedpipelinechanges);
        }
    }
}

const checkMemRefrence = (stage)=>{
    if(stage.app=="transform"){
        if(stage.transformtype=="object"){
            let inmemactions = ["deletekey","addkey","setkey", "getkey"];
            let actionIndex = _.findIndex(inmemactions, (ac)=>{return ac==stage.action});
            if(actionIndex==-1){
                return false;
            }else{
                return true;
            }
        }else if(stage.transformtype=="array"){
            let inmemactions = ["changeelement", "push", "unshift", "removeelement", "splice"];
            let actionIndex = _.findIndex(inmemactions, (ac)=>{return ac==stage.action});
            if(actionIndex==-1){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

const updateDelineatedPipeline = async(delineatedpipelinechanges, pipelineid)=>{
    let res = await reorderpipelines({"reorders": delineatedpipelinechanges, "id": pipelineid});
    repopulatePipeline(res.snapshot)
}

const getChangeOnDelete = (position, pipeline)=>{
    let change = -1;
    let pcopy = pipeline;
    let nextstageindex;
    for(let i=0; i< position.length; i++){
        if(i==(position.length-1)){
            let deletestageindex = pcopy[position[i]].stageindex;
            if(pcopy.length>(position[i]+1)){
                nextstageindex = pcopy[position[i]+1].stageindex;
            }
            if(nextstageindex!=undefined){
                change = deletestageindex-nextstageindex
            }
        }else{
            if(pcopy[position[i]].type=="conditional"){
                if(pcopy[position[i]].conditions.length>(position[i+1]+1)){
                    nextstageindex = pcopy[position[i]].conditions[position[i+1]+1].pipeline[0].stageindex;
                }else if(pcopy.length>position[i]+1){
                    nextstageindex = pcopy[position[i]+1].stageindex
                }
                pcopy = pcopy[position[i]].conditions[position[i+1]].pipeline;
                i = i+1;
            }else if(pcopy[position[i]].type=="loop"){
                if(pcopy.length>position[i]+1){
                    nextstageindex = pcopy[position[i]+1].stageindex;
                }
                pcopy = pcopy[position[i]].loop;   
            }
        }
    }
    return change;
}

// todo: change 
export const performChange = createAsyncThunk(
    "pipeline/performchange",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let changetype = payload.changetype;
            let source = payload.source
            let stateCopy = getState();
            let pipelineCopy = JSON.parse(JSON.stringify(stateCopy.pipeline.pipeline));
            let mappingMapCopy = JSON.parse(JSON.stringify(stateCopy.pipeline.mappingmap));
            let pipelineid = stateCopy.pipeline.activepipleineid;
            let stagestobeexecuted = [];
            if(changetype=="delete"){
                let position = payload.position;
                let pCopy = pipelineCopy;
                let stage;
                let delineatedpipelinechanges = [];
                let change = getChangeOnDelete(position, pCopy);
                for(let i=0; i < position.length; i++){
                    if(i==(position.length-1)){
                        stage = {...pCopy[position[i]]}
                        performChangeActionOnPipeline(stage, pCopy, mappingMapCopy, change, stagestobeexecuted);
                        removeStageDependance(stage,mappingMapCopy, pCopy);
                        modifyDownstreamStageIndex(pCopy, mappingMapCopy, position[i], change, delineatedpipelinechanges);
                        pCopy.splice(position[i],1);

                    }else{
                        if(pCopy[position[i]].type=="conditional"){
                            modifyDownstreamStageIndex(pCopy, mappingMapCopy, position[i], change);
                            for(let j=position[i+1]+1;j<pCopy[position[i]].conditions.length; j++){
                                modifyDownstreamStageIndex(pCopy[position[i]].conditions[j].pipeline,mappingMapCopy,-1,change, delineatedpipelinechanges);
                            }
                            pCopy = pCopy[position[i]].conditions[position[i+1]].pipeline;
                            i = i+1;
                        }else if(pCopy[position[i]].type=="loop"){
                            modifyDownstreamStageIndex(pCopy, mappingMapCopy, position[i], change, delineatedpipelinechanges);
                            pCopy = pCopy[position[i]].loop;
                        }
                    }
                }
                if(checkMemRefrence(stage)){
                    let pindex = getParentRef(stage.stageindex)
                    stagestobeexecuted.push([pindex.stageindex]);
                }
                await updateDelineatedPipeline(delineatedpipelinechanges, pipelineid);
            }
            if(changetype=="change_type"){
                let position = payload.position;
                let originStage = findStageByStageIndex(payload.stage.stageindex, pipelineCopy)
                await setStageAction(
                                position, 
                                {...payload.stage}, 
                                pipelineCopy, 
                                mappingMapCopy, 
                                dispatch,
                                getState,
                                source
                                );
                performChangeActionOnPipeline(payload.stage, pipelineCopy, mappingMapCopy, stagestobeexecuted);
                removeStageDependance(payload.stage,mappingMapCopy);
                if(checkMemRefrence(originStage)){
                    let pindex = getParentRef(originStage.stageindex)
                    stagestobeexecuted.push(pindex.stageindex);
                }
            }
            if(stagestobeexecuted.length>0){
                dispatch(testActionInt({
                    index: stagestobeexecuted,
                    pipeline: pipelineCopy,
                    source: source,
                    mappingmap: mappingMapCopy
                }));
            }
            return {
                pipeline: pipelineCopy,
                mappingMap: mappingMapCopy
            }
        }catch(error){
            console.log(error);
        }
    }
)

export const changeLoopIndex = createAsyncThunk(
    "pipeline/changeloopindex",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let position = payload.position;
        let stage = payload.stage;
        let source = payload.source;
        let state = getState();
        let pipeline = JSON.parse(JSON.stringify(state.pipeline.pipeline));
        let pCopy = pipeline;
        for(let i=0; i< position.length; i++){
            if(i==(position.length-1)){
                pCopy[position[i]] = stage;
                dispatch(testActionInt({index: [stage.stageindex], pipeline: [...pipeline], source: source}));
            }else{
                if(pCopy[position[i]].type=="conditional"){
                    pCopy = pCopy[position[i]].conditions[position[i+1]].pipeline;
                    i = i+1;
                }else if(pCopy[position[i]].type=="loop"){
                    pCopy = pCopy[position[i]].loop;
                }
            }
        }
        return pipeline;
    }
)

export const addConditionAction = createAsyncThunk(
    "pipeline/addcondition",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let position = payload.position;
        let stage = JSON.parse(JSON.stringify(payload.stage));
        let conditionName = String.fromCharCode(stage.conditions.length+65);
        let lastindex;
        lastindex = stage.conditions[stage.conditions.length-1].pipeline[stage.conditions[stage.conditions.length-1].pipeline.length-1].stageindex;
        let delineatedpipelinechanges = [];
        let condition = {"name": conditionName, "pipeline":[{
            "conditions":[[
                {
                    "valuetocompare":"",
                    "tocompareopen":false,
                    "comparetotype": "any",
                    "options":[],
                    "condition": "",
                    "valuecompareto":"",
                    "comparetoopen":false
                }
            ]],
            "app": "conditions",
            "last": false,
            "type": "internal",
            "app": "conditional",
            "stageindex": lastindex+1
        }]};
        stage.conditions.push(condition);
        let state = getState();
        let pipeline = JSON.parse(JSON.stringify(state.pipeline.pipeline));
        let mappingmap = JSON.parse(JSON.stringify(state.pipeline.mappingmap));
        let pipelineid = state.pipeline.activepipleineid;
        let pcopy = pipeline;
        let change = 1;
        for(let i=0; i< position.length; i++){
            if(i==(position.length-1)){
                pcopy[position[i]] = stage;
            }else{
                if(pcopy[position[i]].type=="conditional"){
                    modifyDownstreamStageIndex(pcopy,mappingmap,position[i],change, delineatedpipelinechanges);
                    for(let j=position[i+1]+1;j<pcopy[position[i]].conditions.length; j++){
                        modifyDownstreamStageIndex(pcopy[position[i]].conditions[j].pipeline,mappingmap,-1,change, delineatedpipelinechanges);
                    }
                    pcopy = pcopy[position[i]].conditions[position[i+1]].pipeline;
                    i = i+1;
                }else if(pcopy[position[i]].type=="loop"){
                    modifyDownstreamStageIndex(pcopy,mappingmap,position[i],change, delineatedpipelinechanges);
                    pcopy = pcopy[position[i]].loop;
                }
            }
        }
        await updateDelineatedPipeline(delineatedpipelinechanges, pipelineid)
        return {
            pipeline: pipeline,
            mappingmap: mappingmap
        };
    }
)

const getLastIndexOfCondition = (stage)=>{
    if(stage.type=="single"){
        return stage.stageindex;
    }else if(stage.type=="conditional"){
        let stageindex = getLastIndexOfCondition(stage.conditions[stage.conditions.length-1].pipeline[stage.conditions[stage.conditions.length-1].pipeline.length-1])
        return stageindex
    }else if(stage.type=="loop"){
        let stageindex = getLastIndexOfCondition(stage.loop[stage.loop.length-1])
        return stageindex;
    }
}

const getChangeOnDeleteCondition = (stage, indextobedeleted)=>{
    let firstindex = stage.conditions[indextobedeleted].pipeline[0].stageindex;
    let lastindex = getLastIndexOfCondition(stage.conditions[indextobedeleted].pipeline[stage.conditions[indextobedeleted].pipeline.length-1]);
    return (firstindex-lastindex)-1;
}

const getChangeonBlockType = (stage)=>{
    let lastindex = getLastIndexOfCondition(stage);
    return lastindex;
}

export const deleteConditionAction = createAsyncThunk(
    "pipeline/deletecondition",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let indextobedeleted = payload.index;
        let stage = payload.stage;
        let position = payload.position;
        let state = getState();
        let delineatedpipelinechanges = [];
        let pipeline = JSON.parse(JSON.stringify(state.pipeline.pipeline));
        let mappingmap = JSON.parse(JSON.stringify(state.pipeline.mappingmap));
        let pipelineid = state.pipeline.activepipleineid;
        let change = getChangeOnDeleteCondition(stage, indextobedeleted);
        let conditionsCopy = [...stage.conditions];
        for(let i=indextobedeleted+1; i< conditionsCopy.length; i++){
            modifyDownstreamStageIndex(conditionsCopy[i].pipeline, mappingmap, change, -1, delineatedpipelinechanges);
        }
        conditionsCopy.splice(indextobedeleted,1);
        for(let i=0; i < conditionsCopy.length; i++){
            conditionsCopy[i] = {...conditionsCopy[i],
                                 name: String.fromCharCode(i+65)
                                };
        }
        let stagecopy = {...stage,
                         conditions: conditionsCopy
                        }
        let pcopy = pipeline;
        for(let i=0; i< position.length; i++){
            if(i==(position.length-1)){
                pcopy[position[i]] = stagecopy;
                modifyDownstreamStageIndex(pcopy, mappingmap, position[i], change, delineatedpipelinechanges);
            }else{
                if(pcopy[position[i]].type=="conditional"){
                    modifyDownstreamStageIndex(pcopy,mappingmap,position[i],change, delineatedpipelinechanges);
                    for(let j=position[i+1]+1;j<pcopy[position[i]].conditions.length; j++){
                        modifyDownstreamStageIndex(pcopy[position[i]].conditions[j].pipeline,mappingmap,-1,change, delineatedpipelinechanges);
                    }
                    pcopy = pcopy[position[i]].conditions[position[i+1]].pipeline;
                    i = i+1;
                }else if(pcopy[position[i]].type=="loop"){
                    modifyDownstreamStageIndex(pcopy,mappingmap,position[i],change, delineatedpipelinechanges);
                    pcopy = pcopy[position[i]].loop;
                }
            }
        }
        await updateDelineatedPipeline(delineatedpipelinechanges, pipelineid);
        return {
            pipeline: pipeline,
            mappingmap: mappingmap
        }
    }
)

export const changeblocktype = createAsyncThunk(
    "pipeline/changeblocktype",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let position = payload.position;
        let stage  = payload.stage;
        let oldstage = payload.oldstage;
        let source = payload.source;
        let state = getState();
        let pipeline = JSON.parse(JSON.stringify(state.pipeline.pipeline));
        let mappingmap = JSON.parse(JSON.stringify(state.pipeline.mappingmap));
        let pipelineid = state.pipeline.activepipleineid;
        let lastindex = getChangeonBlockType(oldstage);
        let change = stage.stageindex-lastindex;
        if(stage.type=="loop"){
            change = change+1;
        }else if(stage.type=="conditional"){
            change = change+1
        }
        let delineatedpipelinechanges = [];
        let stagestobeexecuted = [];
        let pcopy = pipeline;
        for(let i=0; i< position.length; i++){
            if(i==(position.length-1)){
                performChangeActionOnPipeline(stage, pcopy, mappingmap, change, stagestobeexecuted);
                removeStageDependance(stage,mappingmap, pcopy);
                modifyDownstreamStageIndex(pcopy, mappingmap, position[i], change, delineatedpipelinechanges);
                pcopy[position[i]] = stage;
            }else{
                if(pcopy[position[i]].type=="conditional"){
                    modifyDownstreamStageIndex(pcopy, mappingmap, position[i], change);
                    for(let j=position[i+1]+1;j<pcopy[position[i]].conditions.length; j++){
                        modifyDownstreamStageIndex(pcopy[position[i]].conditions[j].pipeline,mappingmap,-1,change, delineatedpipelinechanges);
                    }
                    pcopy = pcopy[position[i]].conditions[position[i+1]].pipeline;
                    i = i+1;
                }else if(pcopy[position[i]].type=="loop"){
                    modifyDownstreamStageIndex(pcopy, mappingmap, position[i], change, delineatedpipelinechanges);
                    pcopy = pcopy[position[i]].loop;
                }
            }
        }
        if(checkMemRefrence(stage)){
            let pindex = getParentRef(stage.stageindex)
            stagestobeexecuted.push([pindex.stageindex]);
        }
        await updateDelineatedPipeline(delineatedpipelinechanges, pipelineid);
        if(stagestobeexecuted.length>0){
            dispatch(testActionInt({
                                        index: stagestobeexecuted,
                                        pipeline: pipeline,
                source: source,
                mappingmap: mappingmap
            }));
        }

        return {
            pipeline: pipeline,
            mappingmap: mappingmap
        }

    }
)

const setStageActionSync = (indexarr, stage, pipeline)=>{
    // let pipelineCopy = [...pipeline];
    let pipelineCopy = JSON.parse(JSON.stringify(pipeline));
    let pCopy = pipelineCopy;
    for(let i=0; i< indexarr.length; i++){
        if(i==(indexarr.length-1)){
            pCopy[indexarr[i]]= {
                                 ...pCopy[indexarr[i]],
                                 ...stage
                                };
        }else{
            if(pCopy[indexarr[i]].type=="conditional"){
                pCopy = pCopy[indexarr[i]].conditions[indexarr[i+1]].pipeline;
                i = i+1;
            }else if(pCopy[indexarr[i]].type=="loop"){
                pCopy = pCopy[indexarr[i]].loop;
            }
        }
    }
    return pipelineCopy;
}

const setStageAction = async(
                            indexarr, 
                            stage, 
                            pipeline, 
                            mappingmap, 
                            dispatch,
                            getState,
                            source
                        )=>{
    let pCopy = pipeline;
    let pipelineModified = false;
    let state = getState();
    let pipelineid = state.pipeline.activepipleineid;
    //holds pointer to indexarr not actual pipeline;
    let pipelineModifiedIndex = 0;
    let change=0;
    for(let i=0; i< indexarr.length; i++){
        if(i==(indexarr.length-1)){
            let stageCopy = {...pCopy[indexarr[i]]};
            // handle modification of conditions stage changes 
            // handle changes of block type toovalue
            if(stage.type=="conditional"&&stageCopy.type=="conditional"){
                if(stageCopy.conditions!=undefined){
                    if(stageCopy.conditions.length!==stage.conditions.length){
                        change = findChangeInConditions(stageCopy.conditions, stage.conditions, mappingmap);
                        pipelineModified = true;
                        pipelineModifiedIndex = i;
                    }
                    pCopy[indexarr[i]] = stage;
                }else{
                    stage.conditions[0].pipeline[0] = {...stage.conditions[0].pipeline[0],
                                                       stageindex: pCopy[indexarr[i]].stageindex+1
                                                      }
                    pCopy[indexarr[i]] = stage.stageindex
                    change=1;
                    pipelineModified = true;
                    pipelineModifiedIndex = i; 
                }
            }else if(stageCopy.type=="conditional"&&stage.type!="conditional"){
                for(let j=0; j< stageCopy.conditions.length; j++){
                    change = change + stageCopy.conditions[j].pipeline.length;
                }
                change = -1*change;
                pipelineModified = true;
                pipelineModifiedIndex = i;
                pCopy[indexarr[i]]= stage;
            }else if(stage.type=="conditional"&&stageCopy.type!="conditional"){
                stage.conditions[0].pipeline[0] = {...stage.conditions[0].pipeline[0],
                                                    stageindex: pCopy[indexarr[i]].stageindex+1
                                                  }
                pCopy[indexarr[i]] = stage;
                change=1;
                pipelineModified = true;
                pipelineModifiedIndex = i; 
            }else if(stage.type=="loop"&&stageCopy.type=="loop"){
                if(stage.currentindex!=stageCopy.currentindex){
                    pCopy[indexarr[i]] = stage;
                    dispatch(testActionInt({index: [stage.stageindex], pipeline: [...pipeline], source: source}));
                }else{
                    pCopy[indexarr[i]]= stage;
                }
            }
            else if(stage.type=="loop"&&stageCopy.type!="loop"){
                change = 1;
                pipelineModified = true;
                pipelineModifiedIndex = i;
                stage.loop[0] = {...stage.loop[0],
                                 stageindex: stage.stageindex+1
                                }
                pCopy[indexarr[i]] = stage;

            }else if(stageCopy.type=="loop"&&stage.type!="loop"){
                change = stageCopy.loop.length;
                pipelineModified = true;
                pipelineModifiedIndex = i;
                pCopy[indexarr[i]] = stage;
            }
            else{
                pCopy[indexarr[i]]= stage
            }
        }else{
            if(pCopy[indexarr[i]].type=="conditional"){
                pCopy = pCopy[indexarr[i]].conditions[indexarr[i+1]].pipeline;
                i = i+1;
            }else if(pCopy[indexarr[i]].type=="loop"){
                pCopy = pCopy[indexarr[i]].loop;
            }
        }
    }
    if(pipelineModified){
        pCopy = pipeline;
        let delineatedpipelinechanges = [];
        for(let i=0; i< pipelineModifiedIndex+1; i++){
            if(i==pipelineModifiedIndex){
                modifyDownstreamStageIndex(pCopy,mappingmap,indexarr[i],change, delineatedpipelinechanges);
            }else{
                modifyDownstreamStageIndex(pCopy,mappingmap,indexarr[i],change, delineatedpipelinechanges);
                if(pCopy[indexarr[i]].type=="conditional"){
                    for(let j=indexarr[i+1]+1;j<pCopy[indexarr[i]].conditions.length; j++){
                        modifyDownstreamStageIndex(pCopy[indexarr[i]].conditions[j].pipeline,mappingmap,-1,change, delineatedpipelinechanges);
                    }
                    pCopy= pCopy[indexarr[i]].conditions[indexarr[i+1]].pipeline;
                    i = i+1;
                }
                if(pCopy[indexarr[i]].type=="loop"){
                    pCopy = pCopy[indexarr[i]].loop;  
                }
            }
        }
        await updateDelineatedPipeline(delineatedpipelinechanges, pipelineid);
    }
}

export const setStageAsync = createAsyncThunk(
    "pipeline/setstageasync",
    async (payload, {dispatch, rejectWithValue, getState})=>{
        let position = payload.position;
        let source = payload.source;
        let stateCopy = getState();
        let pipelineCopy = [...stateCopy.pipeline.pipeline];
        let mappingMapCopy = {...stateCopy.pipeline.mappingmap};
        await setStageAction(
                        payload.position, 
                        payload.stage, 
                        pipelineCopy, 
                        mappingMapCopy, 
                        dispatch,
                        getState,
                        source
                        );
        return {
            pipeline: pipelineCopy,
            mappingmap: mappingMapCopy
        };
    }
)

export const addStage = createAsyncThunk(
    "pipeline/addstage",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let state = getState();
        let position = payload.position;
        let stage = payload.stage;
        let pipelineCopy = JSON.parse(JSON.stringify(state.pipeline.pipeline));
        let mappingMapCopy = JSON.parse(JSON.stringify(state.pipeline.mappingmap));
        let pipelineid = state.pipeline.activepipleineid;
        await addStageAction(position, stage, pipelineCopy, mappingMapCopy, pipelineid);
        return {
            pipeline: pipelineCopy,
            mappingmap: mappingMapCopy
        }
    }
)

export const createNextStage = createAsyncThunk(
    "pipeline/createnextstage",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let state = getState();
        let position = payload.position;
        let stage = payload.stage;
        let pipelineCopy = JSON.parse(JSON.stringify(state.pipeline.pipeline));
        let mappingmap = JSON.parse(JSON.stringify(state.pipeline.mappingmap));
        let pipelineid = state.pipeline.activepipleineid;
        await createNextStageAction(position, stage , pipelineCopy, mappingmap, pipelineid);
        return {
            pipeline: pipelineCopy,
            mappingmap: mappingmap
        }
    }
)

const addStageAction = async (
                                indexarr, 
                                stage, 
                                pipeline, 
                                mappingmap, 
                                pipelineid
                            )=>{

    if(stage==undefined){
        stage = {
                "type": "single",
                "app": "",
                "schema":"choose_schema",
                "action":"choose_action",
                "outputschema":[],
                "inputschema": [],
                "inputschemamapping":[],
                "subactions":[],
                "last": false,
                "active": true,
                "return": true,
                "expanded": true
        }
    }
    let delineatedpipelinechanges = [];
    if(indexarr.length==1){
        let stageindex = getLastStageIndex(pipeline[indexarr[0]]);
        modifyDownstreamStageIndex(pipeline,mappingmap,indexarr[0],1, delineatedpipelinechanges);
        pipeline.splice(indexarr[0]+1,0,{...stage,stageindex: stageindex });
        delineatedpipelinechanges.push({"oldindex": "", "newindex": stageindex});
    }else{
        let pCopy = pipeline;
        for(let i=0; i< indexarr.length; i++){
            if(i==(indexarr.length-1)){
                let stageindex = getLastStageIndex(pCopy[indexarr[i]])
                modifyDownstreamStageIndex(pCopy, mappingmap, indexarr[i], 1, delineatedpipelinechanges);
                pCopy.splice(indexarr[i]+1,0,{...stage,stageindex: stageindex });
                delineatedpipelinechanges.push({"oldindex": "", "newindex": stageindex});
            }else{
                modifyDownstreamStageIndex(pCopy,mappingmap,indexarr[i],1, delineatedpipelinechanges);
                if(pCopy[indexarr[i]].type=="conditional"){
                    for(let j=indexarr[i+1]+1;j<pCopy[indexarr[i]].conditions.length; j++){
                        modifyDownstreamStageIndex(pCopy[indexarr[i]].conditions[j].pipeline,mappingmap,-1,1, delineatedpipelinechanges);
                    }
                    pCopy= pCopy[indexarr[i]].conditions[indexarr[i+1]].pipeline;
                    i = i+1;
                }
                if(pCopy[indexarr[i]].type=="loop"){
                    pCopy = pCopy[indexarr[i]].loop;  
                }
            }
        }
    }
    await updateDelineatedPipeline(delineatedpipelinechanges, pipelineid);
}

const createNextStageAction = async (indexarr, stage, pipeline, mappingmap, pipelineid)=>{
    let pCopy = pipeline;
    for(let i=0; i< indexarr.length; i++){
        if(i==(indexarr.length-1)){
            pCopy[indexarr[i]] = {...pCopy[indexarr[i]],
                                    expanded: false
                                   }
            
        }else{
            if(pCopy[indexarr[i]].type=="conditional"){
                pCopy = pCopy[indexarr[i]].conditions[indexarr[i+1]].pipeline;
                i = i+1;
            }else if(pCopy[indexarr[i]].type=="loop"){
                pCopy = pCopy[indexarr[i]].loop;
            }
        }
    }
    await addStageAction(indexarr, stage, pipeline, mappingmap, pipelineid);
}

const extractTemplateId = (key)=>{
    let parts = key.split("__");
    return parts[0];
}

export const loadTemplateVariables = (
    type,
    templateid,
    getState,
    delineatedPipeline,
    source,
    position,
    event
)=>{
    if(type=="main"){
        let stateCopy = getState();
        let templatevariablesCopy = JSON.parse(JSON.stringify(stateCopy.apptemplates.appvariables));
        let schema = [];
        let output = {};
        if(templatevariablesCopy[templateid]!=undefined){
            let varaibleschema = templatevariablesCopy[templateid].schema;
            output["variable"] = varaibleschema[0].value
            schema.push(varaibleschema[0]);
        }
        //load inputs
        let appinputs = JSON.parse(JSON.stringify(stateCopy.apptemplates.appinputs));
        let templateinputs = appinputs[templateid];
        if(templateinputs!=undefined){
            let inputschema = {
                "key": "inputs",
                "label": "Inputs",
                "type": "object",
                "subschema": [...templateinputs],
                "value": {}
            }
            schema.push(inputschema)
            output["inputs"] = {};
        }
        //load session storage
        let localStorageSchema = getLocalStorageSchema();
        schema.push(localStorageSchema[0]);
        output["localstorage"] = {};
        //load session storage
        let sessionStorageSchema = getSessionStorage();
        schema.push(sessionStorageSchema[0]);
        output["sessionstorage"] = {};
        if(source=="pipeline"){
            // load loop variable schema
            let elements = stateCopy.apptemplates.appelements[templateid];
            let loopschema = generateLoopVaraibleP(elements,templatevariablesCopy[templateid].schema, position);
            if(loopschema!=undefined&&loopschema.length>0){
                schema.push(loopschema[0]);
                output["loopvariable"] = {};
                //load event schema;
                let eventschema = [];
                generateSchema(event, eventschema);
                schema.push(eventschema)
                output["event"] = {};
            }
               
        }
        delineatedPipeline["template"] = {
                                          outputschema: schema,
                                          output: output
                                         };
        
        return delineatedPipeline;
    }else{

    }

}

export const loadDelineatedPipeline = createAsyncThunk(
    "apptemplates/loaddelineatedpipeline",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let delineatedPipeline = {};
        loadTemplateVariables(
            "main",
            payload.templateid,
            getState,
            delineatedPipeline,
            "loadpipeline",
            [],
            {}
        );
        createTempVarSnapshot(delineatedPipeline);
        setDelineatedPipelinekey("template", delineatedPipeline["template"])
    }
)

const testActionInt = createAsyncThunk(
    "pipeline/testactionint",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let source = payload.source;

        // only available for the interfaces
        let templateid = payload.templateid;
        try{
            let statecopy = getState();
        
            //set stage to loading
            Delineatedpipeline[payload.index]= {...Delineatedpipeline[payload.index],
                                                sync: 1
                                                }
            let payloadapi;
            let mappingmap;
            if(payload.mappingmap==undefined){
                mappingmap = {...statecopy.pipeline.mappingmap};
            }else{
                mappingmap = payload.mappingmap;
            }
            if(source=="apps"){
                payloadapi = {
                    id: statecopy.pipeline.activepipleineid,
                    pipeline: payload.pipeline,
                    index: payload.index,
                    executionId: statecopy.pipeline.activepipeline.executionId,
                    mappingMap: mappingmap
                }
            }else{
                // let delineatedPipeline = {...Delineatedpipeline};
                // loadTemplateVariables("main", templateid, getState, delineatedPipeline, "test");
                payloadapi = {
                    id: statecopy.pipeline.activepipleineid,
                    pipeline: payload.pipeline,
                    index: payload.index,
                    executionId: statecopy.pipeline.activepipeline.executionId,
                    mappingMap: mappingmap,
                }
            }

            let res = await testActionApi({payload:payloadapi, source: source}, dispatch, getState);
            let pipeline = payload.pipeline;
            updatePipelineStageOutput(
                                        pipeline,
                                        res,
                                        dispatch
                                    );
            return res;
        }catch(error){
            dispatch(showError("Pipeline instance stopped with error "+error.message))
            return rejectWithValue({stageindex: error.response.data.error.stageindex, message: error.response.data.error.message});
        }
    }
)

export const testAction = createAsyncThunk(
    "pipeline/testaction",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        let source = payload.source;
        let templateid = payload.templateid;
        try{
            let statecopy = getState();

            payload = payload.stageindex;

            let stage = findStageByStageIndex(payload, statecopy.pipeline.pipeline);
            //update the updatequeue
            // updateQueue = [{stageindex: payload, status:0},...updateQueue];
            //set stage to loading
            Delineatedpipeline[stage.stageindex]= {...Delineatedpipeline[stage.stageindex],
                                                    sync: 1
                                                  }

            let payloadapi;

            if(source=="apps"){
                payloadapi = {
                    id: statecopy.pipeline.activepipleineid,
                    pipeline: statecopy.pipeline.pipeline,
                    index: [payload],
                    mappingMap: statecopy.pipeline.mappingmap,
                    executionId: statecopy.pipeline.activepipeline.executionId,
                }
            }else{
                // let delineatedPipeline ={};
                // loadTemplateVariables("main", templateid, getState, delineatedPipeline, "test");
                payloadapi = {
                    id: statecopy.pipeline.activepipleineid,
                    pipeline: statecopy.pipeline.pipeline,
                    index: [payload],
                    executionId: statecopy.pipeline.activepipeline.executionId,
                    key: templateid,
                    mappingMap: statecopy.pipeline.mappingmap
                }

            }
            let res = await testActionApi({payload: payloadapi, source: source}, dispatch, getState);
            let pipeline = [...statecopy.pipeline.pipeline];
            updatePipelineStageOutput(
                                        pipeline,
                                        res,
                                        dispatch
                                     );
            dispatch(resetactionerror());
            return res;
        }catch(error){
            dispatch(showError("Pipeline instance stopped with error "+error.message));
        }
    }
)

export const getNextSchema = createAsyncThunk(
    "pipeline/getnextschema",
    async(actionpayload, {dispatch, rejectWithValue, getState})=>{
        let state = getState().pipeline;
        let payload = {...actionpayload,
                        pipelineid: state.activepipleineid
                      }
        let res = await getNextSchemaApi(payload);
        let stageindex = res.stageindex;
        let indexarr = [];
        let position = [];
        let stageCopy;
        let pipeline = JSON.parse(JSON.stringify(state.pipeline));
        if(stageindex!="template"){
            indexarr = getPipelineBreakPoints(pipeline, stageindex, "main", indexarr);    
            let updatetime = new Date();
            stageCopy = {...indexarr[indexarr.length-1].stage,
                            status: true,
                            updatetime: updatetime.toString()
                        };
            for(let i=0; i<indexarr.length; i++){
                position.push(indexarr[i].index)
            }
        }
        Delineatedpipeline[stageindex] = {...Delineatedpipeline[stageindex],
                                            "sync": 0,
                                            "outputschema": res.outputschema
                                         }
        if(position.length>0){
            dispatch(setStage({position: position, stage: stageCopy}));
        }
    }
)

export const uploadFile = createAsyncThunk(
    "pipeline/uploadfile",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await uploadFileApi(payload);
            return res;
        }catch(error){
            dispatch(error.response.data.error.message);
            throw error;
        }
    }
)

export const createPipeline = createAsyncThunk(
    "pipeline/createpipeline",
    async(payload, {dispatch})=>{
        try{
            let res = await createPipelineApi(payload);
            return res;
        }catch(err){
            dispatch(showError(err.response.data.error.message));
            throw err;
        }
    }
)

export const updatePipeline = createAsyncThunk(
    "pipeline/updatepipeline",
    async(payload,{dispatch})=>{
        try{
            let res = await updatePipelineApi(payload);
            return res;
        }catch(err){
            dispatch(showError(err.response.data.error.message))
            throw err;
        }
        
    }
)

export const updatePipelineF = createAsyncThunk(
    "pipeline/updatepipelinef",
    async(payload, {dispatch}) => {
        try{
            let res = await updatePipelineApiF(payload);
            return res;
        }catch(err){
            dispatch(showError(err.response.data.error.message));
            throw err;
        }

    }
)

export const getPipelinebySchema = createAsyncThunk(
    "pipeline/getpipelinebyschema",
    async(payload, {dispatch})=>{
        try{
            let res = await getPipelinebySchemaApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const getPipelinebyId = createAsyncThunk(
    "pipeline/getpipelinebyid",
    async(payload, {dispatch})=>{
        try{
            let res = await getPiplelineByIdApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const deletePipelineById = createAsyncThunk(
    "pipeline/deletepipelinebyid",
    async(payload, {dispatch})=>{
        try{
            let res = await deletePipelineByIdApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const getPipelineTriggersBySchema = createAsyncThunk(
    "pipeline/getpipelinetrigerrsbyschema",
    async(payload, {dispatch})=>{
        try{
            let res = await getPipelineTriggersBySchemaApi(payload);
            return res;
        }catch(error){
            throw error;
        }
    }
) 

export const testPipeline = createAsyncThunk(
    "pipeline/testpipeline",
    async(payload, {dispatch})=>{
        try{
            let res = await testPipelineApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }

)

const getPipelineBreakPoints = (pipeline, pauseIndex, pipelinetype,indexarr)=>{
    for(let i=0; i < pipeline.length; i++){
        if(pipeline[i].stageindex==pauseIndex){
            indexarr = [{"index": i, "type": pipelinetype, "stage": pipeline[i]}, ...indexarr]
            break
        }
        if(pipeline[i].type=="conditional"){
            let conditions = pipeline[i].conditions;
            let stageFound = false;
            for(let j =0 ; j < conditions.length; j++){
                let conditionpipeline = conditions[j].pipeline;
                let oldlength = indexarr.length;
                indexarr = getPipelineBreakPoints(conditionpipeline, pauseIndex,"conditional", indexarr);
                if(indexarr.length>oldlength){
                    indexarr = [{"index":j, "type": "condition"}, ...indexarr]
                    stageFound = true;
                    break;
                }
            }
            if(stageFound==true){
                indexarr = [{"index": i, "type": pipelinetype}, ...indexarr];
                break;
            }
        }
        if(pipeline[i].type=="loop"){
            let loop = pipeline[i].loop;
            let oldlength = indexarr.length;
            indexarr = getPipelineBreakPoints(loop, pauseIndex, "loop",indexarr);
            if(indexarr.length>oldlength){
                indexarr = [{"index": i, "type": pipelinetype}, ...indexarr];
                break;
            }
        }
    }
    return indexarr;
}

// pipeline deployment controls
export const deployPipeline = createAsyncThunk(
    "pipeline/deploypipeline",
    async(payload, {dispatch})=>{
        try{
            let res = await deployPipelineApi(payload);
            dispatch(showError("Pipeline has been deployed"));
            return res;
        }catch(error){
            dispatch(showError("Error while deploying the pipeline"));
        }
    }
)

export const activatePipeline = createAsyncThunk(
    "pipeline/activatepipeline",
    async(payload, {dispatch})=>{
        try{
            let res = await activatePipelineApi(payload);
            dispatch(showError("Pipeline has been activated"));
            return res;
        }catch(error){
            dispatch(showError("Error while activating the pipeline"));
        }
    }
)

export const pausePipeline = createAsyncThunk(
    "pipeline/pausepipeline",
    async(payload, {dispatch})=>{
        try{
            let res = await pausePipelineApi(payload);
            dispatch(showError("Pipeline has been paused"));
            return res;
        }catch(error){
            dispatch(showError("Error while pausing the pipeline"));
        }
    }
)

export const getInputStage = createAsyncThunk(
    "pipeline/getinputstage",
    async(payload, {dispatch})=>{
        let res = await getInputStageApi(payload);
        return res;
    }
)

export const runPipeline = createAsyncThunk(
    "pipeline/runpipeline",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await runPipelineApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

const checkoverride = (
                        key, 
                        pipelineid,
                        apptemplates,
                        interactionmapping,
                        position
                    )=>{
    let appvariable = apptemplates.appvariables[key];
    if(appvariable.parent==""||appvariable.parent==undefined){
        return {
            newkey:key,
            newpipelineid: pipelineid,
            interactionmapping: interactionmapping,
            position: position
        }
    }else{
        let parent = appvariable.parent;
        let indexarr = key.split("__");
        indexarr = indexarr.slice(1, indexarr.length);
        let parentelements = apptemplates.appelements[parent];
        for(let i=0; i<indexarr.length; i++){
            if(i==(indexarr.length-1)){
                parentelements = parentelements[indexarr[i]];
                let interactions = parentelements.interactions;
                let pipelineindex = _.findIndex(interactions, (interaction)=>{return interaction.id==pipelineid});
                if(pipelineindex>-1){
                    let resp = checkoverride(
                                                parent, 
                                                interactions[pipelineindex].pipeline, 
                                                apptemplates, 
                                                interactions[pipelineindex].schemamapping,
                                                indexarr
                                            )
                    return resp;
                }else{
                    return {
                        newkey: key,
                        newpipelineid: pipelineid,
                        interactionmapping: interactionmapping,
                        position: position
                    }
                }
            }else{
                parentelements = parentelements[indexarr[i]].childs;
            }
        }
    }
}

const modifyInteractionPipelineSchemamapping = (pipeline, schemamapping)=>{
    pipeline.pipeline[0] = {...pipeline.pipeline[0],
                            inputschemamapping: schemamapping
                           }
}

export const runPipelines = createAsyncThunk(
    "pipeline/runpipelines",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let delineatedpipeline = {};
            let key = payload.key;
            let pipelineid = payload.pipelineid;
            let event = payload.event;
            let position = payload.position;
            let apptemplatesstate = getState().apptemplates;
            let interactionmapping = payload.schemamapping;
            let resp = checkoverride(
                                        key, 
                                        pipelineid, 
                                        apptemplatesstate,
                                        interactionmapping,
                                        position
                                    );
            loadTemplateVariables("main", resp.newkey, getState, delineatedpipeline, "pipeline", resp.position, event);
            let templatepipelines = JSON.parse(JSON.stringify(apptemplatesstate.templatepipelines[resp.newkey]));
            let pipelineIndex = _.findIndex(templatepipelines, (tp)=>{return tp._id==resp.newpipelineid})
            let pipeline = templatepipelines[pipelineIndex];
            modifyInteractionPipelineSchemamapping(pipeline, resp.interactionmapping);
            let res;
            const callback = (resp)=>{
                res = resp;
            }
            await executeLoop(pipeline, {}, delineatedpipeline, callback,resp.newkey, dispatch, getState);
        }catch(error){
            dispatch(showError("Error executing pipeline"));
            throw error;
        }
    }
)
// 
const pipelineSlice = createSlice({
    "name": "pipeline",
    "initialState": {
        "testactionoutput":null,
        "testactionerror": null,
        "testactionindex": null,
        "testactionerror": null,
        "mappingmap":{
            "":""
        },
        "updateQueue":[],
        "activepipeline":[1],
        "activepipleineid": "",
        "pipelinelist":[
        ],
        "activepipeline":"",
        "pipelinedeleted": false,
        "uploadcounter":0,
        "fileuploadaction":false,
        "fileuploadres":null,
        "eventcreated": false,
        "events":[],
        "triggers":[],
        "pipelineresult": null,
        "pipeline": [],
        "availableloops": [],
        "eventList":[],
        "pipelinedeployed": false,
        "pipelineactivated": false,
        "pipelinepaused": false,
        "inputstage": null
    },
    reducers:{
        resetfileuploadres: (state)=>{
            state.fileuploadres = null;
        },
        resettestactionpoutput: (state)=>{
            state.testactionoutput = null;
        },
        settestactionindex: (state,payload)=>{
            state.testactionindex = payload.payload;
        },
        resettestactionindex: (state)=>{
            state.testactionindex = null;
        },
        setUpdateQueue: (state, action)=>{
            state.updateQueue = action.payload;
        },
        resetmappingmap:(state)=>{
            state.mappingmap = {"":""}
        },
        setmappingmap: (state, payload)=>{
            state.mappingmap = payload.payload
        },
        setmappingkey: (state, action)=>{

            let mappingMap = {...state.mappingmap};
            
            let oldkey = action.payload.oldkey;
            let newkey = action.payload.key;
            let stageindex = action.payload.stageindex;
            let stagetype = action.payload.stagetype;
            if(oldkey!=undefined&&newkey!=undefined){
                if(oldkey!=""){
                    let stages = mappingMap[oldkey].stages;
                    let insertedStageIndex;
                    for(let i=0; i<stages.length; i++){
                        if(stages[i].stageindex>stageindex){
                            insertedStageIndex = i;
                            break;
                        }
                    }
                    stages.splice(insertedStageIndex,1);
                    mappingMap[oldkey] = {...mappingMap[oldkey],
                                      stages: stages
                                     }
                }

                // check if new key exists in the map;
                if(mappingMap[newkey]!=undefined&&mappingMap[newkey]?.stages!=undefined){
                    let stages = [...mappingMap[newkey].stages];
                    let insertindex;
                    for(let i=0; i< stages.length; i++){
                        if(stages[i].stageindex>stageindex){
                            insertindex = i;
                            break;
                        }
                    }
                    stages.splice(insertindex, 0, {
                                                stageindex: stageindex,
                                                stagetype: stagetype
                                                })
                    mappingMap[newkey] = {...mappingMap[newkey],
                                        stages: stages
                                        }
                }else{
                    let stages = [{
                                stageindex: stageindex,
                                stagetype: stagetype
                             }]
                    mappingMap[newkey] = {
                                        stages: stages
                                     }
                }
                state.mappingmap = mappingMap;
            }
            
        },
        resetactivepipelineid: (state, payload)=>{
            state.activepipleineid = ""
        },
        resetactivepipeline: (state, payload)=>{
            state.activepipeline = "";
        },
        setactivepipelineid: (state, payload)=>{
            state.activepipleineid = payload.payload
        },
        resetpipelinedeleted: (state, payload)=>{
            state.pipelinedeleted = false;
        },
        resetfileuploadaction: (state, payload)=>{
            state.fileuploadaction = true;
        },
        resetcreatedevent: (state)=>{
            state.eventcreated = false;
        },
        resetactionerror: (state)=>{
            state.testactionerror = null;
        },
        resettriggers : (state)=>{
            state.triggers = [];
        },
        resetpipelineresult: (state)=>{
            state.pipelineresult = null;
        },
        setStage:(state, action)=>{
            // console.log(payload);
            let position = action.payload.position;
            let stage = action.payload.stage;
            let pipelineCopy = setStageActionSync(position, stage, state.pipeline);
            state.pipeline = pipelineCopy;
        },
        deleteStage: (state, payload)=>{
            let position = payload.position;
        },
        resetpipeline: (state, payload)=>{
            state.pipeline = []
        },
        reseteventlist:(state, payload)=>{
            state.eventList = [];
        },
        resetpipelinedeployed: (state, payload)=>{
            state.pipelinedeployed = false;
        },
        resetpipelineactivated: (state, payload)=>{
            state.pipelineactivated = false
        },
        resetpipelinepaused: (state, payload)=>{
            state.pipelinepaused = false
        },
        resetinputstage: (state, payload)=>{
            state.inputstage = null;
        },
        setpipeline:(state, action)=>{
            state.pipeline = action.payload;
        },
        setStageOutput: (state, action)=>{
            state.testactionoutput = action.payload;
        },
        loaddelineatedPipeline: (state, action)=>{

        }
    },
    "extraReducers":(builder)=>{
        builder.addCase(testAction.fulfilled, (state, action)=>{
            // state.testactionoutput = action.payload;
        })
        .addCase(testAction.rejected,(state, action)=>{
            // state.testactionerror = action.payload;
        })
        .addCase(testActionInt.fulfilled, (state, action)=>{
            // state.testactionoutput = action.payload;
        })
        .addCase(testActionInt.rejected, (state, action)=>{
            // state.testactionerror = action.payload;
        })
        .addCase(createPipeline.fulfilled, (state, action)=>{
            state.activepipleineid = action.payload.id;
        })
        .addCase(getPipelinebySchema.fulfilled, (state, action)=>{
            state.pipelinelist = action.payload.pipelines;
        })
        .addCase(getPipelinebyId.fulfilled, (state, action)=>{
            try{
                let activepipeline = action.payload;
                state.activepipeline = action.payload;
                state.activepipleineid = activepipeline._id;
                _.mapKeys(activepipeline.delineatedpipeline,(value, key)=>{
                    Delineatedpipeline[key] = value;
                })
                state.mappingmap = activepipeline.mappingMap;
                if(activepipeline.pipeline.length==0){
                    state.pipeline = 
                    [
                        {
                            "type": "single",
                            "app": "",
                            "schema":"choose_schema",
                            "action":"choose_action",
                            "outputschema":[],
                            "inputschema": [],
                            "inputschemamapping":[],
                            "subactions":[],
                            "last": false,
                            "active": true,
                            "return": true,
                            "stageindex": 1,
                            "expanded": true 
                        }
                    ]
                }else{
                    state.pipeline = activepipeline.pipeline;
                }
            }catch(error){
                console.log(error);
            }
        })
        .addCase(deletePipelineById.fulfilled, (state, action)=>{
            state.pipelinedeleted = true;
        })
        .addCase(uploadFile.fulfilled, (state, action)=>{
            state.fileuploadaction = true;
            state.fileuploadres = action.payload;
        })
        .addCase(getPipelineTriggersBySchema.fulfilled, (state, action)=>{
            state.triggers = action.payload;        
        })
        .addCase(testPipeline.fulfilled, (state, action)=>{
            state.pipelineresult = action.payload;
        })
        .addCase(updatePipeline.fulfilled, (state, action)=>{
        })
        .addCase(performChange.fulfilled, (state, action)=>{
            state.pipeline = action.payload.pipeline;
            state.mappingmap = action.payload.mappingMap;
        })
        .addCase(setStageAsync.fulfilled, (state, action)=>{
            state.pipeline = action.payload.pipeline;
            state.mappingmap = action.payload.mappingmap;
        })
        .addCase(deployPipeline.fulfilled, (state, action)=>{
            state.pipelinedeployed = true
        })
        .addCase(activatePipeline.fulfilled, (state, action)=>{
            state.pipelineactivated = true
        })
        .addCase(pausePipeline.fulfilled, (state, action)=>{
            state.pipelinepaused = true
        })
        .addCase(getInputStage.fulfilled, (state, action)=>{
            state.inputstage = action.payload;
        })
        .addCase(runPipeline.fulfilled, (state, action)=>{
            state.pipelineresult = action.payload;
        })
        .addCase(addStage.fulfilled, (state, action)=>{
            state.pipeline = action.payload.pipeline;
            state.mappingmap = action.payload.mappingmap
        })
        .addCase(createNextStage.fulfilled, (state, action)=>{
            state.pipeline = action.payload.pipeline;
            state.mappingmap = action.payload.mappingmap;
        })
        .addCase(changeLoopIndex.fulfilled, (state, action)=>{
            state.pipeline = action.payload;
        })
        .addCase(addConditionAction.fulfilled, (state, action)=>{
            state.pipeline = action.payload.pipeline;
            state.mappingmap = action.payload.mappingmap;
        })
        .addCase(deleteConditionAction.fulfilled, (state, action)=>{
            state.pipeline = action.payload.pipeline;
            state.mappingmap = action.payload.mappingmap;
        })
        .addCase(changeblocktype.fulfilled, (state, action)=>{
            state.pipeline = action.payload.pipeline;
            state.mappingmap = action.payload.mappingmap;
        })
        .addCase(updatePipelineF.fulfilled, (state, action)=>{

        })
        .addCase(getNextSchema.fulfilled, (state, action)=>{

        })

    }
})

export const selectTestActionOutput = (state)=> state.pipeline.testactionoutput;

export const selecttestActionIndex = (state)=> state.pipeline.testactionindex;

export const getSchemaMappingValue = (key)=>{return (state)=>state.pipeline.mappingmap[key]}

export const getSchemaMappingMap = (state) => state.pipeline.mappingmap;

export const selectActivepipelineid = (state) => state.pipeline.activepipleineid;

export const selectPipelinelist = (state) => state.pipeline.pipelinelist;

export const selectActivePipeline = (state) => state.pipeline.activepipeline;

export const selectPipelineDeleted = (state) => state.pipeline.pipelinedeleted;

export const selectFileuploadres = (state) => state.pipeline.fileuploadres;

export const selectTestactionError = (state) => state.pipeline.testactionerror;

export const selectPipelineTriggers = (state) => state.pipeline.triggers;

export const selectPipelineresult = (state) => state.pipeline.pipelineresult;

export const selectPipelinedeployed = (state) => state.pipeline.pipelinedeployed;

export const selectPipelineactivated = (state) =>  state.pipeline.pipelineactivated;

export const selectPipelinepaused = (state) => state.pipeline.pipelinepaused; 

export const selectInputStage = (state) => state.pipeline.inputstage;

export const selectStage = createSelector(
    [state=> state.pipeline.pipeline, (state, position)=> position?.stageindex],
    (pipeline, position)=>{
        let stage = null;
        if(position!=undefined){
            let res = findStageByStageIndex(position, pipeline);
            if(res!=null){
                return res;
            }
        }
        return stage;
    }
)

export const selectPipeline = createSelector(
    [state=>state.pipeline.pipeline, (state, position)=> position],
    (pipeline, position)=>{
        let pipelineCopy = pipeline;
        let skeleton = [];
        if(position.length==0){
            for(let i=0; i < pipelineCopy.length; i++){
                skeleton.push({
                    "stageindex":pipelineCopy[i].stageindex,
                    "position":[...position,i]
                });
            }
        }else if (position.length>0){
            let pipelineCopyCopy = pipelineCopy;
            for(let i =0; i < position.length; i++){
                if(i==position.length-2){
                    if(pipelineCopyCopy[position[i]].type=="conditional"){
                        pipelineCopyCopy = pipelineCopyCopy[position[i]].conditions[position[i+1]].pipeline;
                        i=i+1;
                        break;
                    }else{
                        if(pipelineCopyCopy[position[i]].type=="loop"){
                            pipelineCopyCopy = pipelineCopyCopy[position[i]].loop;
                        }else if(pipelineCopyCopy[position[i]].type=="conditional"){
                            pipelineCopyCopy = pipelineCopyCopy[position[i]].conditions[position[i+1]].pipeline;
                            i = i+1;
                        }
                    }        
                }else if(i==position.length-1){
                    if(pipelineCopyCopy[position[i]].type=="loop"){
                        pipelineCopyCopy = pipelineCopyCopy[position[i]].loop
                        break
                    }else{
                        if(pipelineCopyCopy[position[i]].type=="loop"){
                            pipelineCopyCopy = pipelineCopyCopy[position[i]].loop;
                        }else if(pipelineCopyCopy[position[i]].type=="conditional"){
                            pipelineCopyCopy = pipelineCopyCopy[position[i]].conditions[position[i+1]].pipeline;
                            i = i+1;
                        }
                    }
                }else{
                    if(pipelineCopyCopy[position[i]].type=="loop"){
                        pipelineCopyCopy = pipelineCopyCopy[position[i]].loop;
                    }else if(pipelineCopyCopy[position[i]].type=="conditional"){
                        pipelineCopyCopy = pipelineCopyCopy[position[i]].conditions[position[i+1]].pipeline;
                        i = i+1;
                    }
                }
            }

            for(let i=0; i < pipelineCopyCopy.length; i++){
                skeleton.push({
                    "stageindex": pipelineCopyCopy[i].stageindex,
                    "position": [...position, i]
                })
            }
        }
        return skeleton;
    }
)

export const getVariablePipeline = createSelector(
    [state=>state.pipeline.pipeline, (state, position)=> position?.position],
    (pipeline, position)=>{
        let vPipeline = [];
        // [1, 0, 0]
        if(position!=undefined&&position.length>0){
            let pipelineCopyCopy = pipeline;
            for(let i=0; i< position.length; i++){
                if(i==(position.length-1)){
                    for(let j=0; j < position[i]; j++){
                        vPipeline.push({...pipelineCopyCopy[j],
                                        });
                    }
                }else{
                    if(pipelineCopyCopy[position[i]].type=="loop"){
                        for(let j=0; j < position[i]; j++){
                            vPipeline.push({
                                            ...pipelineCopyCopy[j],
                                            });
                        }
                        vPipeline.push({...pipelineCopyCopy[position[i]],
                                         active: true,
                                        })
                        pipelineCopyCopy = pipelineCopyCopy[position[i]].loop;
                        
                    }else if(pipelineCopyCopy[position[i]].type=="conditional"){
                        for(let j=0; j < position[i]; j++){
                            vPipeline.push({...pipelineCopyCopy[j],
                                            });
                        }
                        pipelineCopyCopy = pipelineCopyCopy[position[i]].conditions[position[i+1]].pipeline;
                        i = i+1;                        
                    }
                }
            }    
        }
        return vPipeline;
    }
)

export const getDependantStages = (stageindex)=>{
    return (state)=>{
        let mappingmapcopy = {...state.pipeline.mappingmap};
        let depandants = getDependantStagesInt(mappingmapcopy, stageindex);
        return depandants;
    }
}

export const selectPipelineRemote = (state) => state.pipeline.pipeline; 


export const {
                resetfileuploadres,
                resettestactionpoutput, 
                settestactionindex, 
                setmappingkey,
                resetmappingmap,
                resetactivepipelineid,
                resetactivepipeline,
                setactivepipelineid,
                resetpipelinedeleted,
                setmappingmap,
                resetfileuploadaction,
                resetcreatedevent,
                resetactionerror,
                resetpipelineresult,
                setStage,
                deleteStage,
                resetpipeline,
                reseteventlist,
                resetpipelinedeployed,
                resetpipelineactivated,
                resetpipelinepaused,
                resetinputstage,
                setUpdateQueue,
                setpipeline,
                setStageOutput
            } = pipelineSlice.actions;

export default pipelineSlice.reducer;