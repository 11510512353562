import {deleteM, get, postjson, putjson} from "../signin/axios";
import { baseURL, signinURL } from "../../env";

export const createCollaborationAPi = async(payload)=>{
    let res = await postjson(baseURL+"collaboration/create", payload);
    return res;
}

export const getCollaboratorsApi = async(payload)=>{
    let res = await get(baseURL+"collaborations/getlist/");
    return res;
}

export const getCollaboratorApi = async(payload)=>{
    let res = await get(baseURL+"collaborations/getone/"+payload);
    return res;
}

export const updateCollaborationApi = async(payload)=>{
    let res = await putjson(baseURL+"collaborations/getone/"+payload.id, payload);
    return res;
}

export const deleteCollaborationApi = async(payload)=>{
    let res = await deleteM(baseURL+"collaborations/getone/"+payload);
    return res;
}

export const getCollabWorkspacesApi = async(payload)=>{
    let res = await get(baseURL+"collaboration/workspaces");
    return res;
}

export const makeCollaborationActiveApi = async(payload)=>{
    let token = localStorage.getItem("token");
    if(token!=undefined&&token!=null){
        let res = await get(baseURL+"collaboration/active/"+payload);
        return res;
    }else{
        let res = await fetch(signinURL+"collaboration/active/"+payload);
        return res.json();
    }
}

export const getDomainsApi = async(payload)=>{
    let res = get(baseURL+"collaboration/hosts/");
    return res;
}