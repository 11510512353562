import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Button, IconButton, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import MarketplaceGrid from "./marketplace/MarketplaceGrid";
import "./AppCreateModal.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const AppCreateModal = ({open, handleClose, saveApp})=>{

    const {workspace} = useParams();

    const scrollRef = useRef(null);

    const [appname, setAppName] = useState("");

    const [file, setFile] = useState("");

    const [error, setError] = useState("");

    const [createoption, setCreateOption] = useState("new");

    const handleChange = (e)=>{
        let file = e.target.files[0];
        setFile(file);
    }

    const save = ()=>{
        if(appname==""){
          setError("Appname can be null");
          return;
        }
        if(file==""||file==null||file=={}){
          setError("Logo is need to create an app");
          return;
        }
        setError("");        
        saveApp({
          "name": appname,
          "logo": file,
          "workspace": workspace,
        })
    }

    return(
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <div style={{display: "flex"}}>
                <div className={createoption=="new"?"create-option active":"create-option"} onClick={()=>{setCreateOption("new")}}>
                    New
                </div>
                <div className={createoption=="marketplace"?"create-option active":"create-option"} onClick={()=>{setCreateOption("marketplace")}}>
                    Marketplace
                </div>
            </div>
            {createoption=="new"&&
                <div className="new-create-box">
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Create App
                    </Typography>
                    <div>
                    {error!=""&&
                        <div style={{padding: 5, border: "1px solid red"}}>
                            {error}
                        </div>
                    }
                    </div>
                    <div>
                        <div style={{padding: 10, margin:5}}>
                            <TextField value={appname} onChange={(e)=>{setAppName(e.target.value)}} label="App Name"></TextField>
                        </div>
                        <div style={{padding: 10, margin:5}}>
                            <span style={{padding:10}}>Logo:</span> 
                            <input type="file" onChange={(e)=>{handleChange(e)}}></input>
                        </div>
                        <div>
                            <Button onClick={()=>{save()}} style={{color: "#3A07CD"}}>Save</Button>
                            <Button onClick={()=>{handleClose()}} style={{color: "#3A07CD"}}>Cancel</Button>
                        </div>
                    </div>
                </div>
            }
            {createoption=="marketplace"&&
                <>

                    <div
                        ref={scrollRef}
                        className="app-market-place-grid"
                    >
                        <div style={{display: "flex", justifyContent:"flex-end"}}>
                            <IconButton onClick={()=>{handleClose()}}>
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                            </IconButton>
                        </div>
                        <MarketplaceGrid
                            source={"workspace"}
                            sourceid={workspace}
                            scrollref={scrollRef}
                        >
                        </MarketplaceGrid>
                    </div>
                </>   
            }
          </Box>
      </Modal>
    )
}

export default AppCreateModal