import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import {
         getAppTemplatesApi, 
         getElementsApi, 
         getElementsProdApi, 
         getRouteParamsApi, 
         getTemplateSettingsApi, 
         getTemplateVariablesApi, 
         getTemplateVariablesProdApi, 
         templatePipleineApi, 
         updateRouteParamsApi, 
         updateTemplateApi, 
         updateTemplateSettingsApi, 
         updateTemplateVariablesApi,
         templatePipelineProdApi, 
         getTemplateCssClassesApi,
         getTemplateCssClassesProdApi,
         updateTemplateCssClassesApi,
         deployWebsiteApi,
         updateTemplateChildEnabledApi,
         getTemplateChildEnabledApi,
         interactionpipelinesApi
       } from "./apptemplate.service";
import { showError } from "../error.slice";
import {getKey} from "../../apps/appdesigner/utils";
import {generateSchema} from "../../Services/pipelines/server/utils";
import {executeElementConditions} from "../pipelines/server/server-apps/conditional";
import {TypeProps} from "./../../apps/appdesigner/Settings";
import { camelCaseHypens, generateStyleValue} from "../../apps/appdesigner/utils";
import { getWebsiteTemplates } from "../websites/website.slice";
import * as _ from "lodash";

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export const getSystemClassName = ()=>{
    let classnames =[];
    return ()=>{
        let valid = false;
        let name;
        while(!valid){
            name = makeid(5);
            let nameindex = _.findIndex(classnames, (n)=>{return n==name});
            if(nameindex==-1){
                valid= true
            }
        }
        return name;
         
    }
}

export const classNameFactory = getSystemClassName();

const parseSchemaKey = (val)=>{
    if(val!=""){
        let steps = val.split(".");
        let appstep = steps[0];
        let appstepsplit = appstep.split("[");
        appstepsplit = appstepsplit[0];
        // appstepsplit = appstepsplit.split("]");
        // let appindex = appstepsplit[0];
        steps[0] = appstepsplit;
        return steps;
    }else{
        return [null, null, null];
    }
    
}

const getValuedSchema = (sc, sm, templatevariables)=>{
    for(let i=0; i< sc.length; i++){
        let val;
        if(sc[i].type=="object"){
            getValuedSchema(sc[i].subschema, sm[i].mapping, templatevariables);
            val = getAppObjectVal(
                                    sc[i].subschema, 
                                    sm[i].mapping,
                                    templatevariables,
                                    [],
                                    [],
                                    [],
                                    {},

                                 );
        }else{
            val = getAppObjectVal(
                                    sc[i], 
                                    sm[i],
                                    templatevariables,
                                    [],
                                    [],
                                    [],
                                    {});
        }
        sc[i] = {...sc[i],
                 value: val
                }
    }
}

export const getLocalStorageSchema = ()=>{
    let schema = [{
        "key": "localstorage",
        "label": "LocalStorage",
        "type": "object",
        "subschema":[],
        "value":{}
    }];
    let subschema = [];
    let lStorage = {};
    for(let i=0; i< localStorage.length; i++){
        let storagekey = localStorage.key(i);
        let storagekeyvalue = localStorage.getItem(storagekey);
        let keyschema = {
            "key": storagekey,
            "label": storagekey,
            "type": "string",
            "value": storagekeyvalue,
            "subschema":[]
        }
        subschema.push(keyschema);
        lStorage[storagekey] = storagekeyvalue
    }
    if(subschema.length>0){
        schema[0] = {...schema[0],
                     subschema: subschema,
                     value: lStorage
                    }
        return schema;
    }else{
        return []
    }
}

export const getSessionStorage = ()=>{
    let schema = [{
        "key": "localstorage",
        "label": "LocalStorage",
        "type": "object",
        "subschema":[],
        "value":{}
    }];
    let subschema = [];
    let lStorage = {};
    for(let i=0; i< localStorage.length; i++){
        let storagekey = localStorage.key(i);
        let storagekeyvalue = localStorage.getItem(storagekey);
        let keyschema = {
            "key": storagekey,
            "label": storagekey,
            "type": "string",
            "value": storagekeyvalue,
            "subschema":[]
        }
        subschema.push(keyschema);
        lStorage[storagekey] = storagekeyvalue
    }
    if(subschema.length>0){
        schema[0] = {...schema[0],
                     subschema: subschema,
                     value: lStorage
                    }
        return schema;
    }else{
        return []
    }   
}

const getValfromPipe = (
                            mapping, 
                            variables, 
                            loopvariables, 
                            inputs, 
                            routeparams,
                            type
                        )=>{
    let parsedVal = parseSchemaKey(mapping);
    let value;
    try{
        for(let i=0; i < parsedVal.length; i++){
            if(i==0){
                if(parsedVal[0]=="template"){
                    value = variables;
                }else if(parsedVal[0]=="loopvar"){
                    value = loopvariables;
                }else if(parsedVal[0]=="inputs"){
                    value = inputs;
                    i=i+1;
                }else if(parsedVal[0]=="routeparams"){
                    value = routeparams;
                }else if(parsedVal[0]=="localstorage"){
                    value = getLocalStorageSchema();
                }else if(parsedVal[0]=="sessionstorage"){
                    value = getSessionStorage();
                }
                
            }else if(i==(parsedVal.length-1)){
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                }else{
                    if(type=="file"){
                        let filesubschema = value[concernedkeyindex].subschema;
                        let urlindex = _.findIndex(filesubschema, (fss)=>{return fss.key=="url"});
                        value = filesubschema[urlindex].value;
                    }else{
                        value = value[concernedkeyindex].value;
                    }
                }
            }
            else{
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex]?.deleted){
                    value = undefined;
                    break;
                }else{
                    value = value[concernedkeyindex].subschema;
                }
            }
        }
    }catch(error){
        value = undefined
    }
    return value;
}
  
const isValidURL = (str)=> {
    if(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(str)) {
         return true
     } else {
         return false
     }
}

export const getAppObjectVal = (
                                sc, 
                                sm,
                                templatevariables, 
                                loopvariables, 
                                inputs,
                                routeparams, 
                                objectval,
                                env,
                                currentpath
                                )=>{
    if((sm!=undefined&&sm!=""&&sm!=null)){
        if(Array.prototype.isPrototypeOf(sm)){
            let schemamapping = [...sm];
            let schemaCopy = [...sc];
            // console.log(schemamapping);
            for(let i=0; i<schemamapping.length;i++){
                    if(Array.prototype.isPrototypeOf(schemamapping[i]?.mapping)){ 
                        let subobjectval = getAppObjectVal(
                                                            sc[i].subschema,
                                                            schemamapping[i]?.mapping, 
                                                            templatevariables, 
                                                            loopvariables, 
                                                            inputs, 
                                                            routeparams,
                                                            {},
                                                            env,
                                                            currentpath
                                                            );

                        objectval[schemamapping[i].key] = subobjectval;
                    }else{
                        let sci = schemaCopy[i];
                        if(sci.type=="array"){
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = "";
                            }else if(schemamapping[i].mapping?.action=="const"){
                                if(sci.subschema[0].type!="object"){
                                    let submapping = schemamapping[i].mapping?.mapping;
                                    let arrayval = [];
                                    for(let i=0; i< submapping.length; i++){
                                        if(submapping[i].action=="const"){
                                            arrayval.push(submapping[i].val)
                                        }else if(submapping[i].action=="get"){
                                            arrayval.push(getValfromPipe(
                                                                            submapping[i].val, 
                                                                            templatevariables, 
                                                                            loopvariables,
                                                                            inputs,
                                                                            routeparams,
                                                                            sci.subschema[0].type                        
                                                                        ));
                                        }
                                    }
                                    objectval[schemamapping[i].key] = arrayval;
                                }else{
                                    let arrval = [];
                                    for(let j=0; j< schemamapping[i].mapping.mapping.length; j++){
                                        let objval = getAppObjectVal(
                                                                      sci.subschema[0].subschema,
                                                                      schemamapping[i].mapping.mapping[j],
                                                                      templatevariables, 
                                                                      loopvariables, 
                                                                      inputs, 
                                                                      routeparams,
                                                                      {},
                                                                      env,
                                                                      currentpath
                                                                      );
                                        arrval.push(objval);
                                    }
                                    objectval[schemamapping[i].key] = arrval;
                                }
                            }else if(schemamapping[i].mapping?.action=="get"){
                                // console.log
                                objectval[schemamapping[i].key] = getValfromPipe(
                                                                                    schemamapping[i].mapping.val, 
                                                                                    templatevariables, 
                                                                                    loopvariables,
                                                                                    inputs,
                                                                                    routeparams,
                                                                                    sci.type
                                                                                    );
                            }
                        }else if(sci.type=="file"){
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = '';
                            }else if(schemamapping[i].mapping?.action=="const"){
                                objectval[schemamapping[i].key] = schemamapping[i].mapping?.file.url;
                            }else if(schemamapping[i].mapping?.action=="get"){
                                objectval[schemamapping[i].key] = getValfromPipe(
                                                                                  schemamapping[i].mapping?.val, 
                                                                                  templatevariables, 
                                                                                  loopvariables, 
                                                                                  inputs,
                                                                                  routeparams,
                                                                                  "file"
                                                                                )
                            }else if(schemamapping[i].mapping.action=="urlconst"){
                                objectval[schemamapping[i].key] = schemamapping[i].mapping.val;
                            }else if(schemamapping[i].mapping.action=="urlget"){
                                objectval[schemamapping[i].key] = getValfromPipe(
                                                                                  schemamapping[i].mapping.val,
                                                                                  templatevariables,
                                                                                  loopvariables,
                                                                                  inputs,
                                                                                  routeparams
                                                                                )
                            }

                        }
                        else{
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = "";
                            }else if(schemamapping[i].mapping?.action=="const"){
                                if(schemamapping[i].key=="href"){
                                    let v = schemamapping[i].mapping?.val;
                                    if(env==false){
                                        if(!isValidURL(v)){
                                            if(v.startsWith("/")){
                                                v = currentpath+v;
                                            }else{
                                                v = currentpath+ "/"+v;
                                            }
                                        }
                                        objectval[schemamapping[i].key] = v;
                                    }else{
                                        objectval[schemamapping[i].key] = v;
                                    }
                                }else{
                                    objectval[schemamapping[i].key] = schemamapping[i].mapping?.val;
                                }
                                
                            }else if(schemamapping[i].mapping?.action=="get"){
                                // console.log
                                if(schemamapping[i].key=="href"){
                                    let v = getValfromPipe(
                                        schemamapping[i].mapping?.val,
                                        templatevariables,
                                        loopvariables,
                                        inputs,
                                        routeparams,
                                        undefined
                                    )
                                    if(env==false){
                                        if(!isValidURL(v)){
                                            if(v.startsWith("/")){
                                                v = currentpath+v;
                                            }else{
                                                v = currentpath+ "/"+v;
                                            }
                                        }
                                        objectval[schemamapping[i].key] = v;
                                    }else{
                                        objectval[schemamapping[i].key] = v;
                                    }
                                }else{
                                    objectval[schemamapping[i].key] = getValfromPipe(
                                        schemamapping[i].mapping?.val, 
                                        templatevariables, 
                                        loopvariables,
                                        inputs,
                                        routeparams,
                                        undefined
                                        
                                        );
                                }
                                
                            }
                    }
                }
            }
            return objectval;
        }else{
          let val;
          if(sc.type=="array"){
            if(sm.mapping.action==undefined){
                 val = [];
            }else if(sm.mapping?.action=="const"){
                if(sc.subschema[0].type!="object"){
                    let submapping = sm.mapping?.mapping;
                    let arrayval = [];
                    for(let i=0; i< submapping.length; i++){
                        if(submapping[i].action=="const"){
                            arrayval.push(submapping[i].val)
                        }else if(submapping[i].action=="get"){
                            arrayval.push(getValfromPipe(
                                                            submapping[i].val, 
                                                            templatevariables, 
                                                            loopvariables,
                                                            inputs,
                                                            routeparams,
                                                            sc.type
                                                            ));
                        }
                    }
                    val = arrayval;
                }else{
                    let arrval = [];
                    let submapping = sm.mapping?.mapping;
                    for(let j=0; j< submapping.length; j++){
                        let val = getAppObjectVal(
                                                    sc.subschema[0].subschema, 
                                                    submapping[j],
                                                    templatevariables, 
                                                    loopvariables, 
                                                    inputs,
                                                    routeparams, 
                                                    {},
                                                    env,
                                                    currentpath
                                                    );
                        arrval.push(val);
                    }
                    val = arrval;
                }
            }else if(sm.mapping?.action=="get"){
                // console.log
                val = getValfromPipe(
                                        sm.mapping.val, 
                                        templatevariables, 
                                        loopvariables,
                                        inputs,
                                        routeparams,
                                        sc.type
                                        );
            }
          }else if(sc.type=="object"){
            if(sm.mapping?.action==undefined){
                objectval[sc.key] = getAppObjectVal(
                                                    sc.subschema, 
                                                    sm.mapping,
                                                    templatevariables, 
                                                    loopvariables, 
                                                    inputs,
                                                    routeparams, 
                                                    {},
                                                    env,
                                                    currentpath
                                                    ); 
            }else if(sm.mapping?.action=="get"){
                objectval[sc.key] = getValfromPipe(
                                                    sm.mapping.val, 
                                                    templatevariables, 
                                                    loopvariables,
                                                    inputs,
                                                    routeparams,
                                                    sc.type
                                                    );
            }
            return objectval;
          }else if(sc.type=="file"){
            if(sm.mapping?.action=="const"){
                val = sm.mapping.file.url;
            }else if(sm.mapping?.action=="get"){
                val = getValfromPipe(
                                        sm.mapping.val, 
                                        templatevariables, 
                                        loopvariables, 
                                        inputs,
                                        routeparams,
                                        "file")
            }else if(sm.mapping?.action=="urlconst"){
                val = sm.mapping.val;
            }else if(sm.mapping?.action=="urlget"){
                val = getValfromPipe(
                                        sm.mapping.val,
                                        templatevariables,
                                        loopvariables,
                                        inputs,
                                        routeparams
                                    )
            }
          }else{
            if(sc.key=="href"){
                if(sm.mapping?.action=="const"){ 
                    val = sm.mapping.val
                    // hack to make routing work in the app editor
                    if(env==false){
                        if(!isValidURL(val)){
                            if(val.startsWith("/")){
                                val = currentpath+val;
                            }else{
                                val = currentpath+ "/"+val;
                            }
                        }
                    }
                    
                }else if(sm.mapping?.action=="get"){
                    val = getValfromPipe(
                                            sm.mapping.val, 
                                            templatevariables, 
                                            loopvariables,
                                            inputs,
                                            routeparams,
                                            sc.type
                                            );
                    // hack to make routing work in the app editor
                    if(env==false){
                        if(!isValidURL(val)){
                            if(val.startsWith("/")){
                                val = currentpath+val;
                            }else{
                                val = currentpath+ "/"+val;
                            }
                        }
                    }
                }
            }else{
                if(sm.mapping?.action=="const"){ 
                    val = sm.mapping.val
                }else if(sm.mapping?.action=="get"){
                    val = getValfromPipe(
                                            sm.mapping.val, 
                                            templatevariables, 
                                            loopvariables,
                                            inputs,
                                            routeparams,
                                            sc.type
                                            );
                }
            }
          }
          return val;  
        }
    }else{
        return {};
    }
}

const adddepstovar = (variable, depkey)=>{
    if(variable.depsarray==undefined){
        let depsarray = [];
        depsarray.push(depkey);
        variable = {...variable,
                    depsarray: depsarray
                    }
    }else{
        let depsarray = JSON.parse(JSON.stringify(variable.depsarray));
        let depexist = _.findIndex(depsarray,(dep)=>{return dep==depkey});
        if(depexist==-1){
            depsarray.push(depkey);
        }   
        variable = {...variable,
                    depsarray: depsarray
                    }
    }
    
    return variable;    
}

const removedepsfromvar = (variable, depkey)=>{
    let depsarray = [...variable.depsarray];
    let depindex = _.findIndex(depsarray, (dep)=>{ return dep==depkey});
    if(depindex>-1){
        depsarray.splice(depindex,1);
        variable = {...variable,
                    depsarray: depsarray
                }
    }
    return variable;
}

const getInputDefaults = (inputs)=>{
    let highestIndex = 0;
    for(let i=0; i<inputs.length; i++){
        if(inputs[i].key.startsWith("untitled")){
            let inputcopy = {...inputs[i]};
            let inputname = inputcopy.key;
            inputname = inputname.replace("untitled", "");
            if(parseInt(inputname)> highestIndex){
                highestIndex = parseInt(inputname);
            }
        }
    }
    highestIndex = highestIndex+1;
    let inputname = "untitled"+highestIndex.toString();
    return inputname;
}

export const getInputProps = (type)=>{
    let typeprops = [...TypeProps];
    let textindex = _.findIndex(typeprops, (typeprop)=>{ return typeprop.type==type});
    let props = typeprops[textindex].props;
    return props;
}

const getInputOutputtype = (type)=>{
    let typeprops = [...TypeProps];
    let typeindex = _.findIndex(typeprops, (typeprop)=>{ return typeprop.type==type});
    let outputtype = typeprops[typeindex].outputtype;
    return outputtype;
}

const isElementInput = (element)=>{
    let inputtags = ["input", "select"];
    let tagindex = _.findIndex(inputtags, (tag)=>{ return element.tag==tag});
    if(tagindex>-1){
        return true;
    }else{
        return false;
    }
}

const addElementAction = (elements, position, element, inputs)=>{
    let elementsCopy = [...elements];
    let classname = classNameFactory();
    if(position.length==0){
        let elementCopy = {...element,
                            type: "single",
                            conditions:[[]],
                            loopvariable:[],
                            loopvariablemapping:[],
                            styles:[],
                            scsystems: [],
                            scusers: [],
                            interactions:[],
                            "desktop": true,
                            "mobile": true,
                            extraprops: []
                          }
        if(elementCopy.tag=="input"){
            let inputname = getInputDefaults(inputs);
            let typeprops = getInputProps("text")
            elementCopy = {...elementCopy,
                           name: inputname,
                           inputindex: inputs.length,
                           inputtype: "text",
                           props: typeprops
                          }
            let inputvariable = {
                "type": "string",
                "val": "",
                "key": inputname,
                "label": inputname
            }
            inputs.push(inputvariable)
        }
        elementsCopy.push(elementCopy)
    }else{
        let secondPointer = elementsCopy;
        for(let i=0; i< position.length+1; i++){
            if(i==position.length){
                let elementCopy = {...element,
                                   type: "single",
                                   conditions: [[]],
                                   loopvariable:[],
                                   loopvariablemapping:[],
                                   styles:[],
                                   scsystems: [],
                                   scusers: [],
                                   interactions: [],
                                   desktop: true,
                                   mobile: true,
                                   extraprops: []
                                  }
                if(elementCopy.tag=="input"||elementCopy.tag=="select"){
                    let inputname = getInputDefaults(inputs);
                    let typeprops = getInputProps("text");
                    elementCopy = {...elementCopy,
                                    name: inputname,
                                    inputindex: inputs.length,
                                    inputtype: "text",
                                    props: typeprops
                                    }
                    let inputvariable = {
                        "type": "string",
                        "val": "",
                        "key": inputname,
                        "label": inputname
                    }
                    inputs.push(inputvariable)
                }
                secondPointer.splice(secondPointer.length,0,elementCopy);
            }else{
                secondPointer = secondPointer[position[i]].childs;
            }
        }
    }
    return {
        elements: elementsCopy,
        inputs: inputs
    }
}

export const addElement = createAsyncThunk(
    "apptemplates/addelement",
    async(payload,{dispatch, rejectWithValue, getState})=>{
        if(payload.type=="native"){
            let state = getState();
            let appstate = state.apptemplates;
            let elements = JSON.parse(JSON.stringify(appstate.elements));
            let inputs = JSON.parse(JSON.stringify(appstate.appinputs));
            inputs = inputs[appstate.activetemplateid];
            let results = addElementAction(
                                            elements, 
                                            payload.position, 
                                            payload.element, 
                                            inputs
                                          )
            return results;
        }else if(payload.type=="templates"){
            let templatevariable = await getTemplateVariablesApi(payload.element._id);
            let element = {...payload.element};
            if(templatevariable.variable==null){
                element = {...element,
                    elemtype: "template",
                    props: [],
                    propsmapping: [],
                    interactions: []
                   }
            }else{
                element = {...element,
                            elemtype: "template",
                            props: templatevariable.variable.schema,
                            propsmapping: templatevariable.variable.schemamapping
                         }

            }
            
            let state = getState();
            let appstate = state.apptemplates;
            let elements = JSON.parse(JSON.stringify(appstate.elements));
            let inputs = JSON.parse(JSON.stringify(appstate.appinputs));
            inputs = inputs[appstate.activetemplateid];
            let results = addElementAction(
                                            elements,
                                            payload.position,
                                            element,
                                            inputs
                                          )
            return results;
        }
    }
)

export const templatePipleine = createAsyncThunk(
    "apptemplates/templatepipeline",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await templatePipleineApi(payload);
            return res;
        }catch(err){
            throw err;
        }
    }
)

export const getAppPipelines = createAsyncThunk(
    "apptemplates/apppipeline",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let activetemplateid = getState().apptemplates.activetemplateid;
            if(activetemplateid==payload.templateid){
                let res = await templatePipleineApi(payload.templateid);
                return {
                    templateid: payload.templateid,
                    key: payload.key,
                    pipelines: res,
                }
            }else{
                let res = await templatePipleineApi(payload.templateid);
                return{
                    templateid: payload.templateid,
                    key: payload.key,
                    pipelines: res
                }
            }

        }catch(err){
            throw err;
        }
    }
)

export const getTemplateVariables = createAsyncThunk(
    "apptemplates/gettemplatevariables",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getTemplateVariablesApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error getting variables"))
            throw err;
        }
    }
)

export const getAppVariables = createAsyncThunk(
    "apptemplates/getappvariables",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            
            if(payload.source=="pipelines"){
                let resp = await getTemplateVariablesApi(payload.templateid);
                let res = {
                    key: payload.key,
                    variable: resp,
                    elmkey: payload.elmkey,
                    indexarr: payload.indexarr
                }
                return res;
            }else{
                // if templateid in designer fetch the dev variables else fetch the prod variables
                let activetemplateid = getState().apptemplates.activetemplateid;
                if(payload.templateid==activetemplateid){
                    let resp = await getTemplateVariablesApi(payload.templateid);
                    let res = {
                        key: payload.key,
                        variable: resp,
                        elmkey: payload.elmkey,
                        indexarr: payload.indexarr
                    }
                    return res;
                }else{
                    let resp = await getTemplateVariablesApi(payload.templateid);
                    let res = {
                        key: payload.key,
                        variable: resp,
                        elmkey: payload.elmkey,
                        indexarr: payload.indexarr
                    }
                    return res;
                }
            }
            
        }catch(err){
            dispatch(showError("Error getting variables"));
            throw err;
        }
    }
)

export const updateTemplateVariables = createAsyncThunk(
    "apptemplates/updatetemplatevariables",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateTemplateVariablesApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error updating variables"));
            throw err;
        }
    }
)

export const getTemplateClasses = createAsyncThunk(
    "apptemplates/gettemplateclasses",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getTemplateCssClassesApi(payload);
            return res.result;
        }catch(err){
            dispatch(showError("Error getting classes"));
            throw err;
        }
    }
)

export const getAppclasses = createAsyncThunk(
    "apptemplates/getappclasses",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let activetemplateid = getState().apptemplates.activetemplateid;
            if(payload.templateid==activetemplateid){
                let resp = await getTemplateCssClassesApi(payload.templateid);
                if(resp?.result!=null){
                    return {
                        key: payload.key,
                        classes: resp.result.classes,
                        elmkey: payload.elmkey,
                        indexarr: payload.indexarr
                    }
                }else{
                    return null;
                }
                
            }else{
                let resp = await getTemplateCssClassesApi(payload.templateid);
                if(resp!=null&&resp?.result!=null){
                    return {
                        key: payload.key,
                        classes: resp.result.classes,
                        elmkey: payload.elmkey,
                        indexarr: payload.indexarr
                    }
                }else{
                    return null;
                }  
            }
        }catch(err){
            dispatch(showError("Error getting app classes"))
        }
    }

)

export const updateTemplateClasses = createAsyncThunk(
    "apptemplates/updatemplateclasses",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateTemplateCssClassesApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error updating css classes"));
            throw err;
        }
    }
)

export const getRouteParams = createAsyncThunk(
    "apptemplates/getrouteparams",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getRouteParamsApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error getting routeparams"));
            throw err;
        }
    }
)

export const getAppRouteParams = createAsyncThunk(
    "apptemplates/getapprouteparams",
    async(payload, {dispatch, rejectWithValue})=>{

    }
)

export const updateRouteParams = createAsyncThunk(
    "apptemplates/updaterouteparams",
    async(payload, {dispatch, rejectWithValue})=>{
        try{    
            let res = await updateRouteParamsApi(payload);
            return res;
        }catch(err){
            dispatch(showError("Error updating routeparams"));
        }
    }
)

export const updateTemplate = createAsyncThunk(
    "apptemplates/updatetemplate",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let resp = await updateTemplateApi(payload);
            return resp;
        }catch(error){
            dispatch(showError("Error updating templates"));
            throw error;
        }
        
    }
)

export const updateTemplateChildEnabled = createAsyncThunk(
    "apptemplates/updatetemplatechildenabled",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let resp = await updateTemplateChildEnabledApi(payload);
            return resp;
        }catch(error){
            dispatch(showError("Error updating child enabled"));
            throw error;
        }

    }
)

export const getTemplateChildEnabled = createAsyncThunk(
    "apptemplates/gettemplatechildenabled",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let resp = await getTemplateChildEnabledApi(payload);
            return resp;
        }catch(error){
            dispatch(showError("Error getting child enabled"));
            throw error;
        }
    }
)

export const getElements = createAsyncThunk(
    "apptemplates/getelements",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let resp = await getElementsApi(payload);
            return resp;
        }catch(error){
            dispatch(showError("Error fetching elements"));
            throw error;
        }
    }
)

export const getAppElements = createAsyncThunk(
    "apptemplates/getappelements",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{

            // if templateid in designer fetch the dev elements else fetch the prod elements
            let activetemplateid = getState().apptemplates.activetemplateid;
            if(payload.source=="pipelines"){
                let resp = await getElementsApi(payload.templateid);
                let indexarr = payload.indexarr;
                let key = getKey(payload.templateid, indexarr);
                let res = { 
                            key: key,
                            elements: resp.elements,
                            source: payload.source
                        };
                return res;

            }else{
                if(activetemplateid==payload.templateid){
                    let resp = await getElementsApi(payload.templateid);
                    let indexarr = payload.indexarr;
                    let key = getKey(payload.templateid, indexarr);
                    let res = { 
                                key: key,
                                elements: resp.elements,
                                source: payload.source
                            };
                    return res;
    
                }else{
                    let resp = await getElementsApi(payload.templateid);
                    let indexarr = payload.indexarr;
                    let key = getKey(payload.templateid, indexarr);
                    let res = {
                        key: key,
                        elements: resp.elements,
                        source: payload.source
                    }
                    return res;
                }
            }
        }catch(error){
            dispatch(showError("Error fetching elements"));
            throw error;
        }
    }
)

export const updateTemplateSettings = createAsyncThunk(
    "apptemplates/updatetemplatesettings",
    async(payload, {dispatch, rejectWithValue})=>{
        try{    
            let res = await updateTemplateSettingsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating templates"));
            throw error;
        }
    }
)

export const getTemplateSettings = createAsyncThunk(
    "apptemplates/gettemplatesettings",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getTemplateSettingsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting template settings"));
            throw error;
        }
    }
)

export const getAppTemplates = createAsyncThunk(
    "apptemplates/getapptemplates",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getAppTemplatesApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting app templates"));
            throw error;
        }
    }
)

export const updateElementProps = createAsyncThunk(
    "apptemplates/updateelementprops",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let state = JSON.parse(JSON.stringify(getState().apptemplates));
            let props = JSON.parse(JSON.stringify(payload.props));
            let position = JSON.parse(JSON.stringify(payload.position));
            let element = JSON.parse(JSON.stringify(payload.element));
            if(element.elemtype=="template"){
                let sm = props.props;
                let schemamapping = props.propsmapping;
                let templatevariables = state.appvariables[state.activetemplateid];
                // get valued schema
                getValuedSchema(sm, schemamapping, templatevariables.schema);
                let templatekey = getKey(element._id, position);
                let appvariablesCopy = JSON.parse(JSON.stringify(state.appvariables));
                appvariablesCopy[templatekey] = {...appvariablesCopy[templatekey],
                                                 schema: sm,
                                                 schemamapping: schemamapping
                                                }
                
                
                element = {...element,
                           props: sm,
                           propsmapping: schemamapping
                          }
                let elements = JSON.parse(JSON.stringify(state.elements));
                elements = setElementAction(elements, position, element);


                templatevariables = {...state.appvariables[state.activetemplateid]};
                
                //add child to deps of parent
                templatevariables = adddepstovar(templatevariables, templatekey);
                
                appvariablesCopy[state.activetemplateid] = templatevariables;

                dispatch(updateTemplateVariables({
                    "templateid": state.activetemplateid,
                    "variable":{
                        "schema": templatevariables.schema,
                        "schemamapping": templatevariables.schemamapping,
                        "depsarray": templatevariables.depsarray
                    }
                }))
                return {
                    type: "template",
                    appvariables: appvariablesCopy,
                    elements: elements
                }  
            }
            if(element.elemtype=="native"){
                element = {...element,
                           props: props.props,
                           propsmapping: props.propsmapping
                          }
                let elements = [...state.elements];
                elements = setElementAction(elements, position, element);

                return {
                    type: "native",
                    elements: elements
                }
            }

        }catch(error){
            dispatch(showError("Error setting element props"));
            throw error;
        }
    }

)

export const deployWebsite = createAsyncThunk(
    "apptemplates/deploywebsite",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deployWebsiteApi(payload);
            dispatch(getWebsiteTemplates(payload))
            dispatch(showError("Website deployed"));
            return res;
        }catch(error){
            dispatch(showError("Error deploying pipelines"));
            throw error;
        }
    }
)

const updateVariableDeps = (appvariables, keytoupdate)=>{
    let tempvar = appvariables[keytoupdate];    
    if(tempvar.depsarray!=undefined&&tempvar.depsarray.length>0){
        for(let i=0; i<tempvar.depsarray.length; i++){
            let childvar = appvariables[tempvar.depsarray[i]];
            if(childvar!=undefined){
                let sc = [...childvar.schema];
                let scm = [...childvar.schemamapping];
                getValuedSchema(sc, scm, tempvar.schema);
                appvariables[tempvar.depsarray[i]] = {...appvariables[tempvar.depsarray[i]],
                                                      schema: sc,
                                                      schemamapping: scm
                                                     }
                updateVariableDeps(appvariables, tempvar.depsarray[i]);
            }
        }
    }
}

const getKeystobeUpdatedAtChange = (
                            schemamapping, 
                            schema,
                            keystobeupdated
                           )=>{
    for(let i=0; i < schemamapping.length; i++){
        if(Array.prototype.isPrototypeOf(schemamapping[i].mapping)){
            getKeystobeUpdatedAtChange(schemamapping[i].mapping, schema[i].subschema, keystobeupdated);
        }else{
            if(schemamapping[i].mapping.action=="get"){
                let val = schema[i].value;
                keystobeupdated.push({
                    "key": schemamapping[i].mapping.val,
                    "value": val
                })
            }
        }
    }
}

const updatekey = (key, val, variable, keystobeupdated)=>{
    if(key.startWith("template")){
        let keyparts = parseSchemaKey(key);
        let schema = variable.schema;
        let schemamapping = variable.schemamapping;
        for(let i=1; i< keyparts.length; i++){
            if(i==(keyparts.length-1)){
                let keyindex = _.findIndex(schema, (sc)=>{return sc.key==keyparts[i]});
                schema[keyindex] = {...schema[keyindex],
                                    value: val 
                                   }
                if(schemamapping[keyindex].action=="get"){
                    keystobeupdated.push({
                        key: keystobeupdated[keyindex].val,
                        value: val
                    })
                }
            }else{
                let keyindex = _.findIndex(schema, (sc)=> {return sc.key==keyparts[i]});
                if(keyindex>-1){
                    schema = schema[keyindex].subschema;
                    schemamapping = schemamapping[keyindex].mapping;
                }else{
                    throw new Error("unexpected key found");
                }
            }
        }
    }
}

const updatevariablekey = (appvariables, parentkey,keystobeupdated)=>{
    let parentvar = appvariables[parentkey];
    let childkeystobeupdated = [];
    for(let i=0; i< keystobeupdated.length; i++){
        let key = keystobeupdated[i];
        updatekey(key.key, key.value, childkeystobeupdated);
    }
    if(parentvar.parent!=""){
        updatevariablekey(appvariables, parentvar.parent, childkeystobeupdated);
    }
    updateVariableDeps(appvariables, parentkey);
}

// update variable and related variable in transformation pipelines
const updateVar = (appvariables, childkey)=>{
    let childvar = appvariables[childkey];
    if(childvar.parent!=""){
        let keystobeupdated = [];
        getKeystobeUpdatedAtChange(childvar.schemamapping, childvar.schema,keystobeupdated);
        updatevariablekey(appvariables, childvar.parent, keystobeupdated);
        updateVariableDeps(appvariables, childkey);
    }else{
        updateVariableDeps(appvariables, childkey);
    }
}

// update schemamapping on transfrom of the variable
const updateConstSchemamapping = (schema, schemamapping)=>{
    for(let i=0; i< schema.length; i++){
        if(schema[i].type=="object"){
            updateConstSchemamapping(schema[i].subschema, schemamapping[i].mapping);
        }else if(schema[i].type=="array"){
            if(schemamapping[i].mapping.action=="const"){
                if(schema[i].subschema[0].type!="object"){
                    let submapping = schemamapping[i].mapping.mapping;
                    for(let j=0; j< schema[i].value.length; j++){
                        submapping[j] = {...submapping[j],
                                         val: schema[i].value[j]
                                        }
                    }
                    let mapping = {...schemamapping[i].mapping};
                    mapping = {
                                ...mapping,
                                mapping: submapping
                              }
                    schemamapping[i] = {...schemamapping[i],
                                        mapping: mapping
                                        }
                }else{
                    let submapping = schemamapping[i].mapping.mapping;
                    for(let j=0; j< schema[i].value.length; j++){
                        let subschema = generateSchema(schema[i].value[j]);
                        updateConstSchemamapping(subschema, submapping[j])
                    }
                    let mapping = {...schemamapping[i].mapping};
                    mapping = {
                                ...mapping,
                                mapping: submapping
                              }
                    schemamapping[i] = {...schemamapping[i],
                                        mapping: mapping
                                        }
                }
            }
        }else{
            if(schemamapping[i].mapping.action=="const"){
                let mapping = {...schemamapping[i].mapping};
                mapping = {...mapping,
                           val: schema[i].value
                          }
                schemamapping[i] = {...schemamapping[i],
                                    mapping: mapping
                                   }
            }
        }
    }
}

const deleteElementAction = (elements, position)=>{
    let elementsCopy = [...elements];
    let secondPointer = elementsCopy;
    let delelm = [];
    for(let i=0; i< position.length; i++){
        if(i==(position.length-1)){
            delelm = secondPointer.splice(position[i],1);
        }else{
            secondPointer = secondPointer[position[i]].childs
        }
    }
    return {
        "elements": elements,
        "delelm": delelm
    };
}

const updateVariableMappingsOnPositionChanged = (
                                                    templateid, 
                                                    oldpostion, 
                                                    newpostion,
                                                    appelements,
                                                    appvariables,
                                                    appinputs,
                                                    routeparams
                                                )=>{
    let oldkey = getKey(templateid, oldpostion);
    let newkey = getKey(templateid, newpostion);
    appelements[newkey] = appelements[oldkey];
    delete appelements[oldkey];
    appvariables[newkey] = appvariables[oldkey];
    delete appvariables[oldkey];
    appinputs[newkey] = appinputs[oldkey];
    delete appinputs[oldkey];
    routeparams[newkey] = routeparams[oldkey];
    delete routeparams[oldkey];
                                               
}

const handleKeyChange = (
        draggedElement,
        oldpostion, 
        newpostion,
        appelements, 
        appvariables,
        appinputs,
        routeparams 
    )=>{
    // handle the element dragged
    if(draggedElement.type=="template"){
        updateVariableMappingsOnPositionChanged(draggedElement.templateid, oldpostion, newpostion, appelements, appvariables, appinputs, routeparams);
        if(draggedElement.childs.length>0){
            for(let i=0; i< draggedElement.childs.length; i++){
                let newoldposition = [...oldpostion,i];
                let newnewpostion = [...newpostion, i];
                handleKeyChange(
                        draggedElement.childs[i], 
                        newoldposition, 
                        newnewpostion, 
                        appelements, 
                        appvariables,
                        appinputs,
                        routeparams 
                    )
            }
        }
    }else{
        if(draggedElement.childs.length>0){
            for(let i=0; i< draggedElement.childs.length; i++){
                let newoldposition = [...oldpostion, i];
                let newnewpostion = [...newpostion, i];
                handleKeyChange(
                    draggedElement.childs[i],
                    newoldposition, 
                    newnewpostion, 
                    appelements, 
                    appvariables,
                    appinputs,
                    routeparams 
                )
            }
        }
    }

}

const handleElementDragged = (
                                elementsCopy, 
                                elementdragged,
                                appelements,
                                appvariables,
                                appinputs,
                                routeparams   
                             )=>{
    let draggedElement = null;
    let secondpointer = elementsCopy;
    for(let i=0; i< elementdragged.length; i++){
        if(i==(elementdragged.length-1)){
            draggedElement = {...secondpointer[elementdragged[i]]}
            for(let j=elementdragged[i]; j< secondpointer.length; j++){
                let oldpostion = [...elementdragged];
                oldpostion[elementdragged.length-1] = j;
                let newpostion = [...elementdragged];
                oldpostion[elementdragged.length-1] = j+1;
                handleKeyChange(
                    secondpointer[j],
                    oldpostion,
                    newpostion,
                    appelements,
                    appvariables,
                    appinputs,
                    routeparams
                )   
            }
            secondpointer.splice(elementdragged[i],1);
        }else{
            secondpointer = secondpointer[elementdragged[i]].childs;
        }
    }
    return draggedElement;
}

const handleElementDropAction = (
                                    elements,
                                    elementdragged,
                                    position,
                                    appelements,
                                    appvariables,
                                    appinputs,
                                    routeparams
                                )=>{
    let elementsCopy = [...elements];
    let draggedElement = handleElementDragged(
                                                elementsCopy, 
                                                elementdragged,
                                                appelements,
                                                appvariables,
                                                appinputs,
                                                routeparams
                                             );
    handleKeyChange(
        draggedElement,
        elementdragged,
        position,
        appelements,
        appvariables,
        appinputs,
        routeparams
    )
    let dropppointer = elementsCopy;
    if(draggedElement!=null&&draggedElement!=undefined){
        for(let i=0; i<position.length; i++){
            if(i==(position.length-1)){
                for(let j=position[i]; j< dropppointer.length; j++){
                    let oldpostion = [...position];
                    oldpostion[position.length-1] = j;
                    let newpostion = [...position];
                    oldpostion[position.length-1] = j-1;
                    handleKeyChange(
                        dropppointer[j],
                        oldpostion,
                        newpostion,
                        appelements,
                        appvariables,
                        appinputs,
                        routeparams
                    )   
                }
                dropppointer.splice(position[i],0,draggedElement);
            }else{
                dropppointer = dropppointer[position[i]].childs;
            }
        }
    }

}

const setElementAction = (elements, position, element)=>{
    let elementsCopy = [...elements];
    let secondPointer = elementsCopy;
    for(let i=0; i< position.length; i++){
        if(i==(position.length-1)){
            secondPointer[position[i]] = element;
        }else{
            secondPointer = secondPointer[position[i]].childs;
        }
    }
    return elementsCopy;
}

const addparenttovar = (variable, elmkey)=>{
    variable = {...variable,
                parent: elmkey
                }
    return variable;
}

const getElementPropsMapping = (appelements, parentkey, indexarr)=>{
    let tempelements = appelements[parentkey];
    let element;
    let secondPointer = tempelements;
    for(let i=0; i< indexarr.length; i++){
        if(i==(indexarr.length-1)){
            element = {...secondPointer[indexarr[i]]};
        }else{
            secondPointer = secondPointer[indexarr[i]].childs;
        }
    }
    return element;
}

const loadAppInputs = (elements, inputs)=>{
    for(let i=0; i< elements.length; i++){
        if(isElementInput(elements[i])){
            let outputtype = getInputOutputtype(elements[i].inputtype);
            let inputvariable;
            if(outputtype=="file"){
                let subschema = [
                    {
                        "key": "url",
                        "label": "URL",
                        "value": "",
                        "subschema":[],
                        "type": "string"
                    },
                    {
                        "key": "name",
                        "label": "Name",
                        "value": "",
                        "subschema":[],
                        "type": "string"
                    },
                    {
                        "key": "size",
                        "label": "Size",
                        "value": "",
                        "subschema":[],
                        "type": "string"
                    },
                    {
                        "key": "lastModified",
                        "label": "LastModified",
                        "value": "",
                        "subschema":[],
                        "type": "datetime"
                    }
                ]
                inputvariable = {
                    "type": outputtype,
                    "value": "",
                    "key": elements[i].name,
                    "label": elements[i].name,
                    "subschema": subschema
                }

            }else{
                inputvariable = {
                    "type": outputtype,
                    "value": "",
                    "key": elements[i].name,
                    "label": elements[i].name,
                    "subschema":[]
                }
            }
            let index = elements[i].inputindex;
            if(inputs.length<index){
                for(let j=(index-inputs.length); j<=index; j++){
                    inputs.push({
                        "type": "string",
                        "value": "",
                        "key": "",
                        "label": "",
                        "subschema":[]
                    })
                }
            }
            inputs[index] = inputvariable;
        }
        if(elements[i].childs!=undefined&&Array.prototype.isPrototypeOf(elements[i].childs)){
            loadAppInputs(elements[i].childs, inputs);
        }
    }
}

const isPropDiff = (schema, schemamapping, propschema, propschemamapping, ischanged)=>{
    for(let i=0; i< schema.length; i++){
        let keyindex = _.findIndex(propschema, (ps)=>{return ps.key==schema[i].key});
        if(keyindex>-1){
            if(schema[i].subschema.length>0){
                let schemasubschema = schema[i].subschema;
                let propsubschema = propschema[keyindex].subschema;
                let schemasubschemamapping = schemamapping[i].mapping;
                
                if(propschemamapping[keyindex].mapping==""){
                    propschemamapping[keyindex] = {...propschemamapping[keyindex],
                                                   mapping: []
                                                  };
                    let propsubschemamapping = propschemamapping[keyindex].mapping;
                    isPropDiff(schemasubschema, schemasubschemamapping, propsubschema, propsubschemamapping, ischanged);    
                }else{
                    let propsubschemamapping = propschemamapping[keyindex].mapping;
                    isPropDiff(schemasubschema, schemasubschemamapping, propsubschema, propsubschemamapping, ischanged);
                }
                
            }
        }else{
            let currentschema = {...schema[i]};
            let currentschemamapping = {...schemamapping[i]};
            propschema.splice(i,0, currentschema);
            propschemamapping.splice(i,0,currentschemamapping);
            ischanged.value = true;
        }
    }


    // remove the extra keys from the propsschema
    for(let i=0; i< propschema.length; i++){
        let keyindex = _.findIndex(schema, (sc)=>{return sc.key==propschema[i].key});
        if(keyindex==-1){
            propschema.splice(i,1);
            propschemamapping.splice(i,1);
            ischanged.value = true;
        }
    }
}

const setChildEnabledAction = (elements,position, val)=>{
    let elementsCopy = [...elements];
    let secondPointer = elementsCopy;
    for(let i=0; i< position.length; i++){
        if(i==(position.length-1)){
            secondPointer[position[i]] = {...secondPointer[position[i]],
                                          childenabled: val
                                         }
        }else{
            secondPointer = secondPointer[position[i]].childs;
        }
    }
    return elementsCopy;
}

export const setChildEnabled = createAsyncThunk(
    "apptemplates/setchildenabled",
    async(payload, {dispatch, rejectWithValue, getState})=>{
            let state = getState().apptemplates;
            let elements = JSON.parse(JSON.stringify(state.elements));
            elements = setChildEnabledAction(elements, payload.position, payload.state);
            dispatch(updateTemplateChildEnabled({val: payload.state, templateid: state.activetemplateid}))
            return {
                "elements": elements,
                "val": payload.state
            }

    }
)

export const getExportedTemplatePipelines = createAsyncThunk(
    "apptemplates/getexportedtemplatepipelines",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await interactionpipelinesApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting exported templates"));
            throw error;
        }
    }
) 

const apptemplateSlice = createSlice({
    name: "apptemplates",
    initialState:{
        "templates": [],
        "templatevariables":{},
        "templateclasses":[],
        "pipelineSubs":{
            "key": []
        },
        "templateinteractions":[],
        "routeparams":{},
        "templatepipelines":{},
        "activetemplatepipelines": [],
        "activevariableid": "",
        "appelements":{},
        "appvariables":{},
        "appclasses":{},
        "appvariablesdependency": {},
        "appinputs":{},
        "elements":[],
        "activeposition":[],
        "activekey": "",
        "focusposition": [],
        "focuskey": "",
        "elementDragged":[],
        "activetemplatesettings": undefined,
        "elementInFocus":[],
        "elementActive":[],
        "inputs": [],
        "activetemplateid": "",
        "apptemplates": [],
        "prod": true,
        "templateloadstatus": {
            "": true
        },
        "currentpath": "",
        "mode": "",
        "childenabled": false,
        "pipelines":[],
        "focussource": "bar"
    },
    reducers:{
        setActivePosition: (state, action)=>{
            state.activeposition = action.payload.position;
            state.activekey = action.payload.key
        },
        setFocusPosition: (state, action)=>{
            state.focusposition = action.payload.position;
            state.focuskey = action.payload.key;
            state.focussource = action.payload.focussource;
        },
        setFocusKey: (state, action)=>{
            state.focuskey = action.payload;
        },
        setElementDragged: (state, action)=>{
            state.elementDragged = action.payload;
        },
        setactivetemplateid: (state, action)=>{
            state.activetemplateid = action.payload;
        },
        resetactivetemplateid: (state, action)=>{
            state.activetemplateid = "";
            state.activevariableid = "";
        },
        resetelements: (state, action)=>{
            state.elements = [];
            state.appelements = {};
        },
        resetvariables: (state, action)=>{
            state.appvariables = {};
            state.templatevariables = {};
        },
        resetactivetemplatepipelines: (state, action)=>{
            state.activetemplatepipelines = [];
            state.templatepipelines = [];
        },
        handleElementDrop: (state, action)=>{
            let elements = JSON.parse(JSON.stringify(state.elements));
            let elementDragged = JSON.parse(JSON.stringify(state.elementDragged));
            let appvariables = JSON.parse(JSON.stringify(state.appvariables));
            let appelements = JSON.parse(JSON.stringify(state.appelements));
            let appinputs = JSON.parse(JSON.stringify(state.appelements));
            let routeparams = JSON.parse(JSON.stringify(state.routeparams));
            handleElementDropAction(
                                                elements, 
                                                elementDragged,
                                                action.payload,
                                                appvariables,
                                                appelements,
                                                appinputs,
                                                routeparams
                                                );
            let appelementsCopyCopy = {...appelements};
            appelementsCopyCopy[state.activetemplateid] = elements;
            state.elements = elements;
            state.appelements = appelementsCopyCopy;
            state.appinputs = appinputs;
            state.routeparams = routeparams;
            state.appvariables = appvariables;
            state.elementDragged = [];
        },
        deleteElement: (state, action)=>{
            let elements = [...state.elements];
            let res = deleteElementAction(elements, action.payload);
            elements = res.elements;
            let delelm = res.delelm[0];
            
            if(delelm.elemtype=="template"){
                let depkey = getKey(delelm._id, action.payload);
                let templatevariables = {...state.appvariables[state.activetemplateid]};
                templatevariables = removedepsfromvar(templatevariables, depkey);
                let appvariablesCopy = {...state.appvariables};
                delete appvariablesCopy[depkey];
                appvariablesCopy[state.activetemplateid] = {...appvariablesCopy[state.activetemplateid],
                                                            templatevariables
                                                           }
                state.appvariables = appvariablesCopy;
                state.elements = elements;
                let appelementsCopy = {...state.appelements};
                appelementsCopy[state.activetemplateid] = elements;
                state.appelements = appelementsCopy;
            }else if(delelm.elemtype=="native"){
                if(isElementInput(delelm)){
                    // remove the desired inputs
                    let inputindex = delelm.inputindex;
                    let inputs = [...state.inputs];
                    inputs.splice(inputindex,1);
                    let appinputs = {...state.appinputs};
                    appinputs[state.activetemplateid] = inputs;
                    state.inputs = inputs;
                    state.appinputs = appinputs;
                }
                state.elements = elements;
                let appelementsCopy = {...state.appelements};
                appelementsCopy[state.activetemplateid] = elements;
                state.appelements = appelementsCopy;    
            }            
        },
        setElement: (state, action)=>{
            let elements = [...state.elements];
            elements = setElementAction(elements, action.payload.position, action.payload.element);
            state.elements = elements;
            let appelementsCopy = {...state.appelements};
            appelementsCopy[state.activetemplateid] = elements;
            state.appelements = appelementsCopy;
        },
        setTemplateVariable: (state, action)=>{
            let templatevariableCopy = {...state.templatevariables};
            templatevariableCopy[action.payload.key] = action.payload.value;
            state.templatevariables = templatevariableCopy;
        },
        setElements: (state, action)=>{
            state.elements = action.payload;
            let appelementsCopy = {...state.appelements};
            appelementsCopy[state.activetemplateid] = action.payload;
            state.appelements = appelementsCopy;

        },
        setTemplateId: (state, action)=>{
            state.activetemplateid = action.payload;
        },
        updateElementProps: (state, action)=>{
            let props = JSON.parse(JSON.stringify(action.payload.props));
            let position = JSON.parse(JSON.stringify(action.payload.position));
            let element = JSON.parse(JSON.stringify(action.payload.element));
            if(element.elemtype=="template"){
                let sm = props.props;
                let schemamapping = props.propsmapping;
                let templatevariables = state.appvariables[state.activetemplateid];
                // get valued schema
                getValuedSchema(sm, schemamapping, templatevariables.schema);
                let templatekey = getKey(element._id, position);
                let appvariablesCopy = JSON.parse(JSON.stringify(state.appvariables));
                appvariablesCopy[templatekey] = {...appvariablesCopy[templatekey],
                                                 schema: sm,
                                                 schemamapping: schemamapping
                                                }
                
                
                element = {...element,
                           props: sm,
                           propsmapping: schemamapping
                          }
                let elements = JSON.parse(JSON.stringify(state.elements));
                elements = setElementAction(elements, position, element);


                templatevariables = {...state.appvariables[state.activetemplateid]};
                
                //add child to deps of parent
                templatevariables = adddepstovar(templatevariables, templatekey);
                
                appvariablesCopy[state.activetemplateid] = templatevariables;
                // let activevariablescopy =  {...state.templatevariables};
                // activevariablescopy[state.activetemplateid] = templatevariables;
                state.appvariables = appvariablesCopy;

                state.elements = elements;
                let appelementsCopy = {...state.appelements};
                appelementsCopy[state.activetemplateid] = elements;
                state.appelements = appelementsCopy;
                
            }
            if(element.elemtype=="native"){
                element = {...element,
                           props: props.props,
                           propsmapping: props.propsmapping
                          }
                let elements = [...state.elements];
                elements = setElementAction(elements, position, element);
                state.elements = elements;
                let appelementsCopy = {...state.appelements};
                appelementsCopy[state.activetemplateid] = elements;
                state.appelements = appelementsCopy;

            }
        },
        transformvar: (state, action)=>{
            let updatedSchema = [...action.payload.schema];
            for(let i=0; i<updatedSchema.length; i++){
                if(updatedSchema[i]?.key=="variable"){
                    let varschema = updatedSchema[i];
                    let appvariables = JSON.parse(JSON.stringify(state.appvariables));
                    let appvariablescopy = JSON.parse(JSON.stringify(state.appvariables));
                    let childschemamapping = [...appvariables[action.payload.childkey].schemamapping];
                    updateConstSchemamapping([varschema], childschemamapping);
                    appvariables[action.payload.childkey] = {
                                                                ...appvariables[action.payload.childkey],
                                                                schema: [varschema],
                                                                schemamapping: childschemamapping
                                                            }
                    appvariablescopy[action.payload.childkey] = {
                                                                    ...appvariablescopy[action.payload.childkey],
                                                                    schema: [varschema],
                                                                    schemamapping: childschemamapping
                                                                }
                    updateVar(appvariablescopy, action.payload.childkey)
                    state.appvariables = appvariablescopy;
                }else if(updatedSchema[i]?.key=="inputs"){
                    let varschema = updatedSchema[i];
                    let appinputs = JSON.parse(JSON.stringify(state.appinputs));
                    appinputs[action.payload.childkey] = [...varschema.subschema]
                    state.appinputs = appinputs;
                    state.inputs = [...varschema.subschema];
                }
            }
        },
        setInputType: (state, action)=>{
            let element = action.payload.element;
            let position = action.payload.position;
            let act = action.payload.act;
            let inputs = [...state.inputs];
            let elementCopy = {...element};
            if(act=="change_type"){
                let outputtype = getInputOutputtype(element.inputtype);
                if(outputtype!=null){
                    inputs[element.inputindex] = {...inputs[element.inputindex],
                                                type: outputtype,
                                                val: ""
                                                }
                    
                }else{
                    inputs[element.inputindex] = {...inputs[element.inputindex],
                                                type: outputtype,
                                                val: null
                                                }
                }
                let props = getInputProps(element.inputtype);
                elementCopy = {...elementCopy,
                                    props: props
                                }
            }else if(act=="change_name"){
                inputs[elementCopy.inputindex] = {...inputs[element.inputindex],
                                                  key: element.name,
                                                  label: element.label
                                                 }
            }
            
            let elements = [...state.elements];
            elements = setElementAction(elements, position, elementCopy);
            state.elements = elements;
            let appelementsCopy = {...state.appelements};
            appelementsCopy[state.activetemplateid] = elements;
            state.appelements = appelementsCopy;
            state.inputs = inputs;
            let appinputs = {...state.appinputs};
            appinputs[state.activetemplateid] = inputs;
            state.appinputs = appinputs;
        },
        onChangeHandler: (state, action)=>{
            let val = action.payload.val;
            let index = action.payload.index;
            let key = action.payload.key;
            let appinputs = {...state.appinputs};
            let inputs = appinputs[key];
            if(inputs[index]?.type=="file"){
                let fileurl = URL.createObjectURL(action.payload.file)
                let output = {
                    "url": fileurl,
                    "name": val,
                    "size": action.payload.file.size,
                    "lastModified": action.payload.file.lastModified
                }
                let subschema = [
                    {
                        "key": "url",
                        "label": "URL",
                        "value": fileurl,
                        "subschemainputs":[],
                        "type": "string"
                    },
                    {
                        "key": "name",
                        "label": "Name",
                        "value": val,
                        "subschema":[],
                        "type": "string"
                    },
                    {
                        "key": "size",
                        "label": "Size",
                        "value": action.payload.file.size,
                        "subschema":[],
                        "type": "string"
                    },
                    {
                        "key": "lastModified",
                        "label": "LastModified",
                        "value": action.payload.file.lastModified,
                        "subschema":[],
                        "type": "string"
                    }
                ]
                inputs[index] = {...inputs[index],
                                 value: val,
                                 file: fileurl,
                                 subschema: subschema
                                }
            }else{
                inputs[index] = {...inputs[index],
                    value: val
                   }
            }
            appinputs[key] = inputs
            state.appinputs = appinputs;
            state.inputs = inputs
        },
        setProd: (state, action)=>{
            state.prod = action.payload;
        },
        setCurrentPath: (state, action)=>{
            state.currentpath = action.payload;
        },
        setmode: (state, action)=>{
            state.mode = action.payload;
        },
        resetmode: (state, action)=>{
            state.mode = "";
        },
        resetapptemplates: (state, action)=>{
            state.apptemplates = []
        },
        mergeStyles: (state, action)=>{
        
            
        }

    },
    extraReducers: (builder)=>{
        builder.addCase(addElement.fulfilled, (state, action)=>{
            let result = action.payload;
            state.elements = result.elements;
            let appelementsCopy = {...state.appelements};
            appelementsCopy[state.activetemplateid] = result.elements;
            let appinputs = {...state.appinputs};
            appinputs[state.activetemplateid] = result.inputs;
            state.appinputs = appinputs;
            state.inputs = result.inputs;
            state.appelements = appelementsCopy;
        })
        builder.addCase(templatePipleine.fulfilled,(state, action)=>{
            state.activetemplatepipelines  = action.payload;
        })
        builder.addCase(getAppPipelines.fulfilled, (state, action)=>{
            let resp = action.payload;
            if(resp!=undefined){
                let appPipelinesCopy = {...state.templatepipelines};
                appPipelinesCopy[resp.key] = resp.pipelines;
                state.templatepipelines = appPipelinesCopy;
            }
            
        })
        builder.addCase(getTemplateVariables.fulfilled,(state, action)=>{
          let templatevariable = action.payload;
          let templatevariablesCopy = {...state.templatevariables};
          if(templatevariable.variable!=null&&templatevariable.variable!=undefined){
            let schema = [...templatevariable.variable.schema];
            let schemamapping = [...templatevariable.variable.schemamapping];
            getValuedSchema(schema, schemamapping);
            templatevariablesCopy[templatevariable.variable.templateid] = {
                                                                            schema: schema,
                                                                            schemamapping: schemamapping
                                                                          };
            state.templatevariables = templatevariablesCopy;
          }   
        })
        builder.addCase(getAppVariables.fulfilled, (state, action)=>{
            let templatevariable = action.payload.variable;
            let parentkey = action.payload.elmkey;
            let appvariablesCopy = {...state.appvariables};
            if(templatevariable.variable!=null&&templatevariable.variable!=undefined){
                let schema = [...templatevariable.variable.schema];
                let schemamapping = [...templatevariable.variable.schemamapping];
                let element;
                if(parentkey!=""){
                    element = getElementPropsMapping(state.appelements, parentkey, action.payload.indexarr);
                    let isChanged = {
                        "value":false
                    };
                    let props = JSON.parse(JSON.stringify(element.props));
                    let propsmapping = JSON.parse(JSON.stringify(element.propsmapping));
                    isPropDiff(schema, schemamapping, props, propsmapping, isChanged);
                    if(isChanged.value){
                        if(state.activetemplateid==parentkey){
                            let elementCopy = {...element,
                                               props: props,
                                               propsmapping: propsmapping
                                              };
                            let elements = setElementAction(state.appelements[parentkey], action.payload.indexarr, elementCopy);
                            state.elements = elements;
                            let appelementsCopy = {...state.appelements};
                            appelementsCopy[state.activetemplateid] = elements;
                            state.appelements = appelementsCopy;
                        }else{
                            let elementCopy = {...element,
                                                props: props,
                                                propsmapping: propsmapping
                                              }
                            let elements = setElementAction(state.appelements[parentkey], action.payload.indexarr, elementCopy);
                            let appelementsCopy = {...state.appelements};
                            appelementsCopy[parentkey] = elements;
                            state.appelements = appelementsCopy;
                        }
                    }
                    schemamapping = propsmapping;
                }
                let depsarray = [];
                if(templatevariable.variable.depsarray!=undefined){
                    depsarray = [...templatevariable.variable.depsarray];
                }
                let parentvariable = {...state.appvariables[parentkey]};
                getValuedSchema(schema, schemamapping, parentvariable?.schema);
                
                // update parent deps array
                if(parentkey!=""){
                    parentvariable = adddepstovar(parentvariable, action.payload.key);
                    appvariablesCopy[parentkey] = parentvariable;
                }

                // get the parent variable
                appvariablesCopy[action.payload.key] = {
                                                         schema: schema,
                                                         schemamapping: schemamapping,
                                                         parent: parentkey,
                                                         depsarray: depsarray
                                                       }
                // update the dependencies
                updateVariableDeps(appvariablesCopy, action.payload.key);
                state.appvariables = appvariablesCopy;
                let templateloadstatus = {...state.templateloadstatus};
                templateloadstatus[action.payload.key] = true;
                state.templateloadstatus = templateloadstatus;
            }
        })
        builder.addCase(updateTemplateVariables.fulfilled, (state, action)=>{
            let templatevariable = action.payload;
            let templatevariablesCopy = {...state.templatevariables};
            let schema = [...templatevariable.variable.schema];
            let schemamapping = [...templatevariable.variable.schemamapping];
            //get the child dependencies
            getValuedSchema(schema,schemamapping);
            templatevariablesCopy[templatevariable.templateid] = {
                                                                    schema: schema,
                                                                    schemamapping: schemamapping,
                                                                 }; 
            let appvariablesCopy = {...state.appvariables};
            appvariablesCopy[templatevariable.templateid] = {...appvariablesCopy[templatevariable.templateid],
                                                                schema: schema,
                                                                schemamapping: schemamapping,
                                                            }
            updateVariableDeps(appvariablesCopy, templatevariable.templateid);
            state.templatevariables = templatevariablesCopy;
            state.appvariables = appvariablesCopy;
        })
        builder.addCase(getTemplateClasses.fulfilled, (state, action)=>{
            if(action.payload!=null){
                let cssclasses = action.payload;
                state.templateclasses = cssclasses.classes;
            }
        })
        builder.addCase(getAppclasses.fulfilled, (state, action)=>{
            if(action.payload!=null){
                let appclassesCopy = {...state.appclasses};
                appclassesCopy[action.payload.key] = [...action.payload.classes] 
                state.appclasses = appclassesCopy;
            }
            

        })
        builder.addCase(updateTemplateClasses.fulfilled, (state, action)=>{
            let classes = action.payload.classes;
            state.templateclasses = classes;
            let appclasses = {...state.appclasses};
            appclasses[action.payload.templateid] = classes
            state.appclasses = appclasses;
        })
        builder.addCase(updateElementProps.fulfilled, (state, action)=>{
            if(action.payload.type=="native"){
                let elements = [...action.payload.elements];
                state.elements = elements;
                let appelementsCopy = {...state.appelements};
                appelementsCopy[state.activetemplateid] = elements;
                state.appelements = appelementsCopy;
            }else if(action.payload.type=="template"){
                let appvariables = {...action.payload.appvariables};
                let elements = [...action.payload.elements]
                state.appvariables = appvariables;
                state.elements = elements;
                let appelementsCopy = {...state.appelements};
                appelementsCopy[state.activetemplateid] = elements;
                state.appelements = appelementsCopy;
            }
        })
        builder.addCase(getRouteParams.fulfilled, (state, action)=>{
            let routeparams = action.payload;
            let routeparamsCopy = {...state.routeparams};
            if(routeparams.routeparams!=null&&routeparams.routeparams!=undefined){
                let schema = [...routeparams.routeparams.schema];
                let schemamapping = [...routeparams.routeparams.schemamapping];
                getValuedSchema(schema,schemamapping);
                routeparamsCopy[routeparams.routeparams.templateid] = {
                                                            schema: schema,
                                                            schemamapping: schemamapping
                                                           } 
                state.routeparams = routeparamsCopy;
            }
        })
        builder.addCase(updateRouteParams.fulfilled, (state, action)=>{
            let routeparams = action.payload;
            let routeparamsCopy = {...state.routeparams};
            let schema = [...routeparams.routeparams.schema];
            let schemamapping = [...routeparams.routeparams.schemamapping];
            getValuedSchema(schema,schemamapping);
            routeparamsCopy[routeparams.templateid] = {
                                                        schema: schema,
                                                        schemamapping: schemamapping
                                                      }
            state.routeparams = routeparamsCopy;
        })
        builder.addCase(updateTemplate.fulfilled, (state, action)=>{

        })
        builder.addCase(getElements.fulfilled, (state, action)=>{
            state.elements = action.payload.elements;
        })
        builder.addCase(getTemplateSettings.fulfilled, (state, action)=>{
            state.activetemplatesettings = action.payload;
        })
        builder.addCase(updateTemplateSettings.fulfilled, (state, action)=>{
            
        })
        builder.addCase(getAppElements.fulfilled, (state, action)=>{
            if(action.payload.source=="pipelines"){
                let appelementsCopy = state.appelements;
                if(appelementsCopy[action.payload.key]==undefined||appelementsCopy[action.payload.key]?.length==0){
                
                    let appelementsCopy = state.appelements;
                    appelementsCopy[action.payload.key] = action.payload.elements;
                    let inputs = [];
                    // load app inputs
                    loadAppInputs(action.payload.elements, inputs);
                    let appinputs = state.appinputs;
                    appinputs[action.payload.key] = inputs
                    state.appinputs = appinputs;
                    state.appelements = appelementsCopy;
                    
                }

            }else{
                let appelementsCopy = state.appelements;
                appelementsCopy[action.payload.key] = action.payload.elements;
                let inputs = [];
                // load app inputs
                loadAppInputs(action.payload.elements, inputs);
                let appinputs = state.appinputs;
                appinputs[action.payload.key] = inputs
                state.appinputs = appinputs;
                state.appelements = appelementsCopy;    
            }
            

        })
        builder.addCase(getAppTemplates.fulfilled, (state, action)=>{
            let templatelist = action.payload;
            let activetemplateindex = _.findIndex(templatelist,(t)=>{return t._id==state.activetemplateid});
            if(activetemplateindex>-1){
                templatelist.splice(activetemplateindex,1)
            }
            state.apptemplates = templatelist;
        })
        builder.addCase(deployWebsite.fulfilled, (state, action)=>{

        })
        builder.addCase(getTemplateChildEnabled.fulfilled, (state, action)=>{
            if(action.payload.childenabled==true&&state.childenabled==false){
                state.childenabled = true;
            }else if(action.payload.childenabled==false&&state.childenabled==true){
                state.childenabled = false;
            }else if(action.payload.childenabled==undefined&&state.childenabled==true){
                state.childenabled = false;
            }
        })
        builder.addCase(setChildEnabled.fulfilled, (state, action)=>{
            let elements = [...action.payload.elements];
            state.elements = elements;
            let appelementsCopy = {...state.appelements};
            appelementsCopy[state.activetemplateid] = elements;
            state.appelements = appelementsCopy;
            state.childenabled = action.payload.state;
        })
        builder.addCase(getExportedTemplatePipelines.fulfilled, (state, action)=>{
            state.templateinteractions = action.payload;
        })
    }
})


export const selectTemplateInteractions = (state) => state.apptemplates.templateinteractions;

export const selectActivePosition = (state) => state.apptemplates.activeposition;

export const selectActiveKey = (state) => state.apptemplates.activekey;

export const selectFocusPosition = (state) => state.apptemplates.focusposition;

export const selectFocusKey = (state) => state.apptemplates.focuskey;

export const selectActiveTemplatePipelines = (state) => state.apptemplates.activetemplatepipelines;

export const selectActiveElements = (state) => state.apptemplates.elements;

export const selectActiveTemplateSettings = (state) => state.apptemplates.activetemplatesettings;

export const selectAppTemplates = (state) => state.apptemplates.apptemplates;

export const selectActivetemplateid = (state) => state.apptemplates.activetemplateid;

export const selectTemplateClasses = (state) => state.apptemplates.templateclasses;

export const selectAppClasses = (state) => state.apptemplates.appclasses;

export const selectChildEnabled = (state) => state.apptemplates.childenabled;

export const selectTemplateLoadStatus = createSelector(
    [state=> state.apptemplates.templateloadstatus, (state, templatekey)=> templatekey],
    (templateloadstatus, templatekey)=>{
        return templateloadstatus[templatekey];
    }
)

export const jsoncompare = (val1,val2)=>{
    if(JSON.stringify(val1)==JSON.stringify(val2)){
        return true
    }else{
        return false;
    }
}

export const selectAppElements = createSelector(
    [state => state.apptemplates.appelements, (state, key)=> key],
    (appelements, key)=>{
        let elements = appelements[key];
        if(elements==undefined){
            return []
        }else{
            return elements;
        }
    }
)

export const selectTemplateVariable = createSelector(
    [state=> state.apptemplates.templatevariables, (state, templateid)=> templateid],
    (templatevariables, templateid)=>{
        let variable;
        variable  = templatevariables[templateid];
        return variable;
    }
)

export const selectRouteParams = createSelector(
    [state=> state.apptemplates.routeparams, (state, templateid)=> templateid],
    (routeparams, templateid)=>{
        return routeparams[templateid];
    }
)

export const selectConditions = createSelector(
    [
        (state)=>{
            return state.apptemplates;
        },
        (state, conditions)=>{
            return conditions;
        },
        (state, variable,key)=>{
            return key
        },
        (state, variable, key, position)=>{
            return position;
        }
    ],
    (apptemplates, conditions, key, position)=>{
        let res = false;
        let appvariables = apptemplates.appvariables;
        let templatevariables = appvariables[key]?.schema;
        let elements = apptemplates.appelements[key];
        let loopvariables = generateLoopVaraibleP(elements, templatevariables, position)
        let appinputs = apptemplates.appinputs;
        let inputs = appinputs[key];
        for(let i=0; i< conditions.length; i++){
            let blockproceed = true;
            for(let j=0; j<conditions[i].length; j++){
                let valuetocomparemapping = {...conditions[i][j].valuetocompare};
                let valuetocompare;
                if(valuetocomparemapping.action=="const"){
                    valuetocompare = valuetocomparemapping.val;
                }
                if(valuetocomparemapping.action=="get"){
                    valuetocompare = getValfromPipe(
                                                        valuetocomparemapping.val,
                                                        templatevariables,
                                                        loopvariables,
                                                        inputs
                                                    );
                }
                let valuecomparetomapping = conditions[i][j].valuecompareto;
                let valuecompareto;
                if(valuecomparetomapping.action=="const"){
                    valuecompareto = valuecomparetomapping.val;
                }
                if(valuecomparetomapping.action=="get"){
                    valuecompareto = getValfromPipe(
                                                        valuecomparetomapping.val, 
                                                        templatevariables,
                                                        loopvariables,
                                                        inputs
                                                    );
                }
                if(typeof valuetocompare=="boolean"){
                    if(valuecompareto=="true"){
                        valuecompareto = true
                    }else if(valuecompareto=="false"){
                        valuecompareto = false
                    }else{
                        valuecompareto = undefined;
                    }
                }
                let resp = executeElementConditions(conditions[i][j].condition, valuetocompare, valuecompareto);
                blockproceed = blockproceed&&resp;

            }
            res = res||blockproceed;
        }
        return res;
    }
)

export const selectElementStyles = createSelector(
    [
        (state) => state.apptemplates,
        (apptemplates, styles) => styles,
        (apptemplates, styles, key) => key,
        (apptemplates, styles, key, position) => position 
    ],
    (apptemplates, styles, key, position)=>{
        if(styles==undefined||key==undefined||position==undefined){
            return {}
        }else{
            let computedStyles = {};
            let appvariables = apptemplates.appvariables;
            let templatevariables = appvariables[key]?.schema;
            for(let i=0; i<styles.length; i++){
                if(styles[i].blocktype=="block"){
                    let stylename = camelCaseHypens(styles[i].name);
                    let stylevalue = generateStyleValue(styles[i]);
                    if(stylevalue!=""&&stylevalue!=undefined&&stylevalue!=null){
                        computedStyles[stylename] = stylevalue;
                    }
                }else if(styles[i].blocktype=="conditional"){
                    let conditions = styles[i].conditions;
                    let res = false;
                    for(let j=0; j<conditions.length; j++){
                        let blockproceed = true;
                        for(let k=0; k<conditions[j].length; k++){
                            let valuetocomparemapping = {...conditions[j][k].valuetocompare};
                            let valuetocompare;
                            if(valuetocomparemapping.action=="const"){
                                valuetocompare = valuetocomparemapping.val;
                            }
                            if(valuetocomparemapping.action=="get"){
                                valuetocompare = getValfromPipe(valuetocomparemapping.val,templatevariables);
                            }
                            let valuecomparetomapping = conditions[j][k].valuecompareto;
                            let valuecompareto;
                            if(valuecomparetomapping.action=="const"){
                                valuecompareto = valuecomparetomapping.val;
                            }
                            if(valuecomparetomapping.action=="get"){
                                valuecompareto = getValfromPipe(valuecomparetomapping.val, templatevariables);
                            }
                            let resp = executeElementConditions(conditions[j][k].condition, valuetocompare, valuecompareto);
                            blockproceed = blockproceed&&resp;
            
                        }
                        res = res||blockproceed;
                    }
                    if(res){
                        let stylename = camelCaseHypens(styles[i].name);
                        let stylevalue = generateStyleValue(styles[i]);
                        if(stylevalue!=""&&stylevalue!=undefined&&stylevalue!=null){
                            computedStyles[stylename] = stylevalue;
                        }
                    }
                }
            }
            return computedStyles;
        }
    }
)

export const selectElementClasses = createSelector(
    [
        (state) => state.apptemplates,
        (apptemplates, scusers)=> scusers,
        (apptemplates, scusers, scsystems)=> scsystems,
        (apptemplates, scusers, scsystems, key) => key,
        (apptemplates, scusers, scsystems, key, position) => position
    ],
    (apptemplates, scusers, scsystems, key, position)=>{
            if(scusers==undefined||scsystems==undefined||key==undefined||position==undefined){
                return ""
            }else{
                let clsString = "";
                let appvariables = apptemplates.appvariables;
                let templatevariables = appvariables[key]?.schema
                let clstoapply = [...scusers, ...scsystems];
                for(let i=0; i<clstoapply.length; i++){
                    let clsname = clstoapply[i].name;
                    if(clstoapply[i].blocktype=="conditional"){
                        let conditions = clstoapply[i].conditions;
                        let res = false;
                        for(let j=0; j<conditions.length; j++){
                            let blockproceed = true;
                            for(let k=0; k<conditions[j].length; k++){
                                let valuetocomparemapping = {...conditions[j][k].valuetocompare};
                                let valuetocompare;
                                if(valuetocomparemapping.action=="const"){
                                    valuetocompare = valuetocomparemapping.val;
                                }
                                if(valuetocomparemapping.action=="get"){
                                    valuetocompare = getValfromPipe(valuetocomparemapping.val,templatevariables);
                                }
                                let valuecomparetomapping = conditions[j][k].valuecompareto;
                                let valuecompareto;
                                if(valuecomparetomapping.action=="const"){
                                    valuecompareto = valuecomparetomapping.val;
                                }
                                if(valuecomparetomapping.action=="get"){
                                    valuecompareto = getValfromPipe(valuecomparetomapping.val, templatevariables);
                                }
                                let resp = executeElementConditions(conditions[j][k].condition, valuetocompare, valuecompareto);
                                blockproceed = blockproceed&&resp;
                
                            }
                            res = res||blockproceed;
                        }
                        if(res){
                            if(clsString==""){
                                clsString = clsString+clsname;
                            }else{
                                clsString = clsString+" "+clsname;
                            }
                            
                        }
                    }else{
                        if(clsString==""){
                            clsString = clsString+clsname;
                        }else{
                            clsString = clsString+" "+clsname;
                        }
                    }
                }
                return clsString;
            }
    }
)

export const selectProps = createSelector(
    [
        (state) => state.apptemplates,
        (apptemplates, variable) => variable,
        (apptemplates, variable, key)=> key,
        (apptemplates, variable, key, position) => position
    ],
    (apptemplates, variable, key, position)=>{
        let objectval = {}
        let sc = variable.schema;
        let sm = variable.schemamapping;
        let appvariables = apptemplates.appvariables;
        let templatevariables = appvariables[key]?.schema;
        let elements = apptemplates.appelements[key];
        let loopvariables = generateLoopVaraibleP(elements, templatevariables, position)
        let appinputs = apptemplates.appinputs;
        let inputs = appinputs[key];
        objectval = getAppObjectVal(
                                    sc, 
                                    sm, 
                                    templatevariables, 
                                    loopvariables, 
                                    inputs, 
                                    [],
                                    objectval,
                                    apptemplates.prod,
                                    apptemplates.currentpath
                                    );
        return objectval;

    }
)

export const selectInputVariables = (templateid)=>{
    return (state)=>{
        let apptemplates = state.apptemplates;
        let templateinputs = JSON.parse(JSON.stringify(apptemplates.appinputs[templateid]));
        let inputvariable = [{
            "key": "inputs",
            "label": "Inputs",
            "type": "object",
            "subschema": [...templateinputs]
        }];
        return inputvariable;

    }
}

export const selectInputVal = (state, key, index)=> state.apptemplates.appinputs[key]?.at(index)?.value;

const insertLoopVariable = (loopvariables, currentloopvariable)=>{
    if(loopvariables.length==0){
        loopvariables = currentloopvariable
    }else{
        if(loopvariables[1].subschema.length==0){
            loopvariables[1] = {...loopvariables[1],
                                subschema: currentloopvariable
                                }
        }else{
            insertLoopVariable(loopvariables[1].subschema, currentloopvariable)
        }
    }
}

const generateLoopVaraible = (
                                loopvariable, 
                                loopvariablemapping, 
                                templatevariables,
                                env,
                                currentpath
                                )=>{
    let loopvar = getAppObjectVal(
                                    loopvariable, 
                                    loopvariablemapping, 
                                    templatevariables?.schema,
                                    [], 
                                    [],
                                    [], 
                                    {},
                                    env,
                                    currentpath
                                    );
    if(loopvar.loopvariable.length>0){
        let firstelement = loopvar.loopvariable[0];
        let loopvarschema = [];
        let currentindex = {
            "currentindex": firstelement
        }
        generateSchema(currentindex, loopvarschema);
        return loopvarschema;
    }else{
        return [];
    }

}

// todo: check the algo
const getConcernedElementIndex = (prefix_done, current_position_prefix)=>{
    let prefixparts = prefix_done.split("__");
    let currentparts = current_position_prefix.split("__");
    if(prefixparts.length>currentparts.length){
        throw new Error("Unsupported index found");
    }
    return currentparts[(currentparts.length-1)];
}

export const generateLoopVaraibleP = (elements, templatevariables, position, env, currentpath)=>{
    let loopvariables = [];
    let prefix_done = "";
    let secondPointer = elements;
    if(position!=undefined&&elements!=undefined&&templatevariables!=undefined){
        for(let i=0; i<position.length; i++){
            if(secondPointer[position[i]].type=="loop"){
                if(position.length>(i+1)&&position[i+1].toString().startsWith("ci__")){
                    let loopvariable = secondPointer[position[i]].loopvariable;
                    let loopvariablemapping = secondPointer[position[i]].loopvariablemapping;
                    let loopvar = getAppObjectVal(
                                                    loopvariable, 
                                                    loopvariablemapping, 
                                                    templatevariables, 
                                                    loopvariables, 
                                                    [], 
                                                    [],
                                                    {},
                                                    env,
                                                    currentpath
                                                    );
                    let currentindex = getConcernedElementIndex(prefix_done, position[i+1]);
                    let concerned_element = loopvar.loopvariable[currentindex];
                    let loopvarschema = [];
                    let current_index = {
                        "currentindex": concerned_element
                    }
                    generateSchema(current_index, loopvarschema);
                    let currentloopvariable = [
                        loopvarschema[0],
                        {
                            "key": "loopvariable",
                            "label": "Loop Variable",
                            "type": "object",
                            "subschema":[]
                        }
                    ]
                    if(loopvariables.length==0){
                        loopvariables = [...currentloopvariable];
                    }else{
                        insertLoopVariable(loopvariables, currentloopvariable);
                    }
                    i = i+1;
                }else{
                    let loopvariable = secondPointer[position[i]].loopvariable;
                    let loopvariablemapping = secondPointer[position[i]].loopvariablemapping;
                    let loopvar = getAppObjectVal(
                                                    loopvariable, 
                                                    loopvariablemapping, 
                                                    templatevariables, 
                                                    loopvariables, 
                                                    [],
                                                    [], 
                                                    {},
                                                    env,
                                                    currentpath
                                                    );
                    let currentindex = 0;
                    let concerned_element = loopvar.loopvariable[currentindex];
                    let loopvarschema = [];
                    let current_index = {
                        "currentindex": concerned_element
                    }
                    generateSchema(current_index, loopvarschema);
                    let currentloopvariable = [
                        loopvarschema[0],
                        {
                            "key": "loopvariable",
                            "label": "Loop Variable",
                            "type": "object",
                            "subschema":[]
                        }
                    ]
                    if(loopvariables.length==0){
                        loopvariables = [...currentloopvariable];
                    }else{
                        insertLoopVariable(loopvariables, currentloopvariable);
                    }
                        
                    
                }
            }
            // to handle loop elements
            if(i<position.length&&position[i].toString().startsWith("ci__")==false){
                secondPointer = secondPointer[position[i]].childs;
            }
            
        }
    }
    return loopvariables;
}

export const selectLoopVariables = createSelector(
    [
        (state)=> state.apptemplates, 
        (state,templateid)=> templateid,
        (state,templateid,indexarr) => indexarr
    ],
    (apptemplates, templateid, indexarr)=>{
        if(templateid==undefined||indexarr==undefined){
            return []
        }
        let appvariables = apptemplates.appvariables[templateid];
        let elements = apptemplates.appelements[templateid];
        let loopvariables = [];
        for(let i=0; i< indexarr.length; i++){
            if(elements[indexarr[i]].type=="loop"){
                let loopv = generateLoopVaraible(
                                                    elements[indexarr[i]].loopvariable, 
                                                    elements[indexarr[i]].loopvariablemapping, 
                                                    appvariables,
                                                    apptemplates.env,
                                                    apptemplates.currentpath
                                                    )
                let currentloopvariable = [
                    loopv,
                    {
                        "key": "loopvariable",
                        "label": "Loop Variable",
                        "type": Object,
                        "subschema":[]
                    }
                ]
                if(loopvariables.length>0){
                    let loopvarindex = _.findIndex(loopvariables, (lv)=>{return lv.key=="loopvariable"});
                    loopvariables[loopvarindex] = {...loopvariables[loopvarindex],
                                                   subschema: currentloopvariable
                                                  }
                }else{
                    loopvariables = currentloopvariable;
                }
            }
        }

        return loopvariables;

    }
)

export const selectLoopVariableV1 = (templateid, indexarr)=>{
    return (state)=>{
        if(templateid==undefined||indexarr==undefined){
            return []
        }
        let apptemplates = state.apptemplates;
        let appvariables = apptemplates.appvariables[templateid];
        let elements = apptemplates.appelements[templateid];
        let loopvariables = [];
        for(let i=0; i< indexarr.length; i++){
            if(elements[indexarr[i]].type=="loop"){
                let loopv = generateLoopVaraible(
                                                    elements[indexarr[i]].loopvariable, 
                                                    elements[indexarr[i]].loopvariablemapping, 
                                                    appvariables,
                                                    apptemplates.env,
                                                    apptemplates.currentpath
                                                    )
                let currentloopvariable = [
                    loopv[0],
                    {
                        "key": "loopvariable",
                        "label": "Loop Variable",
                        "type": "object",
                        "subschema":[]
                    }
                ]
                if(loopvariables.length==0){
                    loopvariables = [...currentloopvariable];
                }else{
                    insertLoopVariable(loopvariables, currentloopvariable);
                }
                
            }
            elements = elements[indexarr[i]].childs;
        }
        return loopvariables;
    }

}

export const selectInnerHtml = createSelector(
    [
        (state)=>{
            return state.apptemplates
        },
        (state, variable)=> variable,
        (state, variable, routeparams)=> routeparams, 
        (state, variable, routeparams, key)=> key,
        (state, variable, routeparams, key, position) => position
    ],
    (apptemplates, variable, routeparams, key, position)=>{
        let objectval = {}
        let sc = variable.schema;
        let sm = variable.schemamapping;
        let appvariables = apptemplates.appvariables;
        let templatevariables = appvariables[variable.key]?.schema;
        let elements = apptemplates.appelements[variable.key];
        let loopvariables = generateLoopVaraibleP(elements, templatevariables, position)
        let appinputs = apptemplates.appinputs;
        let inputs = appinputs[variable.key];
        let inputvariable = [{
            "key": "inputs",
            "label": "inputs",
            "type": "object",
            "subschema": inputs
        }]
        if(apptemplates.prod==false&&apptemplates.activetemplateid==key){
            if(apptemplates.routeparams[key]!=undefined){
                routeparams = JSON.parse(JSON.stringify(apptemplates.routeparams[key]));
                routeparams = routeparams.schema;
            }else{
                routeparams = [];
            }
            
        }else{
            let rp = [{
                "key": "routeparams",
                "label": "Routeparams",
                "type": "object",
                "subschema":[]
            }]
            let subschema = [];
            generateSchema(routeparams, subschema);
            rp[0] = {...rp[0],
                     subschema: subschema
                    }
            routeparams = rp;
        }
        objectval = getAppObjectVal(
                                        sc,
                                        sm, 
                                        templatevariables, 
                                        loopvariables, 
                                        inputvariable, 
                                        routeparams,
                                        objectval,
                                        apptemplates.env,
                                        apptemplates.currentpath
                                        );
        if(objectval=={}){
            return undefined
        }else{
            return objectval.value;
        }
        
    }
)

export const selectLoopVariableValue = createSelector(
    [
        (state) => state.apptemplates,
        (state, variable) => variable,
        (state, variable, key) => key,
        (state, variable, key, position) => position
    ],
    (apptemplates, variable, key, position)=>{
        let objectval = {};
        let sc = variable.sc;
        let sm = variable.sm;
        let appvariables = apptemplates.appvariables;
        let templatevariable = appvariables[key]?.schema;
        let elements = apptemplates.appelements[key];
        let loopvariable = generateLoopVaraibleP(elements, templatevariable, position);
        objectval = getAppObjectVal(
                                    sc, 
                                    sm, 
                                    templatevariable, 
                                    loopvariable,
                                    [], 
                                    [],
                                    objectval,
                                    apptemplates.env,
                                    apptemplates.currentpath
                                    );
        if(objectval==undefined&&objectval=={}&&objectval.loopvariable==undefined){
            return []
        }else{
            return objectval.loopvariable;
        }
    }
)

export const selectActiveElement = (position)=>{
    return (state)=>{
        let elements = state.apptemplates.elements;
        let activeposition = state.apptemplates.activeposition;
        let element;
        let secondPointer = elements;
        for(let i=0; i < activeposition.length; i++){
            if(i==(activeposition.length-1)){
                element = secondPointer[activeposition[i]];
            }else{
                // todo: do check why we need this
                if(secondPointer[activeposition[i]]!=undefined){
                    secondPointer = secondPointer[activeposition[i]].childs;
                }else{
                    element=undefined;
                    break;
                }
            }
        }
        return element;
    }
}

export const selectAppPipelines = createSelector(
    [
        (state) => state.apptemplates,
        (state,key) => key,
        (state, key, res) => res 
    ],
    (apptemplates, key, res)=>{
        let apppipelines = apptemplates.templatepipelines;
        if(apppipelines!=undefined){
            res = apppipelines[key];
            return res;
        }
    }
)

export const selectAppVariables = createSelector(
    [
        (state) => state.apptemplates,
        (state,key) => key
    ],
    (apptemplates, key)=>{
        let appvariables = apptemplates.appvariables;
        if(appvariables!=undefined){
            return appvariables[key]
        }
        
    }
)

export const selectTemplates = state => state.apptemplates.appelements;

export const selectFocusSource = state => state.apptemplates.focussource;

export const { 
                setActivePosition,
                setFocusPosition,
                setElementDragged,
                handleElementDrop,
                setElement,
                setElements,
                setTemplateVariable,
                setTemplateId,
                resetelements,
                resetvariables,
                resetactivetemplateid,
                deleteElement,
                transformvar,
                setInputType,
                onChangeHandler,
                setProd,
                setCurrentPath,
                setmode,
                resetmode,
                resetapptemplates,
                setactivetemplateid
            } = apptemplateSlice.actions;

export default apptemplateSlice.reducer;