import { Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEnvironVar, getOptions, resetvariableupdated, selectCurrentAppvar, selectCurrentWebsiteVar, selectCurrentWorkspacevar, selectDomains, selectFolders, selectTemplates, selectVariableUpdate, updateEnvironVar } from "../../Services/environvariables/environvariables.slice";
import "./Environvariables.css";

const EnvironVariables = ({
    resource,
    resourceId,
    variableId
})=>{

    const variabletypes = [
        "string",
        "number",
        "boolean",
        "datetime",
        "host",
        "folder",
        "template"
    ];

    const {workspace} = useParams();

    const [environvars, setEnvironvars] = useState([]);

    const [edit , setEdit] = useState(false);
    
    const domains = useSelector(selectDomains);

    const folders = useSelector(selectFolders);

    const templates = useSelector(selectTemplates);
    
    const dispatch = useDispatch();

    const currentworkspacevar = useSelector(selectCurrentWorkspacevar);

    const currentappvar = useSelector(selectCurrentAppvar);

    const currentwebsitevar = useSelector(selectCurrentWebsiteVar);

    useEffect(()=>{
        if(resource==""&&resource==undefined){
            return
        }
        if(resource=="app"&&currentappvar.length>0){
            let remotecopy = [...currentappvar];
            setEnvironvars(remotecopy);
        }
        if(resource=="workspace"&&currentworkspacevar.length>0){
            let remotecopy = [...currentworkspacevar];
            setEnvironvars(remotecopy)
        }
        if(resource=="website"&&currentwebsitevar.length>0){
            let remotecopy = [...currentwebsitevar];
            setEnvironvars(remotecopy);
        }
    },[currentwebsitevar, currentappvar, currentworkspacevar])

    useEffect(()=>{
        if(resource!=""&&resource!=undefined&&resourceId!=undefined&&resourceId!=""){
            dispatch(getEnvironVar({
                source: resource,
                sourceid: resourceId
            }))
        }
    },[resource, resourceId])

    useEffect(()=>{
        dispatch(getOptions({type: "domain", workspace: workspace}));
        dispatch(getOptions({type: "folder", workspace: workspace}));
        dispatch(getOptions({type: "template", workspace: workspace}));
    },[])

    const addVar = ()=>{
        let environvarsCopy = [...environvars];
        environvarsCopy.push({
            "name": "",
            "type": "",
            "value": ""
        })
        if(edit==false){
            setEdit(true);
        }
        setEnvironvars(environvarsCopy);
    }

    const setVariableName = (index, val)=>{
        let environvarsCopy = [...environvars];
        environvarsCopy[index] = {...environvarsCopy[index],
                                    name: val
                                 }
        if(edit==false){
            setEdit(true);
        }
        setEnvironvars(environvarsCopy);
    }

    const setVariableType = (index, val)=>{
        let environvarsCopy = [...environvars];
        environvarsCopy[index] = {...environvarsCopy[index],
                                  type: val
                                 }
        if(edit==false){
            setEdit(true);
        }
        setEnvironvars(environvarsCopy);
    }

    const setVariableValue = (index, val)=>{
        let environvarsCopy = [...environvars];
        environvarsCopy[index] = {...environvarsCopy[index],
                                    value: val
                                 }  
        if(edit==false){
            setEdit(true);
        }
        setEnvironvars(environvarsCopy)
    }
    
    const deleteVar = (index)=>{
        let environvarsCopy = [...environvars];
        environvarsCopy.splice(index,1);
        if(edit==false){
            setEdit(true);
        }
        setEnvironvars(environvarsCopy);
    }

    const saveVar = ()=>{
        dispatch(resetvariableupdated())
        dispatch(updateEnvironVar({
            source: resource,
            sourceid: resourceId,
            fields: environvars
        }))
    }

    const variableupdated = useSelector(selectVariableUpdate);

    useEffect(()=>{
        if(edit&&variableupdated){
            setEdit(false);
            dispatch(resetvariableupdated())
            dispatch(getEnvironVar({
                source: resource,
                sourceid: resourceId
            }))
        }
    },[variableupdated])

    return(
        <div>
            <div>
                <div>
                    {edit&&
                        <Button 
                                style={{color: "#3A07CD"}}    
                                onClick={()=>{saveVar()}}
                        >Save</Button>
                    }
                </div>
                {environvars.map((envvar, index)=>{
                    return (
                        <div className="var-line">
                            <div className="var-line-item">
                                <TextField
                                    value={envvar.name}
                                    onChange={(e)=>{setVariableName(index, e.target.value)}}
                                    label={"Name"}
                                >
                                </TextField>
                            </div>
                            <div className="var-line-item">
                                <FormControl sx={{width: 200}}>
                                    <InputLabel id={"var-line-item-"+index}>{"Variable Type"}</InputLabel>
                                    <Select
                                        labelId={"var-line-item-"+index}
                                        value={envvar.type}
                                        onChange={(e)=>{setVariableType(index, e.target.value)}}
                                        label="Variable Type"
                                    >
                                        {variabletypes.map((vartype)=>{
                                            return (
                                                <MenuItem value={vartype}>{vartype}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="var-line-item">
                                {envvar.type=="host"&&
                                    <>
                                        <FormControl sx={{width: 200}}>
                                            <InputLabel id={"host-options-"+index}>Variable value</InputLabel>
                                            <Select
                                                label="Variable Value"
                                                labelId={"host-options"+index}
                                                value={envvar.value}
                                                onChange={(e)=>{setVariableValue(index, e.target.value)}}
                                            >
                                                {
                                                    domains.map((domain)=>{
                                                        return (
                                                            <MenuItem value={domain.name}>{domain.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </>
                                }
                                {envvar.type=="folder"&&
                                    <>
                                        <FormControl sx={{width: 200}}>
                                            <InputLabel id={"folder-options-"+index}>Variable value</InputLabel>
                                            <Select
                                                label="Variable Value"
                                                labelId={"folder-options-"+index}
                                                value={envvar.value}
                                                onChange={(e)=>{setVariableValue(index, e.target.value)}}
                                            >
                                                {folders.map((folder)=>{
                                                    return (
                                                        <MenuItem value={folder._id}>{folder.path}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                       </FormControl>
                                    </>
                                }
                                {envvar.type=="template"&&
                                    <>
                                        <FormControl sx={{width: 200}}>
                                            <InputLabel id={"template-options-"+index}>Variable value</InputLabel>
                                            <Select
                                                labelId={"template-options-"+index}
                                                value={envvar.value}
                                                onChange={(e)=>{setVariableValue(index, e.target.value)}}
                                                label="Variable Value"
                                            >
                                                {templates.map((template)=>{
                                                    return (
                                                        <MenuItem value={template._id}>{template.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </>
                                }
                                {envvar.type=="string"&&
                                    <>
                                        <TextField 
                                                value={envvar.value}
                                                onChange={(e)=>{setVariableValue(index, e.target.value)}}
                                                label={"Variable Value"}
                                        ></TextField>
                                    </>
                                }
                                {envvar.type=="number"&&
                                    <>
                                        <TextField 
                                            value={envvar.value}
                                            onChange={(e)=>{setVariableValue(index, e.target.value)}}
                                            type="number"
                                            label={"Variable Value"}
                                        ></TextField>
                                    </>
                                }
                                {envvar.type=="boolean"&&
                                    <div>
                                        <div>
                                            Variable Value:
                                        </div>
                                        <div>
                                            <input 
                                                type="checkbox" 
                                                value={envvar.value} 
                                                onChange={(e)=>{setVariableValue(index, e.target.checked)}}>
                                            </input>
                                        </div>
                                         
                                    </div>
                                }
                                {envvar.type=="datetime"&&
                                    <div>
                                        <div>
                                            Variable Value
                                        </div>
                                        <div>
                                            <input type="datetime" value={envvar.value} onChange={(e)=>{setVariableValue(index, e.target.value)}}></input>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="var-line-item">
                                <IconButton onClick={()=>{deleteVar(index)}}>
                                    <span className="material-symbols-outlined">
                                        delete
                                    </span>
                                </IconButton>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Button style={{color: "#3A07CD"}} onClick={()=>{addVar()}}>
                Add Field
            </Button>
        </div>
    )
}


export default EnvironVariables;