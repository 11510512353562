import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import CreateMotors from "./CreateMotors";
import {Delineatedpipeline, resetPipeline} from "../utils";
import {getKey} from "../appdesigner/utils";
import {
    resettestactionpoutput,
    getSchemaMappingMap,
    resetactivepipeline,
    getPipelinebySchema,
    getPipelinebyId, 
    selectActivePipeline,
    updatePipeline,
    setmappingmap,
    resetmappingmap,
    selectPipelineRemote,
    resetpipeline,
    updatePipelineF,
    loadDelineatedPipeline
} from "../../Services/pipelines/pipeline.slice";

import {
    templatePipleine,
    selectTemplateLoadStatus,
    getAppVariables,
    getAppElements,
    getAppPipelines,
    setactivetemplateid
} from "../../Services/apptemplates/apptemplates.slice";
import TestStage from "../apps/TestStage/TestStage";
import * as _ from "lodash";
import { 
        getEnvironVar, 
        selectCurrentAppvar, 
        selectCurrentWebsiteVar, 
        selectCurrentWorkspacevar 
        } from "../../Services/environvariables/environvariables.slice";


const Pipeline = ()=>{

    const {id, type, appid, pipelineid, templateid,source, tempsource, workspace} = useParams();

    const [openTestStage, setTestStageOpen] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [activePipelineName, setActivePipelineName] = useState("Untitled");

    const [onload, setonload] = useState(false);

    const [expose, setExpose] = useState(false);

    const templateloaded = useSelector((state)=>{return selectTemplateLoadStatus(state, templateid)})

    useEffect(()=>{
        if(templateloaded){
            return
        }
        if(source!="apps"&&templateid!=undefined){
            dispatch(getAppVariables(
                {
                    "templateid": templateid,
                    "key": templateid,
                    "elmkey": "",
                    "indexarr": [],
                    "source": "pipelines"
                }
            ))
            dispatch(getAppElements({
                "templateid": templateid,
                "indexarr": [],
                "elmkey": "",
                "source": "pipelines"
            }));
        }
    },[templateid])

    const closePipes = ()=>{
        if(source=="apps"){
            dispatch(resetactivepipeline(""));
            // setopenmotorcreate(false);
            resetPipeline();
            dispatch(resetpipeline());
            dispatch(resetmappingmap());
            dispatch(getPipelinebySchema({schemaid: id}));
            navigate("/"+workspace+"/schemas/"+appid+"/details/"+id);
        }
        
        if(source=="apptemplates"){
            dispatch(resetactivepipeline(""));
            resetPipeline();
            dispatch(resetpipeline());
            dispatch(resetmappingmap());
            dispatch(templatePipleine(templateid));
            navigate("/"+workspace+"/appdesigner/"+type+"/"+tempsource+"/"+templateid);
        }

        if(source=="installmanifest"){
            dispatch(resetactivepipeline(""));
            resetPipeline();
            dispatch(resetpipeline());
            let parts = id.split("__");
            navigate("/"+appid+"/manifest/"+parts[0]+"/"+parts[1])

        }
    }

    const mappingMap = useSelector(getSchemaMappingMap);

    const pipeline = useSelector(selectPipelineRemote);

    const updatePipelineFunc = (pipelineCopy)=>{
        if(pipelineCopy==undefined){
            pipelineCopy = [...pipeline];
        }
        let pipelinepayload = {
                               pipeline: pipelineCopy,
                               id: pipelineid,
                               mappingMap: mappingMap,
                               source: source
                             }
        dispatch(resettestactionpoutput());
        dispatch(updatePipelineF(pipelinepayload));
    }

    const savePipelinename = ()=>{
        let pipelinepayload = {
                                name: activePipelineName,
                                pipeline: [...pipeline],
                                id: pipelineid,
                                mappingMap: mappingMap,
                                source: source
                            }
        dispatch(updatePipeline(pipelinepayload))
    }

    const setonloadAction = (val)=>{
        let pipelinepayload = {
                                name: activePipelineName,
                                pipeline: [...pipeline],
                                id: pipelineid,
                                mappingMap: mappingMap,
                                source: source,
                                onload: val,
                                expose: expose
                              }
        setonload(val);
        dispatch(updatePipeline(pipelinepayload));
    }

    const setExposeAction = (val)=>{
        let pipelinepayload = {
                                name: activePipelineName,
                                pipeline: [...pipeline],
                                id: pipelineid,
                                mappingMap: mappingMap,
                                source: source,
                                onload: onload,
                                expose: val
                              }
        setExpose(val);
        dispatch(updatePipeline(pipelinepayload));
    }

    useEffect(()=>{
        if(source=="apps"){
            dispatch(getEnvironVar({
                "source": "workspace",
                "sourceid": workspace
            }));
            dispatch(getEnvironVar({
                "source": "app",
                "sourceid": appid
            }))
            
        }else if(source=="apptemplates"){
            dispatch(setactivetemplateid(templateid));
            dispatch(getAppPipelines({
                                        "templateid": templateid,
                                        "key": getKey(templateid, []),
                                        "elmkey": getKey(templateid, []),
                                        "indexarr": []
                                      }))
            if(tempsource!="templates"){
                dispatch(getEnvironVar({
                    "source": "workspace",
                    "sourceid": workspace
                }));
                dispatch(getEnvironVar({
                    "source": "website",
                    "source": tempsource
                }))
            }else{
                dispatch(getEnvironVar({
                    "source": "workspace",
                    "sourceid": workspace
                }))
            }
        }
    },[source, appid, templateid])

    const currentworkspacevar = useSelector(selectCurrentWorkspacevar);
    const currentwebsitevar = useSelector(selectCurrentWebsiteVar);
    const currentappvar = useSelector(selectCurrentAppvar);

    useEffect(()=>{
        let appenvar = [];
        for(let i=0; i < currentappvar.length; i++){
            if(
                currentappvar[i].type=="string"||
                currentappvar[i].type=="number"||
                currentappvar[i].type=="boolean"||
                currentappvar[i].type=="datetime"){
                appenvar.push({
                    "key": currentappvar[i].name,
                    "value": currentappvar[i].value,
                    "label": currentappvar[i].name,
                    "type": currentappvar[i].type 
                })
            }
        }
        let workspaceenvar = [];
        for(let i=0; i< currentworkspacevar.length; i++){
            if(
                currentworkspacevar[i].type=="string"||
                currentworkspacevar[i].type=="number"||
                currentworkspacevar[i].type=="boolean"||
                currentworkspacevar[i].type=="datetime"
            ){
                workspaceenvar.push({
                    "key": currentworkspacevar[i].name,
                    "value": currentworkspacevar[i].value,
                    "label": currentworkspacevar[i].name,
                    "type": currentworkspacevar[i].type
                })
            }
        }
        let currentwebsitevar = [];
        for(let i=0; i< currentwebsitevar.length; i++){
            if(
                currentwebsitevar[i].type=="string"||
                currentwebsitevar[i].type=="number"||
                currentwebsitevar[i].type=="boolean"||
                currentwebsitevar[i].type=="datetime"
            ){
                currentwebsitevar.push({
                    "key": currentwebsitevar[i].name,
                    "value": currentwebsitevar[i].value,
                    "label": currentwebsitevar[i].name,
                    "type": currentwebsitevar[i].type
                })
            }
        }
        let envvars = [{
            "key": "___envvar___",
            "value":"",
            "type": "object",
            "subschema":[
                {
                    "key": "workspace",
                    "label": "workspace",
                    "type": "object",
                    "subschema":workspaceenvar
                },
                {
                    "key": "app",
                    "label": "app",
                    "type": "object",
                    "subschema":appenvar
                },
                {
                    "key": "website",
                    "label": "Website",
                    "type": "object",
                    "subschema": currentwebsitevar
                }
            ],
        }];

        Delineatedpipeline["___envvar___"] = {...Delineatedpipeline["___envvar___"],
                                            outputschema: envvars
                                            }

    }, currentwebsitevar, currentappvar, currentworkspacevar)
    
    useEffect(()=>{
        if(pipeline.length>0){
            updatePipelineFunc()
        }
    },[pipeline])

    const activepipeline = useSelector(selectActivePipeline);

    useEffect(()=>{
        if(activepipeline==""||activepipeline==null){
            return
        }
        if(activepipeline?.pipeline.length==0){
            return
        }
        let activepipelinecopy = [...activepipeline.pipeline];
        _.mapKeys(activepipeline.delineatedpipeline,(value, key)=>{
            Delineatedpipeline[key] = value;
        })
        dispatch(setmappingmap(activepipeline.mappingMap));
        dispatch(loadDelineatedPipeline({templateid: templateid}))
        setActivePipelineName(activepipeline.name);
        if(activepipeline.onload!=undefined){
            setonload(activepipeline.onload);
        }
        if(activepipeline.expose!=undefined){
            setExpose(activepipeline.expose);
        }
    },[activepipeline])

    useEffect(()=>{
        if(pipelineid==""){
            return;
        }
        dispatch(getPipelinebyId({id: pipelineid}))  
    },[pipelineid])


    return (
            <>
                <div className="input-container">
                    <CreateMotors 
                        close={closePipes} 
                        pipelinetype="main"
                        width={1000}
                        activePipelineName = {activePipelineName}
                        setActivePipelineName = {setActivePipelineName}
                        testStageAction = {()=>{setTestStageOpen(true)}}
                        position={[]}
                        savePipelinename={savePipelinename}
                        templateid={templateid}
                        source={source}
                        onload={onload}
                        expose={expose}
                        setonloadAction = {setonloadAction}
                        setExposeAction = {setExposeAction}
                        pipelineid={id}
                    ></CreateMotors>
                    <TestStage
                        open={openTestStage}
                        handleClose={()=>{setTestStageOpen(false)}}
                        loop={pipeline[0]}
                        pipelineid={pipelineid}
                        header={"test"}
                    ></TestStage>
                </div>
            </>
            )
}

export default Pipeline;