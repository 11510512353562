import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { 
        getTemplates, 
        resetTemplatecreated, 
        resetTemplatedeleted, 
        selectTemplateDeleted, 
        selectTemplates,
        createTemplate,
        selectTextTemplateCreated,
        deleteTemplate,
        deployTemplate,
        selectTempateworkspacepermission
     } from "../../../Services/templates/template.slice";
import BreadCrumbs from "../../BreadCrumbs";
import { Button, FormControl, MenuItem, Modal, Select, TextField , Box, IconButton} from "@mui/material";
import "./Templatelist.css";
import { useNavigate, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
        getWebsiteCreateOptions, 
        selectWebsiteCreateOption,
        resetwebsitecreatewebsiteoptions,
        getWebsiteCreateWebsiteOptions,
        resetwebsitecreateworkspaceoptions,
        getWebsiteCreateWorkspaceOptions,
        selectWebsitecreatewebsiteoptions,
        selectWebsitecreateworkspaceoptions,
        resetwebsitecreateoption
    } from "../../../Services/websites/website.slice";
import { CheckBox } from "@mui/icons-material";
import { showError } from "../../../Services/error.slice";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


const TemplateCreateModal = ({open, handleClose})=>{

    const [createmode, setcreatemode] = useState("new");

    const [templateName, setTemplateName] = useState("");

    const [templateType, setTemplateType] = useState("text");

    const [tempateid, setTemplateid] = useState("");

    const [websiteid, setWebsiteid] = useState("");

    const [workspaceid, setWorkspaceid] = useState("");

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {workspace} = useParams();

    const [saveclicked, setSaveclicked] = useState(false);

    const save = ()=>{
        setSaveclicked(true);
        dispatch(resetTemplatecreated(false));
        let payload;
        if(templateType=="text"){
            payload = {
                "name": templateName,
                "type": templateType,
                "variable": {
                    "schema":[{
                        "key": "input",
                        "label": "Input",
                        "type": "object",
                        "required": false,
                        "fillallowed": true,
                        "guided": true,
                        "guidefinsihed": false,
                        "subschema":[],
                        editallowed: true
                    }],
                    "schemamapping":[{
                        "key": "input",
                        "mapping": ""
                    }]
                },
                "templatetext": "",
                "source": "templates",
                "workspace": workspace
            }
        }else{
            if(createmode=="new"){
                payload = {
                    "name": templateName,
                    "type": templateType,
                    "createmode": "new",
                    "elements":[
                        {
                            "tag": "head",
                            "elemtype": "system",
                            "childs":[],
                            "type": "single"
                        },
                        {
                            "tag": "body",
                            "elemtype": "system",
                            "childs":[],
                            "type": "single"
                        }
                    ],
                    "settings":{
                        jsenabled: true
                    },
                    "source": "templates",
                    "workspace": workspace,
                    childenabled: false
                }
            }else{
                if(tempateid==""){
                    dispatch(showError("Cannot clone empty id !!!"))
                    return
                }else{
                    payload = {
                        "name": templateName,
                        "type": templateType,
                        "source": "templates",
                        "workspace": workspace,
                        "templateid": tempateid,
                        "createmode": "clone"
                    }   
                }
            }
        }   
        dispatch(createTemplate(payload));
    }

    const templatedcreated = useSelector(selectTextTemplateCreated);

    const websitecreateoption = useSelector(selectWebsiteCreateOption);

    const websitecreatewebsiteoptions = useSelector(selectWebsitecreatewebsiteoptions);

    const websitecreateworkspaceoptions = useSelector(selectWebsitecreateworkspaceoptions);

    useEffect(()=>{
        if(saveclicked==true&&templatedcreated.status!=false&&templatedcreated.status!=undefined){
            if(templateType=="text"){
                setSaveclicked(false);
                navigate("/"+workspace+"/templates/text/"+templatedcreated.templateid);
            }
            if(templateType=="html"){
                setSaveclicked(false);
                navigate("/"+workspace+"/appdesigner/html/templates/"+templatedcreated.templateid);
            }
        }
    },[saveclicked, templatedcreated])

    const moveback = ()=>{
        if(createmode=="workspacewebsite"){
            setTemplateid("");
            setWebsiteid(null);
            dispatch(resetwebsitecreatewebsiteoptions());
            setcreatemode("workspace");
        }else if(createmode=="workspace"){
            setTemplateid("");
            dispatch(resetwebsitecreateworkspaceoptions());
            setWorkspaceid(null);
            setcreatemode("all");
        }else if(createmode=="all"){
            dispatch(resetwebsitecreateoption());
            setcreatemode("new");
        }else if(createmode=="website"){
            dispatch(resetwebsitecreatewebsiteoptions());
            setWebsiteid(null);
            setcreatemode("all");
        }
    }

    const moveMode = (mode, data)=>{
        if(mode=="website"){
            setTemplateid("");
            setWebsiteid(data);
            dispatch(resetwebsitecreatewebsiteoptions());
            dispatch(getWebsiteCreateWebsiteOptions(data));
            setcreatemode("website");
        }else if(mode=="workspace"){
            setTemplateid("");
            setWorkspaceid(data);
            dispatch(resetwebsitecreatewebsiteoptions());
            dispatch(resetwebsitecreateworkspaceoptions());
            dispatch(getWebsiteCreateWorkspaceOptions(data));
            setcreatemode("workspace")
        }else if(mode=="workspacewebsite"){
            setTemplateid("");
            setWebsiteid(data);
            dispatch(resetwebsitecreatewebsiteoptions());
            dispatch(getWebsiteCreateWebsiteOptions(data));
            setcreatemode("workspacewebsite");
        }
    }

    const clone = ()=>{
        setcreatemode("all");
        dispatch(getWebsiteCreateOptions(workspace));

    }


    const setTemplateidAcion = (tid, e)=>{
        if(e.target.checked){
            setTemplateid(tid)
        }else{
            setTemplateid("")
        }
    }

    

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="opencreate-headers">
                    <div>
                        <h3>Create Template</h3>
                        {createmode!="new"&&
                            <div>
                                <IconButton onClick={()=>{moveback()}}>
                                    <span className="material-symbols-outlined">
                                        arrow_back
                                    </span>
                                </IconButton>
                            </div>
                        }
                    </div>
                </div>
                {createmode=="new"&&
                    <>
                        <div>
                            <div style={{padding: 10}}>
                                <TextField value={templateName} onChange={(e)=>{setTemplateName(e.target.value)}}></TextField>
                            </div>
                            <div style={{padding: 10}}>
                                <FormControl variant="standard">
                                    <Select
                                        value={templateType}
                                        onChange={(e)=>{setTemplateType(e.target.value)}}
                                        variant="standard"
                                    >
                                        <MenuItem value="text">Text</MenuItem>
                                        <MenuItem value="html">Html</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <Button style={{color: "#3A07CD"}} onClick={()=>{save()}}>Create New</Button>
                            {templateType=="html"&&
                                <Button style={{color: "#3A07CD"}} onClick={()=>{clone()}}>Clone</Button>
                            }
                            <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                        </div>
                    </>
                }
                {createmode=="all"&&
                    <div>
                        <div className="website-create-conatiner">
                            {websitecreateoption?.map((wco)=>{
                                return (
                                    <div style={{textAlign: "center", margin: "10px", cursor: "pointer"}} onClick={()=>{moveMode(wco.type, wco._id)}}>
                                        <div>
                                            {wco.type=="template"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder
                                                </span>
                                            }
                                            {wco.type=="website"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    language
                                                </span>
                                            }
                                            {wco.type=="workspace"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder_open
                                                </span>
                                            }
                                        </div>
                                        <div>
                                            {wco.name}
                                            {wco.type=="template"&&
                                                <input type="checkbox" checked={tempateid==wco._id} onChange={(e)=>{setTemplateidAcion(wco._id, e)}}></input>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <Button style={{color: "#3A07CD"}} disabled={tempateid==""} onClick={()=>{save()}}>Save</Button>
                            <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                        </div>
                    </div>
                }
                {createmode=="website"&&
                    <div>
                        <div className="website-create-conatiner">
                            {websitecreatewebsiteoptions?.map((t)=>{
                                return(
                                    <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode("website"+t.type, t._id)}}>
                                        <div>
                                            {t.type=="template"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder
                                                </span>
                                            }
                                            {t.type=="website"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder_open
                                                </span>

                                            }
                                        </div>
                                        <div>
                                            {t.name}
                                            {t.type=="template"&&
                                                <input type="checkbox" checked={tempateid==t._id} onChange={(e)=>{setTemplateidAcion(t._id, e)}}></input>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <Button style={{color: "#3A07CD"}} disabled={tempateid==""} onClick={()=>{save()}}>Save</Button>
                            <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                        </div>
                    </div>
                }
                {createmode=="workspace"&&
                    <div>
                        <div className="website-create-conatiner">
                                {websitecreateworkspaceoptions?.map((wco)=>{
                                    return (
                                        <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode("workspace"+wco.type, wco._id)}}>
                                            <div>
                                                {wco.type=="template"&&
                                                    <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                        folder
                                                    </span>
                                                }
                                                {wco.type=="website"&&
                                                    <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                        language
                                                    </span>
                                                }
                                                {wco.type=="workspace"&&
                                                    <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                        folder_open
                                                    </span>
                                                }
                                            </div>
                                            <div>
                                                {wco.name}
                                                {wco.type=="template"&&
                                                    <input type="checkbox" checked={tempateid==wco._id} onChange={(e)=>{setTemplateidAcion(wco._id, e)}}></input>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div>
                                <Button style={{color: "#3A07CD"}} disabled={tempateid==""} onClick={()=>{save()}}>Save</Button>
                                <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                            </div>
                    </div>
                }
                {createmode=="workspacewebsite"&&
                    <div>
                        <div className="website-create-conatiner">
                            {websitecreatewebsiteoptions?.map((t)=>{
                                return(
                                    <div style={{textAlign: "center", cursor: "pointer"}} onClick={()=>{moveMode("workspacewebsite"+t.type, t._id)}}>
                                        <div>
                                            {t.type=="template"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder
                                                </span>
                                            }
                                            {t.type=="website"&&
                                                <span style={{color: "#3A07CD", fontSize: "60px"}} className="material-symbols-outlined">
                                                    folder_open
                                                </span>
                                            }
                                        </div>
                                        <div>
                                            {t.name}
                                            {t.type=="template"&&
                                                <input type="checkbox" checked={tempateid==t._id} onChange={(e)=>{setTemplateidAcion(t._id, e)}}></input>
                                            }
                                        </div>
                                    </div>
                                )
                            })}  
                        </div>
                        <div>
                            <Button style={{color: "#3A07CD"}} disabled={tempateid==""} onClick={()=>{save()}}>Save</Button>
                            <Button style={{color: "#3A07CD"}} onClick={()=>{handleClose()}}>Cancel</Button>
                        </div>
                    </div>
                }
            </Box>
        </Modal>
    )

}


const Templatelist = ()=>{

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [templates, setTemplates] = useState([]);

    const {workspace} = useParams()

    const workspaceaccess = useSelector(selectTempateworkspacepermission);

    useEffect(()=>{
        dispatch(getTemplates(workspace));
    },[])

    const [openCreate, setOpenCreate] = useState(false);

    const templatelist = useSelector(selectTemplates);

    useEffect(()=>{
        if(templatelist.length>0){
            let templatelistcopy = [...templatelist];
            setTemplates(templatelistcopy);
        }
    },[templatelist])

    const [deleteClicked, setDeleteClicked] = useState(false);

    const deleteTemplateAction = (id)=>{
        dispatch(resetTemplatedeleted());
        setDeleteClicked(true);
        dispatch(deleteTemplate(id));
    }

    const deploytemplate = (id)=>{
        dispatch(deployTemplate({
            id: id,
            source: "templates",
            workspace: workspace
        }))
    }

    const templateDeleted = useSelector(selectTemplateDeleted);

    useEffect(()=>{
        if(deleteClicked&&templateDeleted){
            dispatch(getTemplates(workspace));
            setDeleteClicked(false);
        }
    },[templateDeleted,deleteClicked]);


    const openTemplateEditor = (type, id)=>{
        if(type=="text"){
            navigate("/"+workspace+"/templates/text/"+id);
        }
        if(type=="html"){
            navigate("/"+workspace+"/appdesigner/html/templates/"+id);
        }
    }

    const openPublish = (id)=>{
        navigate("/"+workspace+"/manifest/template/"+id);
    }

    return(
        <>
            <div className="template-list">
                <BreadCrumbs
                    breadcrumbs={
                        workspace=="main"?
                        [   {
                            "name": "dashboard",
                            "url": "/"
                            },
                            {
                            "name": "interfaces",
                            "url": "/interfaces"
                            }
                        ]:
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            },
                            {
                                "name": "workspace",
                                "url": "/workspaces/"+workspace
                            },
                            {
                                "name": "interface",
                                "url": "/"+workspace+"/interfaces"
                            }
                        ]
                    }
                >
                </BreadCrumbs>
                <div className="template-headers">
                    <h3>
                        Templates
                    </h3>
                    {workspaceaccess=="write"&&
                        <Button  onClick={()=>{setOpenCreate(true)}} style={{color: "#3A07CD"}}>Create</Button>
                    }
                </div>
                <div>
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Updated At</TableCell>
                                <TableCell>DeployedAt</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates.map((template)=>{
                                return (
                                    <TableRow>
                                        <TableCell>
                                            {workspaceaccess=="write"?
                                                <a style={{"cursor": "pointer"}} onClick={()=>{openTemplateEditor(template.type, template._id)}}>{template.name}</a>:
                                                <a style={{"cursor": "pointer"}}>{template.name}</a>
                                            }
                                        </TableCell>
                                        <TableCell>{template.type}</TableCell>
                                        <TableCell>{template.createdAt}</TableCell>
                                        <TableCell>{template.updatedAt}</TableCell>
                                        <TableCell>{template.deployedAt}</TableCell>
                                        <TableCell>
                                            {workspaceaccess=="write"&&
                                                <>
                                                    <Button style={{color: "#3A07CD"}} onClick={()=>{deploytemplate(template._id)}}>
                                                        Deploy
                                                    </Button>
                                                    <IconButton onClick={()=>{deleteTemplateAction(template._id)}}>
                                                        <span className="material-symbols-outlined">
                                                            delete
                                                        </span>
                                                    </IconButton>
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </TableContainer>
                </div>
            </div>
            <TemplateCreateModal
                open={openCreate}
                handleClose={()=>{setOpenCreate(false)}}
            >
            </TemplateCreateModal>
        </>
    )


}

export default Templatelist;