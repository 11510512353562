import { useState , useEffect} from "react";
import {useParams, redirect, useNavigate, Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {selectLogs, getLogs} from "../../Services/pipelinelogs/pipelinelogs.slice";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from "@mui/material";

const Pipelineloglist  = ()=>{

    const {appid,schemaid ,pipelineid, status, workspace} = useParams(); 

    const dispatch = useDispatch();

    const loglist = useSelector(selectLogs);

    const navigate = useNavigate();

    const openlog = ()=>{
        
    }

    
    const close = ()=>{
        navigate("/"+workspace+"/schemas/"+appid+"/details/"+schemaid);
    }
    
    useEffect(()=>{
        if(pipelineid!=undefined){
            dispatch(getLogs({"id":pipelineid, "status": status}));
        }
    },[])


    return(
        <div>
            <div style={{display: "flex",justifyContent: "space-between",alignItems:"center", padding: 10}}>
                <div>
                    <h3>Pipeline Logs</h3>
                </div>
                <div>
                    <IconButton onClick={()=>{close()}}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </IconButton>
                </div>
                
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Execution time</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loglist.map((pipeline)=>{
                                return(
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <Link to={"/"+workspace+"/pipelinelogs/"+appid+"/"+schemaid+"/"+pipelineid+"/"+status+"/"+pipeline._id}>
                                                {pipeline._id}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {pipeline.executedAt}
                                        </TableCell>
                                        <TableCell>
                                            {pipeline.status.toString()}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}


export default Pipelineloglist;