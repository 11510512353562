import { useState } from "react"
import SingleBlock from "./blocks/SingleBlock";
import ConditionalBlock from "./blocks/ConditionalBlock";
import LoopBlock from "./blocks/LoopBlock";
import { useMediaQuery } from 'react-responsive'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFocusPosition } from "../../Services/apptemplates/apptemplates.slice";


const ElementContainer =({
                            element, 
                            indexarr,
                            elmkey,
                            position,
                            type,
                            childs,
                            parentkey,
                            parentposition,
                            parentindexarr,
                            templateinteraction
                        })=>{
    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1000px)'
        })
    
    const getMediaParam = ()=>{
        if(isDesktopOrLaptop){
            return element.desktop;
        }else{
            return element.mobile;
        }
    }


    return(
        <>
            {type=="html"&&
                <>
                    {element.type=="single"&&getMediaParam()&&
                        <SingleBlock
                            element={element}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position={position}
                            type={type}
                            childs={childs}
                            parentkey={parentkey}
                            parentposition={parentposition}
                            parentindexarr={parentindexarr}
                            templateinteraction={templateinteraction}
                        >
                        </SingleBlock>
                    }
                    {element.type=="conditional"&&getMediaParam()&&
                        <ConditionalBlock
                            element={element}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position={position}
                            type={type}
                            childs={childs}
                            parentkey={parentkey}
                            parentposition={parentposition}
                            parentindexarr={parentindexarr}
                            templateinteraction={templateinteraction}
                        >
                        </ConditionalBlock>
                    }
                    {element.type=="loop"&&getMediaParam()&&
                        <LoopBlock
                            element={element}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position={position}
                            type={type}
                            childs={childs}
                            parentkey={parentkey}
                            parentposition={parentposition}
                            parentindexarr={parentindexarr}
                            templateinteraction={templateinteraction}
                        >
                        </LoopBlock>
                    }
                </>
            }
            {type=="android"&&
                <>
                    {element.type=="single"&&
                        <SingleBlock
                            element={element}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position={position}
                        >
                        </SingleBlock>
                    }
                    {element.type=="conditional"&&
                        <ConditionalBlock
                            element={element}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position={position}
                        >
                        </ConditionalBlock>
                    }
                    {element.type=="loop"&&
                        <LoopBlock
                            element={element}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position={position}
                        >
                        </LoopBlock>
                    }
                </>

            }

        </>
    )


}
export default ElementContainer;