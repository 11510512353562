
import { Button, IconButton } from "@mui/material";
import "./CodeNavContainer.css";
import { useState, useEffect } from "react";
import { useDispatch , useSelector} from "react-redux";
import {
        selectActivepipelineid, 
        resetactivepipelineid, 
        resetactivepipeline,
        createPipeline,
        getPipelinebyId,
        setactivepipelineid,
        selectPipelineDeleted,
        resetpipelinedeleted,
        deletePipelineById
    } from "../../Services/pipelines/pipeline.slice";

import {
            createTemplateVariables, 
            getTemplateVariables, 
            selectActiveTemplatePipelines, 
            selectRouteParams, 
            selectTemplateVariable, 
            templatePipleine, 
            updateRouteParams, 
            updateTemplateVariables
        } from "../../Services/apptemplates/apptemplates.slice";
import DeclareVariable from "../apps/Fields/DeclareVariable";
import { useNavigate, useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const FunctionDeleteModal = ({open, handleClose,func,deleteFunction})=>{
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h3>
                        Delete Function {func?.name}
                    </h3>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Do you really want to delete function?
                    </Typography>
                </div>
                <div style={{display: "flex", "padding": 10}}>
                    <div>
                        <Button onClick={()=>{handleClose()}}>Cancel</Button>
                    </div>
                    <div>
                        <Button onClick={()=>{deleteFunction(func._id)}}>Yes</Button>
                    </div>
                </div>
            </Box>
      </Modal>
    )
}



const CodeNavContainer = ({
    jsEnabled,
    templateid,
    source
})=>{


    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {type, workspace} = useParams();

    const [newpipeline, setnewpipeline] = useState(false);

    const {__source} = useParams();

    const [templateVariable, setTemplateVariable] = useState({
        "schema":[{
            "key": "variable",
            "label": "Variable",
            "type": "object",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinsihed": true,
            "subschema":[],
            editallowed: true
        }],
        "schemamapping":[{
            "key": "variable",
            "mapping": ""
        }],
        "depsarray":[]
    });

    const [routeparams, setRouteParams] = useState({
        "schema":[{
            "key": "routeparams",
            "label": "RouteParams",
            "type": "object",
            "required": false,
            "fillallowed": true,
            "guided": false,
            "guidefinished": true,
            "subschema": [],
            editallowed: true
        }],
        "schemamapping":[{
            "key": "routeparams",
            "mapping": ""
        }]
    })

    const templateVariableRemote = useSelector((state)=>selectTemplateVariable(state, templateid));

    useEffect(()=>{
        if(templateVariableRemote==undefined){
            return
        }
        let tempcopy = {...templateVariableRemote};
        setTemplateVariable(tempcopy);
    },[templateVariableRemote])


    const routeparamsRemote = useSelector((state)=> selectRouteParams(state, templateid));

    useEffect(()=>{
        if(routeparamsRemote==undefined){
            return
        }
        let tempcopy = {...routeparamsRemote};
        setRouteParams(tempcopy);
    }, [routeparamsRemote])

    const addPipeline = ()=>{
        dispatch(resetactivepipelineid(""));
        dispatch(resetactivepipeline(""));
        setnewpipeline(true);
        dispatch(createPipeline({schemaid: templateid, source: "apptemplates"}));
    }

    const activepipleineid = useSelector(selectActivepipelineid);

    useEffect(()=>{
        if(newpipeline&&activepipleineid!=""){
            navigate("/"+workspace+"/appdesginer/"+type+"/"+templateid+"/details/"+source+"/"+activepipleineid+"/apptemplates");
        }
    },[activepipleineid, newpipeline])

    useEffect(()=>{
        dispatch(templatePipleine(templateid));
    },[])

    const pipelines = useSelector(selectActiveTemplatePipelines);

    const openPipeline = (id)=>{
        dispatch(setactivepipelineid(id));
        dispatch(getPipelinebyId({id:id}));
        setnewpipeline(true);
    }

    const [openDeletePipeline, setOpenDeletePipeline] = useState(false);

    const [pipelineToDelete, setPipelineToDelete] = useState(null);

    const [deletepipelineclicked, setDeletepipelineclicked] = useState(false);
    
    const deletepipelineack = useSelector(selectPipelineDeleted);

    const closePipelineDeleteModal = ()=>{
        setOpenDeletePipeline(false);
        setPipelineToDelete(null);
    }

    const openDeletePipelineModal = (pipeline)=>{
        setPipelineToDelete(pipeline);
        setOpenDeletePipeline(true);
    }

    const deletePipeline = (id)=>{
        dispatch(resetpipelinedeleted());
        setDeletepipelineclicked(true);
        dispatch(deletePipelineById({id:id}))
    }

    useEffect(()=>{
        if(deletepipelineack&&deletepipelineclicked){
            setDeletepipelineclicked(false);
            setOpenDeletePipeline(false);
            setPipelineToDelete(null);
            dispatch(templatePipleine(templateid));
        }
    },[deletepipelineack])

    const modifyVariable = (sc, sm)=>{
        let templatevariablesCopy = {...templateVariable};
        templatevariablesCopy = {...templatevariablesCopy,
                                 schema: sc,
                                 schemamapping: sm
                                }
        dispatch(updateTemplateVariables({
            "templateid": templateid,
            "variable": {
                schema: sc,
                schemamapping: sm,
                depsarray: templatevariablesCopy.depsarray
            }
        }))
    }

    const modifyRouteParams = (sc, sm)=>{
        let routeparamsCopy = {...routeparams};
        routeparamsCopy = {...routeparamsCopy,
                            schema: sc,
                            schemamapping: sm
                          }
        dispatch(updateRouteParams({
            "templateid": templateid,
            "routeparams":{
                schema: sc,
                schemamapping: sm
            }
        }))
    }

    return (
        <div className="code-nav-container">
            <div className="code-nav-section-headers">
                Variable
            </div>
            <div>
                <DeclareVariable
                    schema={templateVariable.schema} 
                    modifySchema={modifyVariable}
                    schemamapping={templateVariable.schemamapping} 
                    pipelinetype={""}
                    stageindex={0}
                    stagetype={"single"}
                    layout={"vertical"}
                    pipelinestage={undefined}
                    source={"appvariables"}
                    sourceid={templateid}
                >
                </DeclareVariable>            
            </div>
            {__source!="templates"&&
                <>
                    <div className="code-nav-section-headers">
                        Route Params
                    </div>
                    <div>
                        <DeclareVariable
                            schema={routeparams.schema} 
                            modifySchema={modifyRouteParams}
                            schemamapping={routeparams.schemamapping} 
                            pipelinetype={""}
                            stageindex={0}
                            stagetype={"single"}
                            layout={"vertical"}
                            pipelinestage={undefined}
                            source={"appvariables"}
                            sourceid={templateid}
                        >
                        </DeclareVariable>
                    </div>   
                </>
            }
            {jsEnabled&&
                <>
                    <div className="code-nav-section-headers">
                        Functions
                    </div>
                    <div>
                        {pipelines.map((pipeline)=>{
                            return(
                                <div style={{display: "flex", padding: 5, alignItems: "center", justifyContent:"space-between"}}>
                                    <div style={{cursor: "pointer"}} onClick={()=>{openPipeline(pipeline._id)}}>
                                        {pipeline.name}
                                    </div>
                                    <div>
                                        <IconButton onClick={()=>{openDeletePipelineModal(pipeline)}}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        })

                        }
                    </div>
                    <div className="code-function-add">
                        <Button style={{color: "#3A07CD"}} onClick={()=>{addPipeline()}}>Add</Button>
                    </div>
                </>
            }
            <FunctionDeleteModal
                open={openDeletePipeline}
                handleClose={closePipelineDeleteModal}
                deleteFunction={deletePipeline}
                func={pipelineToDelete}
            >
            </FunctionDeleteModal>
            
        </div>
    )
}


export default CodeNavContainer;