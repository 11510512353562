import WebIcon from "../Images/Web-Icon.png";
import Scheduler from "../Images/scheduler.png";
import { baseURL } from "../env";



// stages to be 2 type
// input and stack and output
// loops to have only inputschema and demo values
// stack to have inputschema and expected value
// output to have inputschema
//character limit on string , never to be increased
export const conditionalActions = [
    {
        "type": "string",
        "operators":[
            {   
                "name": "Contains", 
                "value": "strcontains", 
                "reqType": "string,array"
            },
            {
                "name": "Does not contains", 
                "value": "strdoes_not_contains", 
                "reqType": "string,array"
            },
            {
                "name": "Equals", 
                "value": "eq", 
                "reqType": "string"
            },
            {"name": "Does not equals", "value": "neq", "reqType": "string"},
            {"name": "Is in", "value": "str_is_in", "reqType": "string,array"},
            {"name": "Is not in", "value": "str_is_not_in", "reqType": "string, array"},
            {"name": "Starts with", "value": "str_starts_with", "reqType": "string"},
            {"name": "Does not Starts with", "value": "str_does_not_starts_with", "reqType": "string"},
            {"name": "Ends with", "value": "str_ends_with", "reqType": "string"},
            {"name": "Does not ends with", "value": "str_does_not_ends_with", "reqType": "string"},
            {"name": "Exists", "value": "exists", "reqType": "none"},
            {"name": "Does not exists", "value": "does_not_exists", "reqType": "none"}
        ]
    },
    {
        "type": "number",
        "operators":[
            {"name": "Equals", "value": "eq", "reqType": "number"},
            {"name": "Does not equals", "value": "neq", "reqType": "number"},
            {"name": "Less than", "value": "lt", "reqType": "number"},
            {"name": "Greater than", "value": "gt", "reqType": "number"},
            {"name": "Less than or Equal", "value": "lte", "reqType": "number"},
            {"name": "Greater than or Equal", "value": "gte", "reqType": "number"},
            {"name": "Is in", "value": "is_in", "reqType": "string,array"},
            {"name": "Is not in", "value": "is_not_in", "reqType": "string,array"},
            {"name": "Exists", "value": "exists", "reqType": "none"},
            {"name": "Does not exists", "value": "does_not_exists", "reqType": "none"}
        ]
    },
    {
        "type": "datetime",
        "operators":[
            {"name": "Equals", "value": "eq", "reqType": "datetime"},
            {"name": "Does not equals", "value": "neq", "reqType": "datetime"},
            {"name": "Less than", "value": "lt", "reqType": "datetime"},
            {"name": "Greater than", "value": "gt", "reqType": "datetime"},
            {"name": "Less than or Equal", "value": "lte", "reqType": "datetime"},
            {"name": "Greater than or Equal", "value": "gte", "reqType": "datetime"},
            {"name": "Is in", "value": "is_in", "reqType": "string,array"},
            {"name": "Is not in", "value": "is_not_in", "reqType": "string,array"},
            {"name": "Exists", "value": "exists", "reqType": "none"},
            {"name": "Does not exists", "value": "does_not_exists", "reqType": "none"}
        ]
    },
    {
        "type": "boolean",
        "operators":[
            {"name": "Equals", "value": "eq", "reqType": "boolean"},
            {"name": "Does not equals", "value": "neq", "reqType": "boolean"},
            {"name": "Exists", "value": "exists", "reqType": "boolean"},
            {"name": "Does not exists", "value": "does_not_exists", "reqType": "boolean"},
            {"name": "Is in", "value": "is_in", "reqType": "string,array"},
            {"name": "Is not in", "value": "is_not_in", "reqType": "string,array"}
        ]
    },
    {
        "type": "array",
        "operators":[
            {"name": "Equals", "value": "eq", "reqType": "array"},
            {"name": "Does not equals", "value": "neq", "reqType": "array"},
            {"name": "Contains", "value": "contains", "reqType": "array"},
            {"name": "Does not contains", "value": "does_not_contains", "reqType": "array"},
            {"name": "Is in", "value": "is_in", "reqType": "array"},
            {"name": "Is not in", "value": "is_not_in", "reqType": "array"},
            {"name": "Exists", "value": "exists", "reqType": "none"},
            {"name": "Does not exists", "value": "does_not_exists", "reqType": "none"}
        ]
    },
    {
        "type": "object",
        "operators":[
            {"name": "Equals", "value":"eq", "reqType": "object"},
            {"name": "Does not equals", "value": "neq", "reqType": "pbject"},
            {"name": "Has key", "value": "has_key", "reqType": "string"},
            {"name": "Does not has key", "value": "does_not_has_key", "reqType": "string"},
            {"name": "Exists", "value": "exists", "reqType": "none"},
            {"name": "Does not exists", "value": "does_not_exists", "reqType": "none"}
        ]
    }
]

export const HTTPRequestGetInputSchemaMapping = [
    {
        "app": "",
        "key": "host",
        "mapping": ""
    },
    {
        "app": "",
        "key": "path",
        "mapping": ""
    },
    {
        "app": "",
        "key": "query",
        "mapping": []
    },
    {
        "app":"",
        "key": "headers",
        "mapping":[]
    }
]

// ....
export const HTTPRequestGetInputSchema = [
    {
        "key": "host",
        "label": "Host",
        "help": "Host of the request",
        "type": "enum",
        "optiontype": "http",
        "optionsurl": baseURL+"client/domains/<%=workspace%>/<%=appid%>/",
        "optionstemplate": "",
        "optionsargs":[], 
        "options":[],
        "validationregex":"(^\/)",
        "required": true,
        "fillallowed": true,
        "default": "",
        "guided": false,
        "subschema":[

        ],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "path",
        "label": "Path",
        "help": "Path of the request",
        "type": "string",
        "required": true,
        "fillallowed": true,
        "default": "/",
        "guided": true,
        "guidefinished": false,
        "subschema":[
        ],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "query",
        "label": "Query",
        "help": "Request Query",
        "type": "object",
        "fillallowed": false,
        "required": false,
        "guided": true,
        "guidefinished": false,
        "steps":[],
        "subschema":[
        ],
        "editallowed": true,
        "typechange": false
    },
    {
        "key": "headers",
        "label": "Headers",
        "fillallowed": false,
        "guided": false,
        "guidefinished": false,
        "help": "Request Headers",
        "type": "object",
        "steps":[],
        "subschema":[
        ],
        "editallowed": true,
        "typechange": false
    }
]

export const HttpRequestPostInputSchema = [
    {
        "key": "host",
        "label": "Host",
        "help": "Host of the request",
        "type": "enum",
        "optiontype": "http",
        "optionsurl": baseURL+"client/domains/<%=workspace%>/<%=appid%>/",
        "optionsarg":[],
        "optionstemplate":"",
        "options":[{"name": "test", "value": "test"},{"name": "test1", "value":"test1"}],
        "validationregex":"(^\/)",
        "required": true,
        "fillallowed": true,
        "default": "",
        "guided": false,
        "subschema":[

        ],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "path",
        "label": "Path",
        "help": "Path of the request",
        "type": "string",
        "required": true,
        "fillallowed": true,
        "default": "/",
        "guided": true,
        "guidefinished": false,
        "subschema":[
        ],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "query",
        "label": "Query",
        "help": "Request Query",
        "type": "object",
        "fillallowed": false,
        "required": false,
        "guided": true,
        "guidefinished": false,
        "steps":[],
        "subschema":[
        ],
        "editallowed": true,
        "typechange": false
    },
    {
        "key": "body",
        "label": "Body",
        "help": "Request body",
        "type": "object",
        "fillallowed": false,
        "required": false,
        "guided": true,
        "guidefinished": false,
        "steps":[],
        "subschema":[
        ],
        "editallowed": true,
        "typechange": true
    },
    {
        "key": "headers",
        "label": "Headers",
        "fillallowed": false,
        "guided": false,
        "guidefinished": false,
        "help": "Request Headers",
        "type": "object",
        "steps":[],
        "subschema":[
        ],
        "editallowed": true,
        "typechange": false
    }
]

export const HttpRequestPostInputSchemaMapping = [
    {
        "app": "",
        "key": "host",
        "mapping": ""
    },
    {
        "app": "",
        "key": "path",
        "mapping": ""
    },
    {
        "app": "",
        "key": "query",
        "mapping": []
    },
    {
        "app":"",
        "key": "body",
        "mapping":[]
    },
    {
        "app":"",
        "key": "headers",
        "mapping":[]
    }
]

export const ScheduleFunctionSchema = [
    {
        "key": "app",
        "label": "App",
        "help": "Select application",
        "type": "enum",
        "optiontype": "http",
        "optionsurl": baseURL+"scheduler/apps/",
        "optionstemplate": "",
        "optionstemplaterendered": false,
        "optionargs":[],
        "options": [],
        "validationregex": "",
        "required": true,
        "fillallowed": true,
        "default": "",
        "guided": true,
        "guidefinished": false,
        "subschema":[],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "schema",
        "label": "Schema",
        "help": "Select schema",
        "type": "enum",
        "optiontype": "http",
        "optionsurl": "",
        "optionstemplate": baseURL+"scheduler/apps/<%=appid%>",
        "optiontemplaterendered": false,
        "optionargs":[{
            "key": "appid",
            "indexarr": [0]
        }],
        "options":[],
        "validationregex": "",
        "required": true,
        "fillallowed": true,
        "default": "",
        "guided": true,
        "guidefinished": false,
        "subschema":[],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "pipeline",
        "label": "Pipeline",
        "help": "Select pipeline",
        "type": "enum",
        "optiontype": "http",
        "optionsurl": "",
        "optionstemplate": baseURL+"scheduler/pipelines/<%=schemaid=%>",
        "optionsarg":[
            {
                "key": "schemaid",
                "indexarr": [1]
            }
        ],
        "optiontemplaterendered": false,
        "options":[],
        "validationregex": "",
        "required": true,
        "fillallowed": true,
        "default": "",
        "guided": true,
        "guidefinished": false,
        "subschema":[

        ],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "pipelineargs",
        "label": "Pipeline Args",
        "help": "Select pipeline args",
        "type": "http",
        "remoteurl": "",
        "remotetemplate": baseURL+"scheduler/pipelines/<%=pipelineid=%>",
        "remoteurlargs":[
            {
                "key": "pipelineid",
                "indexarr":[2]
            }
        ],
        "remotetemplaterendered": false,
        "subschema":{

        },
        "editallowed": false,
        "typechange": false,
        "required": true,
        "fillallowed": false,
        "guided": false,
        "guidefinished": true
    }
]

const HTTPClientGetInputSchema = [
    {
        "key": "url",
        "label": "URL",
        "help": "URL of the request",
        "type": "string",
        "validationregex":"(^\/)",
        "required": true,
        "fillallowed": true,
        "default": "",
        "guided": false,
        "guidefinished": true,
        "subschema":[

        ],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "urlparams",
        "label": "Url Params",
        "help": "Url params for the rquest",
        "type": "array",
        "required":false,
        "fillallowed": true,
        "guided": true,
        "guidefinished": true,
        "subschema":[
            {
                "type": "string"
            }
        ],
        "editallowed": false,
        "typechange": false 
    },
    {
        "key": "query",
        "label": "Query",
        "help": "Query of the request",
        "type": "object",
        "required": false,
        "fillallowed": false,
        "guided": true,
        "guidefinished": false,
        "subschema":[

        ],
        "editallowed": true,
        "typechange": false
    },
    {
        "key": "headers",
        "label": "Headers",
        "help": "Headers of the request",
        "type": "object",
        "required": false,
        "fillallowed": false,
        "guided": false,
        "guidefinished": false,
        "subschema":[],
        "editallowed": true,
        "typechange": false
    }


]

const HTTPClientGetInputSchemaMapping = [
    {
        "key": "url",
        "mapping":""
    },
    {
        "key": "urlparams",
        "mapping":""
    },
    { 
        "key": "query",
        "mapping": ""
    },
    {
        "key": "headers",
        "mapping": ""
    }
]

const HTTPClientPostInputSchema = [
    {
        "key": "url",
        "label": "URL",
        "help": "URL of the request",
        "type": "string",
        "validationregex":"(^\/)",
        "required": true,
        "fillallowed": true,
        "default": "",
        "guided": false,
        "guidefinished": true,
        "subschema":[

        ],
        "editallowed": false,
        "typechange": false
    },
    {
        "key": "urlparams",
        "label": "Url Params",
        "help": "Url params for the rquest",
        "type": "array",
        "required":false,
        "fillallowed": true,
        "guided": true,
        "guidefinished": false,
        "subschema":[
            {
                "type": "string"
            }
        ],
        "editallowed": false,
        "typechange": false 
    },
    {
        "key": "query",
        "label": "Query",
        "help": "Query of the request",
        "type": "object",
        "required": false,
        "fillallowed": false,
        "guided": true,
        "guidefinished": false,
        "subschema":[

        ],
        "editallowed": true,
        "typechange": false
    },
    {
        "key": "body",
        "label": "Body",
        "help": "Body of the request",
        "type": "object",
        "required": false,
        "fillallowed": false,
        "guided": true,
        "guidefinished": false,
        "subschema":[

        ],
        "editallowed": true,
        "typechange": true
    },
    {
        "key": "headers",
        "label": "Headers",
        "help": "Headers of the request",
        "type": "object",
        "required": false,
        "fillallowed": false,
        "guided": false,
        "guidefinished": false,
        "subschema":[

        ],
        "editallowed": true,
        "typechange": false
    }
]

const HTTPClientPostInputSchemaMapping = [
    {
        "key": "url",
        "mapping":""
    },
    {
        "key":"urlparams",
        "mapping":""
    },
    {
        "key": "query",
        "mapping":""
    },
    {
        "key": "body",
        "mapping":""
    },
    {   
        "key": "headers",
        "mapping":""
    }
]

export const QUERYSCHEMA = [
    {
        "key":"query",
        "label": "Query",
        "required": false,
        "subschema":[],
        "type": "text"
    },
    {
        "key": "values",
        "label": "Values",
        "required": false,
        "subschema":[],
        "type":"object"
    },
    {
        "key": "querysettings",
        "label": "Query Settings",
        "required": false,
        "type": "object",
        "subschema":[]
    },
]

export const QUERYSCHEMAMAPPING = [
    {
        "key": "query",
        "mapping": ""
    },
    {
        "key": "values",
        "mapping":[]
    },
    {
        "key": "querysettings",
        "mapping":{"action": "const", "val":[]}
    },
    ]

// export const availableLoopsResponse = [
//     {
//         "app": "http_server",
//         "name": "HTTP Server",
//         "type": "system",
//         "logo": WebIcon,
//         "actions":[
//             {
//                 "name": "json",
//                 "id": "json",
//                 "inputschema":[{
//                     "key": "output",
//                     "label": "Output",
//                     "help": "Output the request",
//                     "type": "object",
//                     "fillallowed": true,
//                     "guided": true,
//                     "guidefinished": false,
//                     "subschema":[],
//                     "editallowed": true,
//                     "typechange": true
//                 }],
//                 "inputschemamapping":[{
//                     "key": "output",
//                     "mapping": ""
//                 }],
//                 "return": true,
//                 "last": false
//             },
//             {
//                 "name": "file",
//                 "id": "file",
//                 "inputschema":[
//                     {
//                         "key": "file",
//                         "label": "File",
//                         "help": "File to send",
//                         "type":"file",
//                         "fillallowed": true,
//                         "guided": false,
//                         "guidefinished":false,
//                         "subschema":[]
//                     }
//                 ],
//                 "inputschemamapping":[{
//                     "key": "file",
//                     "mapping": ""
//                 }],
//                 "return": true,
//                 "last": false
//             },
//             {
//                 "name": "redirect",
//                 "id": "redirect",
//                 "inputschema":[{
//                     "key": "url",
//                     "label": "Url",
//                     "help": "Url to redirect to, relative urls will be redirect relative to the input host if starts with / and to the inputpath with starts without the slash",
//                     ""
//                 }],
//                 "inputschemamapping":[]
//             }
//         ]
//     }
// ];

export const availableLoops = [
    {   
        "app": "http_server",
        "name": "HTTP Server",
        "type": "system",
        "logo": WebIcon,
        "actions":[
            {
                "name": "GET",
                "id": "GET",
                "outputschema": [],
                "outputschemamapping": [], 
                "inputschema": [...HTTPRequestGetInputSchema],
                "inputschemamapping": [...HTTPRequestGetInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[
                ]
            },
            {
                "name": "POST",
                "id": "POST",
                "outputschema": [],
                "outputschemamapping": [],
                "inputschema": [...HttpRequestPostInputSchema],
                "inputschemamapping": [...HttpRequestPostInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "PUT",
                "id": "PUT",
                "outputschema": [],
                "outputschemamapping": [],
                "inputschema": [...HttpRequestPostInputSchema],
                "inputschemamapping": [...HttpRequestPostInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "DELETE",
                "id": "DELETE",
                "outputschema": [],
                "outputschemamapping": [],
                "inputschema": [...HTTPRequestGetInputSchema],
                "inputschemamapping": [...HTTPRequestGetInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[
                ]
            }
        ]
    },
    {
        "app": "scheduler",
        "name": "Scheduler",
        "logo": Scheduler,
        "type": "system",
        "actions":[
            {
                "name": "Poll",
                "id": "poll",
                "inputschema":[
                    {
                        "key": "interval",
                        "label": "Interval Value",
                        "help": "Polling Interval in seconds",
                        "type": "integer",
                        "fillallowed": true,
                        "guided": true,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "data",
                        "label": "Data",
                        "help": "Additional data required for pipeline",
                        "type": "object",
                        "fillallowed": true,
                        "guided": true,
                        "guidefinished": false,
                        "subschema":[],
                    }
                ],
                "inputschemamapping":[
                    {
                        "app": "scheduler",
                        "key": "interval",
                        "mapping": {"action": "const", "val": 300}
                    },
                    {
                        "app": "",
                        "key": "data",
                        "mapping":""
                    }
                ],
                "outputschema":[],
                "outputschemamapping":[],
                "last": false,
                "subactions":[]
            }
        ],
    },
    {
        "app": "manual_trigger",
        "name": "Function",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "name": "Trigger",
                "id": "trigger",
                "inputschema":[
                    {
                        "key": "input",
                        "label":"Input",
                        "help": "Input of the trigger",
                        "type":"object",
                        "fillallowed": true,
                        "guided": true,
                        "guidefinished": false,
                        "subschema":[],
                        "editallowed": true,
                        "typechange": false
                    }

                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            }
        ]
    }
];

// subactions for relations
export const availableSchemaCommonActions = [
    {
        "name": "CREATE",
        "id": "CREATE",
        "inputschema":[],
        "inputschemamapping":[],
        "outputschema":[],
        "outputschemamapping":[],
        "subactions":[]
    },
    {
        "name": "UPDATE",
        "id": "UPDATE",
        "inputschema":[],
        "inputschemamapping":[],
        "outputschema":[],
        "outputschemamapping":[],
        "subactions":[]
    },
    {
        "name": "QUERY",
        "id": "QUERY",
        "inputschema":[],
        "inputschemamapping":[],
        "outschema":[],
        "outputschemamapping":[],
        "subactions":[]
    },
    {
        "name": "DELETE",
        "id": "DELETE",
        "inputschema":[],
        "inputschemamapping":[],
        "outschema":[],
        "outputschemamapping":[],
        "subactions":[]
    },
    {
        "name": "REGISTER_EVENT",
        "id": "REGISTER_EVENT",
        "inputschema":[],
        "inputschemamapping":[],
        "outputschema":[],
        "outputschemamapping":[],
        "subactions":[]
    },
    {
        "name": "SCHEDULE",
        "id": "SCHEDULE",
        "inputschema":[
                    {
                        "key": "type",
                        "label":"Type",
                        "help": "Type of scheduling",
                        "type": "enum",
                        "options":[
                                    {"name": "date", "value": "date"}, {"name": "interval", "value": "interval"}
                                ],
                        "required": true,
                        "fillallowed": true,
                        "default": "",
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[

                        ],
                        "editallowed": false,
                        "typechanged": false
                    },
                    {
                        "key":"input",
                        "label":"Input",
                        "type": "conditional",
                        "help": "Input params for scheduling",
                        "fillallowed": true,
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "condition":{"valindex":0,"condition":"=","val":"date"},
                        "success":{
                            "key": "date",
                            "label": "Date",
                            "type": "datetime",
                            "help": "Timestamp of execution",
                            "required": true,
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema":[]
                        },
                        "error":{
                            "key": "interval",
                            "label": "Interval",
                            "help": "Interval of delay in seconds",
                            "type": "integer",
                            "required": true,
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema":[]
                        }
                    },
                    {
                        "key": "pipelineargs",
                        "label": "Pipeline Args",
                        "type": "object",
                        "help": "Arguments of pipelines",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "required": false
                    }

        ],
        "inputschemamapping":[
            {
                "key": "type",
                "mapping":""
            },
            {
                "key": "input",
                "mapping":""
            },
            {
                "key": "pipelineargs",
                "mapping": ""
            }
        ],
        "outputschema":[],
        "outschemamapping":[]
    }
]

export const availableSchemaCommonReadActions = [
    {
        "name": "QUERY",
        "id": "QUERY",
        "inputschema":[],
        "inputschemamapping":[],
        "outschema":[],
        "outputschemamapping":[],
        "subactions":[]
    },
    {
        "name": "REGISTER_EVENT",
        "id": "REGISTER_EVENT",
        "inputschema":[],
        "inputschemamapping":[],
        "outputschema":[],
        "outputschemamapping":[],
        "subactions":[]
    },
    {
        "name": "SCHEDULE",
        "id": "SCHEDULE",
        "inputschema":[
                    {
                        "key": "type",
                        "label":"Type",
                        "help": "Type of scheduling",
                        "type": "enum",
                        "options":[
                                    {"name": "date", "value": "date"}, {"name": "interval", "value": "interval"}
                                ],
                        "required": true,
                        "fillallowed": true,
                        "default": "",
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[

                        ],
                        "editallowed": false,
                        "typechanged": false
                    },
                    {
                        "key":"input",
                        "label":"Input",
                        "type": "conditional",
                        "help": "Input params for scheduling",
                        "fillallowed": true,
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "condition":{"valindex":0,"condition":"=","val":"date"},
                        "success":{
                            "key": "date",
                            "label": "Date",
                            "type": "datetime",
                            "help": "Timestamp of execution",
                            "required": true,
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema":[]
                        },
                        "error":{
                            "key": "interval",
                            "label": "Interval",
                            "help": "Interval of delay in seconds",
                            "type": "integer",
                            "required": true,
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema":[]
                        }
                    },
                    {
                        "key": "pipelineargs",
                        "label": "Pipeline Args",
                        "type": "object",
                        "help": "Arguments of pipelines",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "required": false
                    }

        ],
        "inputschemamapping":[
            {
                "key": "type",
                "mapping":""
            },
            {
                "key": "input",
                "mapping":""
            },
            {
                "key": "pipelineargs",
                "mapping": ""
            }
        ],
        "outputschema":[],
        "outschemamapping":[]
    }
]

export const availableSchemaCommonActionsName = [
    "CREATE",
    "QUERY",
    "UPDATE",
    "DELETE",
    "REGISTER"
];

export const availableResponses = [
    "pipeline_response", 
    "http_response_json",
    "http_response_file",
    "http_response_redirect",
    "http_response_error"

];

export const availableApps = [
    {   
        "app": "http_client",
        "name": "HTTP Client",
        "type": "system",
        "logo": WebIcon,
        "actions":[
            {
                "name": "GET",
                "id": "GET",
                "outputschema": [],
                "outputschemamapping": [], 
                "inputschema": [...HTTPClientGetInputSchema],
                "inputschemamapping": [...HTTPClientGetInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "POST",
                "id": "POST",
                "outputschema": [],
                "outputschemamapping": [],
                "inputschema": [...HTTPClientPostInputSchema],
                "inputschemamapping": [...HTTPClientPostInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "PUT",
                "id": "PUT",
                "outputschema": [],
                "outputschemamapping": [],
                "inputschema": [...HTTPClientPostInputSchema],
                "inputschemamapping": [...HTTPClientPostInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "DELETE",
                "id": "DELETE",
                "inputschema": [...HTTPClientGetInputSchema],
                "inputschemamapping": [...HTTPClientGetInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "DOWNLOAD FILE",
                "inputschema":[
                    {
                        "key": "url",
                        "label": "URL",
                        "help": "URL of the file",
                        "type": "string",
                        "required": true,
                        "subschema":[]
                    },
                    {
                        "key": "size",
                        "label": "Size",
                        "help": "Size of the file",
                        "type": "number",
                        "required": false,
                        "subschema":[]
                    },
                    {
                        "key":"mimetype",
                        "label": "Mime Type",
                        "help": "Mime type of the file",
                        "type": "string",
                        "required": false,
                        "subschema":[]
                    }
                ]
            }
        ]
    },
    {
        "app": "scheduler",
        "name": "Scheduler",
        "logo": WebIcon,
        "type": "system",
        "actions":[
            {
                "id": "DELAY",
                "name": "DELAY",
                "outputschema":[],
                "outschemamapping":[],
                "inputschema":[
                    {
                        "key": "type",
                        "label": "Type",
                        "type": "enum",
                        "help":"Type of the scheduling",
                        "options":[{"name": "date", "value": "date"}, {"name": "interval", "value": "interval"}],
                        "required": true,
                        "fillallowed": true,
                        "guided": true,
                        "guidefinished": false,
                        "subschema":[]
                    },
                    {
                        "key":"input",
                        "label":"Input",
                        "type": "conditional",
                        "help": "Input params for scheduling",
                        "fillallowed": false,
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "condition":{"valindex":0,"condition":"=","val":"date"},
                        "success":{
                            "key": "date",
                            "label": "Date",
                            "type": "datetime",
                            "help": "Timestamp of execution",
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema":[]
                        },
                        "error":{
                            "key": "interval",
                            "label": "Interval",
                            "help": "Interval of delay in seconds",
                            "type": "integer",
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema":[]
                        }
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "type",
                        "mapping":""
                    },
                    {
                        "key": "input",
                        "mapping":""
                    }
                ]
            },
            {
                "id": "DESCHEDULE",
                "name": "DESCHEDULE",
                "outputschema":[],
                "outputschemamapping":[],
                "inputschema":[
                    {
                        "key": "jobid",
                        "label": "JobId",
                        "type": "string",
                        "help": "Id of the job to be descheduled",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": false,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "jobid",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "files",
        "name": "Files",
        "logo": Scheduler,
        "type": "system",
        "actions":[
            {
                "id": "READ",
                "name": "Read",
                "outputschema": [],
                "outputschemapping":[],
                "inputschema":[
                    {
                        "key": "file",
                        "label": "File",
                        "type": "file",
                        "help": "File to be read",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": []
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "file",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"READLINES",
                "name": "ReadLines",
                "outputschema":[],
                "outputschemapping":[],
                "inputschema":[
                    {
                        "key": "file",
                        "label": "File",
                        "type": "file",
                        "help": "File to be read",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "file",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "WRITE",
                "name": "Write",
                "outputschema":[],
                "outputschemamapping":[],
                "inputschema":[
                    {
                        "key": "file",
                        "label": "File",
                        "type": "file",
                        "help": "File to write",
                        "required":true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "buffer",
                        "label": "Buffer",
                        "type": "text",
                        "help": "Buffer to write",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "file",
                        "mapping": ""
                    },
                    {
                        "key": "buffer",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "WRITELINES",
                "name": "WriteLines",
                "outputschema":[],
                "outputschemapping":[],
                "inputschema":[
                    {
                        "key": "file",
                        "label": "File",
                        "type": "file",
                        "help": "File to write",
                        "required":true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                    },
                    {
                        "key": "lines",
                        "label": "Lines",
                        "type": "array",
                        "help": "Lines to write",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[{
                            "type": "string"
                        }]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "file",
                        "mapping": ""
                    },
                    {
                        "key": "lines",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "DELETEFILE",
                "name": "DeleteFile",
                "outputschema":[],
                "inputschema":[
                    {
                        "key": "file",
                        "label": "File",
                        "type": "file",
                        "help": "File to delete",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "createallowed": false
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "file",
                        "mapping": ""
                    }
                ]

            },
            {
                "id": "COPYFILE",
                "name": "CopyFile",
                "outputschema":[],
                "inputschema":[
                    {
                        "key": "file",
                        "label": "File",
                        "type": "file",
                        "help": "File to copy",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "createallowed": false
                    },
                    {
                        "key": "dest",
                        "label": "Destination",
                        "type": "enum",
                        "help": "Folder to copy file to",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "optiontype": "http",
                        "optionsurl": baseURL+"filesystem/folderoptions/<%=workspace%>",
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "file",
                        "mapping": ""
                    },
                    {
                        "key": "dest",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "MOVEFILE",
                "name": "MoveFile",
                "outputschema":[],
                "inputschema":[
                    {
                        "key": "file",
                        "label": "File",
                        "type": "file",
                        "help": "File to move",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "createallowed": false
                    },
                    {
                        "key": "dest",
                        "label": "Destination",
                        "type": "enum",
                        "help": "Folder to move file to",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "optiontype": "http",
                        "optionsurl": baseURL+"filesystem/folderoptions/<%=workspace%>",
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "file",
                        "mapping": ""
                    },
                    {
                        "key": "dest",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "json",
        "name": "JSON",
        "logo": Scheduler,
        "type": "system",
        "actions":[
            {
                "id": "parse",
                "name": "Parse",
                "inputschema":[
                    {
                        "key": "payload",
                        "label": "Payload",
                        "type": "string",
                        "help": "String to parse",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "payload",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "stringify",
                "name": "Stringify",
                "inputschema":[
                    {
                        "key": "payload",
                        "label": "Payload",
                        "type": "any",
                        "help": "Object to stringify",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "payload",
                        "mapping":[]
                    }
                ]
            }
        ]
    },
    {
        "app": "regex",
        "name": "Regex",
        "logo": Scheduler,
        "type": "system",
        "actions":[
            {
                "id": "exec",
                "name": "Exec",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "test",
                "name": "Test",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "match",
                "name": "Match",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "matchall",
                "name": "MatchAll",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "search",
                "name": "Search",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "replace",
                "name": "Replace",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "replacement",
                        "label": "Replacement",
                        "type": "string",
                        "help": "String to replace match with",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "replacement",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "replaceall",
                "name": "ReplaceAll",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "replacement",
                        "label": "Replacement",
                        "type": "string",
                        "help": "String to replace match with",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "replacement",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "transform",
        "name": "DataTypes",
        "logo": Scheduler,
        "type": "transform",
        "actions":[]
    },
    {
        "app": "jwt",
        "name": "Jwt",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "sign",
                "name": "Sign",
                "inputschema":[
                    {
                        "key": "payload",
                        "label": "Payload",
                        "type": "object",
                        "help":"Payload to be signed",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[
                            {
                                "key": "data",
                                "label": "Data",
                                "type": "any",
                                "help": "Data to be signed",
                                "required": true,
                                "guided": false,
                                "guidefinshed": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "exp",
                                "label": "Expires In",
                                "help": "Token expiry timestamp",
                                "type": "integer",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "iat",
                                "label": "Issued At",
                                "help": "Token issued at timestamp",
                                "type": "integer",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "nbf",
                                "label": "Notbefore",
                                "help": "Token valid not before",
                                "type": "integer",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "aud",
                                "label": "Auidence",
                                "help": "String to verify auidence",
                                "type": "string",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "sub",
                                "label": "Subject",
                                "help": "Subject claim of the token",
                                "type": "string",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            }
                        ]
                    },
                    {
                        "key": "key",
                        "label": "Key",
                        "type": "text",
                        "help": "Private key to be used",
                        "required": true,
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "payload",
                        "mapping": [
                            {
                                "key": "data",
                                "mapping": ""
                            },
                            {
                                "key": "exp",
                                "mapping": ""
                            },
                            {
                                "key": "iat",
                                "mapping": ""
                            },
                            {
                                "key": "nbf",
                                "mapping": ""
                            },
                            {
                                "key": "aud",
                                "mapping": ""
                            },
                            {
                                "key": "sub",
                                "mapping": ""
                            }

                        ]
                    },
                    {
                        "key": "key",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "verify",
                "name": "Verify",
                "inputschema":[
                    {
                        "key": "token",
                        "label": "Token",
                        "type": "string",
                        "help": "Token to be verified",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "key",
                        "label": "Key",
                        "type": "text",
                        "help": "Private to be used",
                        "required": true,
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "token",
                        "mapping": ""
                    },
                    {
                        "key": "key",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    // {
    //     "app": "zlib",
    //     "name": "Zlib",
    //     "type": "system",
    //     "logo": Scheduler,
    //     "actions":[
    //         {
    //             "id": "compress",
    //             "name": "Compress",
    //             "inputschema":[
    //                 {
    //                     "key": "file",
    //                     "label": "File",
    //                     "help": "File to compress",
    //                     "type": "file",
    //                     "required": true,
    //                     "guided": false,
    //                     "guidefinshed": true,
    //                     "fillallowed": true,
    //                     "subschema":[]
    //                 }
    //             ],
    //             "inputschemamapping":[
    //                 {
    //                     "key": "file",
    //                     "mapping": ""
    //                 }
    //             ]
    //         },
    //         {
    //             "id": "decompress",
    //             "name": "Decompress",
    //             "inputschema":[
    //                 {
    //                     "key": "file",
    //                     "label": "File",
    //                     "help": "File to uncompress",
    //                     "type": "file",
    //                     "required": true,
    //                     "guided": false,
    //                     "guidefinished": true,
    //                     "fillallowed": true,
    //                     "subschema":[]
    //                 }
    //             ],
    //             "inputschemamapping":[
    //                 {
    //                     "key": "file",
    //                     "mapping":[]
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        "app": "math",
        "name": "Math",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "abs",
                "name": "Abs",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "acos",
                "name": "acos",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "acosh",
                "name": "acosh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "asin",
                "name": "asin",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "asinh",
                "name": "asinh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "atan",
                "name": "atan",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "atan2",
                "name": "atan2",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "atanh",
                "name": "atanh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"cbrt",
                "name": "cbrt",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "ceil",
                "name": "ceil",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "clz32",
                "name": "clz32",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "cos",
                "name": "cos",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"cosh",
                "name": "cosh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "exp",
                "name": "exp",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "expm1",
                "name": "expm1",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "floor",
                "name": "floor",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]                
            },
            {
                "id": "fround",
                "name": "fround",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "hypot",
                "name": "hypot",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "array",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[{
                            "type": "number"
                        }]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log",
                "name": "log",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log10",
                "name": "log10",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log1p",
                "name": "log1p",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log2",
                "name": "log2",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"max",
                "name": "max",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "array",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[{
                            "type": "number"
                        }]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"min",
                "name": "min",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "array",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[{
                            "type": "number"
                        }]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "pow",
                "name": "pow",
                "inputschema":[
                    {
                        "key": "base",
                        "label":"Base",
                        "help": "Base",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "exponent",
                        "label": "Exponent",
                        "help": "Exponent",
                        "type": "integer",
                        "required": true,
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "base",
                        "mapping": ""
                    },
                    {
                        "key": "exponent",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "random",
                "name": "Random",
                "inputschema":[
                    {
                        "key": "min",
                        "label": "Min",
                        "help": "Minimum number of the range",
                        "type": "number",
                        "fillallowed": true,
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "max",
                        "label": "Max",
                        "help": "Maximum number of the range",
                        "type": "number",
                        "fillallowed": true,
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "min",
                        "mapping": ""
                    },
                    {
                        "key": "max",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "round",
                "name": "Round",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sign",
                "name": "sign",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sin",
                "name": "sin",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sinh",
                "name": "sinh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sqrt",
                "name": "sqrt",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "tan",
                "name": "tan",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "tanh",
                "name": "tanh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "trunc",
                "name": "trunc",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "block_controls",
        "name": "Block Controls",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "continue",
                "name": "Continue",
                "inputschema":[],
                "inputschemampping":[],
                "outputschema":[]
            },
            {
                "id": "break",
                "name": "Break",
                "inputschema":[],
                "inputschemamapping":[],
                "outputschema":[]
            }
        ]

    },
    {
        "app": "mail",
        "name": "Mail",
        "type": "system",
        "logo": Scheduler,
        "actions":[{
            "id": "send",
            "name": "send",
            "inputschema":[
                {
                    "label": "Host",
                    "key": "host",
                    "required": true,
                    "type": "string",
                    "fillallowed": true,
                    "guided": false,
                    "guidefinished": true,
                    "subschema": []
                },
                {
                    "label": "Port",
                    "key": "port",
                    "required": true,
                    "type": "number",
                    "fillallowed": true,
                    "guided": false,
                    "guidefinished": true,
                    "subschema": []
                },
                {
                    "label": "Secure",
                    "key": "secure",
                    "required": true,
                    "type": "boolean",
                    "fillallowed": true,
                    "guided": false,
                    "guidefinished": true,
                    "subschema": []
                },
                {
                    "label": "Auth",
                    "key": "auth",
                    "required": true,
                    "type": "object",
                    "fillallowed": true,
                    "guided": false,
                    "guidefinished": true,
                    "subschema":[
                        {
                            "label": "Username",
                            "key": "username",
                            "required": true,
                            "type": "string",
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema": []
                        },
                        {
                            "label": "Password",
                            "key": "password",
                            "required": true,
                            "type": "string",
                            "fillallowed": true,
                            "guided": false,
                            "guidefinished": true,
                            "subschema": []
                        }
                    ]
                },
                {
                    "label": "Fromemail",
                    "key": "fromemail",
                    "required": true,
                    "type": "string",
                    "fillallowed": true,
                    "guided": false,
                    "guidefinished": true,
                    "subschema": []
                },
                {
                    "label": "toemail",
                    "key": "toemail",
                    "required": true,
                    "type": "array",
                    "guided": false,
                    "fillallowed": true,
                    "guidefinished": true,
                    "subschema": [
                        {
                            "type": "string"
                        }
                    ]
                },
                {
                    "label": "subject",
                    "key": "subject",
                    "required": true,
                    "type": "string",
                    "guided": false,
                    "fillallowed": true,
                    "guidefinished": true,
                    "subschema": []
                },
                {
                    "label": "Body",
                    "key": "body",
                    "required": true,
                    "type": "text",
                    "fillallowed": true,
                    "guided": false,
                    "guidefinished": true,
                    "subschema": []
                }
            ],
            "inputschemamapping":[
                {
                    "key": "host",
                    "mapping": ""
                },
                {
                    "key": "port",
                    "mapping": ""
                },
                {
                    "key": "secure",
                    "mapping": ""
                },
                {
                    "key": "auth",
                    "mapping": [
                        {
                            "key": "username",
                            "mapping": ""
                        },
                        {
                            "key": "password",
                            "mapping": ""
                        }
                    ]
                },
                {
                    "key": "fromemail",
                    "mapping": ""
                },
                {
                    "key": "toemail",
                    "mapping": ""
                },
                {
                    "key": "subject",
                    "mapping": ""
                },
                {
                    "key": "body",
                    "mapping": ""
                }
            ]
        }]
    },      
    {
        "app": "interface",
        "name": "Interface",
        "type": "interface",
        "logo": Scheduler,
        "actions":[
            {
                "id": "render",
                "name": "Render",
                "inputschema":[],
                "inputschemamapping":[]
            }
        ]
    },
    {
        "app": "mlmodels",
        "name": "ML Models",
        "type": "mlmodels",
        "logo": Scheduler,
        "actions":[
            {
                "id": "train",
                "name": "Train",
                "inputschema":[],
                "inputschemamapping":[]                
            },
            {
                "id": "test",
                "name": "Test",
                "inputschema":[],
                "inputschemapping":[]
            },
            {
                "id": "save",
                "name": "Save",
                "inputschema":[],
                "inputschemamapping":[]
            },
            {
                "id": "deploy",
                "name": "Deploy",
                "inputschema":[],
                "inputschemapping":[]
            }
        ]
    }
]

export const availableClientLoops = [
    {
        "app": "manual_trigger",
        "name": "Function",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "name": "Trigger",
                "id": "trigger",
                "inputschema":[
                    {
                        "key": "input",
                        "label":"Input",
                        "help": "Input of the trigger",
                        "type":"object",
                        "fillallowed": true,
                        "guided": true,
                        "guidefinished": false,
                        "subschema":[],
                        "editallowed": true,
                        "typechange": false
                    }

                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            }
        ]
    }
]

export const availableClientApps = [
    {   
        "app": "http_client",
        "name": "HTTP Client",
        "type": "system",
        "logo": WebIcon,
        "actions":[
            {
                "name": "GET",
                "id": "GET",
                "outputschema": [],
                "outputschemamapping": [], 
                "inputschema": [...HTTPClientGetInputSchema],
                "inputschemamapping": [...HTTPClientGetInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "POST",
                "id": "POST",
                "outputschema": [],
                "outputschemamapping": [],
                "inputschema": [...HTTPClientPostInputSchema],
                "inputschemamapping": [...HTTPClientPostInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "PUT",
                "id": "PUT",
                "outputschema": [],
                "outputschemamapping": [],
                "inputschema": [...HTTPClientPostInputSchema],
                "inputschemamapping": [...HTTPClientPostInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "DELETE",
                "id": "DELETE",
                "inputschema": [...HTTPClientGetInputSchema],
                "inputschemamapping": [...HTTPClientGetInputSchemaMapping],
                "return": true,
                "last": false,
                "subactions":[]
            },
            {
                "name": "DOWNLOAD FILE",
                "inputschema":[
                    {
                        "key": "url",
                        "label": "URL",
                        "help": "URL of the file",
                        "type": "string",
                        "required": true,
                        "subschema":[]
                    },
                    {
                        "key": "size",
                        "label": "Size",
                        "help": "Size of the file",
                        "type": "number",
                        "required": false,
                        "subschema":[]
                    },
                    {
                        "key":"mimetype",
                        "label": "Mime Type",
                        "help": "Mime type of the file",
                        "type": "string",
                        "required": false,
                        "subschema":[]
                    }
                ]
            }
        ]
    },
    {
        "app": "json",
        "name": "JSON",
        "logo": Scheduler,
        "type": "system",
        "actions":[
            {
                "id": "parse",
                "name": "Parse",
                "inputschema":[
                    {
                        "key": "payload",
                        "label": "Payload",
                        "type": "string",
                        "help": "String to parse",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "payload",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "stringify",
                "name": "Stringify",
                "inputschema":[
                    {
                        "key": "payload",
                        "label": "Payload",
                        "type": "any",
                        "help": "Object to stringify",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "payload",
                        "mapping":[]
                    }
                ]
            }
        ]
    },
    {
        "app": "regex",
        "name": "Regex",
        "logo": Scheduler,
        "type": "system",
        "actions":[
            {
                "id": "exec",
                "name": "Exec",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "test",
                "name": "Test",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "match",
                "name": "Match",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "matchall",
                "name": "MatchAll",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "search",
                "name": "Search",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "replace",
                "name": "Replace",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "replacement",
                        "label": "Replacement",
                        "type": "string",
                        "help": "String to replace match with",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "replacement",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "replaceall",
                "name": "ReplaceAll",
                "inputschema":[
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "type": "string",
                        "help": "Pattern to compile",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]

                    },
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "help": "String to execute",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "replacement",
                        "label": "Replacement",
                        "type": "string",
                        "help": "String to replace match with",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "flags",
                        "label": "Flags",
                        "type": "string",
                        "required": false,
                        "help": "Additional flags to build the regex",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "replacement",
                        "mapping": ""
                    },
                    {
                        "key": "flags",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "transform",
        "name": "DataTypes",
        "logo": Scheduler,
        "type": "transform",
        "actions":[]
    },
    {
        "app": "jwt",
        "name": "Jwt",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "sign",
                "name": "Sign",
                "inputschema":[
                    {
                        "key": "payload",
                        "label": "Payload",
                        "type": "object",
                        "help":"Payload to be signed",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[
                            {
                                "key": "data",
                                "label": "Data",
                                "type": "any",
                                "help": "Data to be signed",
                                "required": true,
                                "guided": false,
                                "guidefinshed": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "exp",
                                "label": "Expires In",
                                "help": "Token expiry timestamp",
                                "type": "integer",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "iat",
                                "label": "Issued At",
                                "help": "Token issued at timestamp",
                                "type": "integer",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "nbf",
                                "label": "Notbefore",
                                "help": "Token valid not before",
                                "type": "integer",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "aud",
                                "label": "Auidence",
                                "help": "String to verify auidence",
                                "type": "string",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            },
                            {
                                "key": "sub",
                                "label": "Subject",
                                "help": "Subject claim of the token",
                                "type": "string",
                                "required": false,
                                "guided": false,
                                "guidefinished": true,
                                "fillallowed": true,
                                "subschema":[]
                            }
                        ]
                    },
                    {
                        "key": "key",
                        "label": "Key",
                        "type": "text",
                        "help": "Private key to be used",
                        "required": true,
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "payload",
                        "mapping": [
                            {
                                "key": "data",
                                "mapping": ""
                            },
                            {
                                "key": "exp",
                                "mapping": ""
                            },
                            {
                                "key": "iat",
                                "mapping": ""
                            },
                            {
                                "key": "nbf",
                                "mapping": ""
                            },
                            {
                                "key": "aud",
                                "mapping": ""
                            },
                            {
                                "key": "sub",
                                "mapping": ""
                            }

                        ]
                    },
                    {
                        "key": "key",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "verify",
                "name": "Verify",
                "inputschema":[
                    {
                        "key": "token",
                        "label": "Token",
                        "type": "string",
                        "help": "Token to be verified",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "key",
                        "label": "Key",
                        "type": "text",
                        "help": "Private to be used",
                        "required": true,
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "token",
                        "mapping": ""
                    },
                    {
                        "key": "key",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "math",
        "name": "Math",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "abs",
                "name": "Abs",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "acos",
                "name": "acos",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "acosh",
                "name": "acosh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "asin",
                "name": "asin",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "asinh",
                "name": "asinh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "atan",
                "name": "atan",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "atan2",
                "name": "atan2",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "atanh",
                "name": "atanh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"cbrt",
                "name": "cbrt",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "ceil",
                "name": "ceil",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "clz32",
                "name": "clz32",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "cos",
                "name": "cos",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"cosh",
                "name": "cosh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "exp",
                "name": "exp",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "expm1",
                "name": "expm1",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "floor",
                "name": "floor",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]                
            },
            {
                "id": "fround",
                "name": "fround",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "hypot",
                "name": "hypot",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "array",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[{
                            "type": "number"
                        }]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log",
                "name": "log",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log10",
                "name": "log10",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log1p",
                "name": "log1p",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "log2",
                "name": "log2",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"max",
                "name": "max",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "array",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[{
                            "type": "number"
                        }]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id":"min",
                "name": "min",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "array",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[{
                            "type": "number"
                        }]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "pow",
                "name": "pow",
                "inputschema":[
                    {
                        "key": "base",
                        "label":"Base",
                        "help": "Base",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "exponent",
                        "label": "Exponent",
                        "help": "Exponent",
                        "type": "integer",
                        "required": true,
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "base",
                        "mapping": ""
                    },
                    {
                        "key": "exponent",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "random",
                "name": "Random",
                "inputschema":[
                    {
                        "key": "min",
                        "label": "Min",
                        "help": "Minimum number of the range",
                        "type": "number",
                        "fillallowed": true,
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "max",
                        "label": "Max",
                        "help": "Maximum number of the range",
                        "type": "number",
                        "fillallowed": true,
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "min",
                        "mapping": ""
                    },
                    {
                        "key": "max",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "round",
                "name": "Round",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sign",
                "name": "sign",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sin",
                "name": "sin",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sinh",
                "name": "sinh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "sqrt",
                "name": "sqrt",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "tan",
                "name": "tan",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "tanh",
                "name": "tanh",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "trunc",
                "name": "trunc",
                "inputschema":[
                    {
                        "key": "input",
                        "label": "Input",
                        "help": "Input",
                        "type": "number",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "input",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "block_controls",
        "name": "Block Controls",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "continue",
                "name": "Continue",
                "inputschema":[],
                "inputschemampping":[],
                "outputschema":[]
            },
            {
                "id": "break",
                "name": "Break",
                "inputschema":[],
                "inputschemamapping":[],
                "outputschema":[]
            }
        ]

    },
    {
        "app": "router",
        "name": "Router",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "navigate",
                "name": "Navigate",
                "inputschema":[
                    {
                        "key": "path",
                        "label": "Path",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "path",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "app": "localstorage",
        "name": "LocalStorage",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "getkey",
                "name": "Getkey",
                "inputschema":[
                    {
                        "key": "key",
                        "label": "Key",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "key",
                        "mapping": ""
                    }
                ]
            },
            {

                "id": "setkey",
                "name": "Setkey",
                "inputschema":[
                    {
                        "key": "key",
                        "label": "Key",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": []
                    },
                    {
                        "key": "value",
                        "label": "Value",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "key",
                        "mapping": ""
                    },
                    {
                        "key": "value",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "removekey",
                "name": "Removekey",
                "inputschema":[
                    {
                        "key": "key",
                        "label": "Key",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "key",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "clear",
                "name": "Clear",
                "inputschema": [
                    
                ],
                "inputschemamapping":[]
            }
        ]
    },
    {
        "app": "window",
        "name": "Window",
        "type": "system",
        "logo": Scheduler,
        "actions":[
            {
                "id": "alert",
                "name": "Alert",
                "inputschema":[
                    {
                        "key": "msg",
                        "label": "Message",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "msg",
                        "mapping": ""
                    }
                ]
            }
        ]
    }

]

/*
    todo: change multiply input to array of numbers;
*/
export const available_transfrom_action = [
    {   
        "type": "all",
        "name": "change_value",
        "id": "change_value",
        "inputschema":[

        ],
    },
    {
        "type": "datetime",
        "actions":[
            {
                "name": "Now",
                "id": "now",
                "inputschema":[

                ],
                "inputschemamapping":[

                ],
                "outputschema":[]
            },
            {
                "name":"IncrementTime",
                "id": "addtime",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "type": "datetime",
                        "required": true,
                        "help": "Datetime to add time to",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]

                    },
                    {
                        "key": "unit",
                        "label": "Unit",
                        "type": "enum",
                        "options":[{"name": "Days", "value": "days"}, {"name": "Hours", "value": "hours"}, {"name": "Minutes", "value": "minutes"},{"name": "Seconds", "value": "seconds"}],
                        "required": true,
                        "help": "Unit of the increment",
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "increment",
                        "label": "Increment",
                        "type": "integer",
                        "required": true,
                        "help": "value of the increment",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "unit",
                        "mapping": ""
                    },
                    {
                        "key": "increment",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name":"DecrementTime",
                "id": "subtracttime",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "type": "datetime",
                        "required": true,
                        "help": "Datetime to add time to",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]

                    },
                    {
                        "key": "unit",
                        "label": "Unit",
                        "type": "enum",
                        "options":[{"name": "Days", "value": "days"}, {"name": "Hours", "value": "hours"}, {"name": "Minutes", "value": "minutes"},{"name": "Seconds", "value": "seconds"}],
                        "required": true,
                        "help": "Unit of the decrement",
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[]
                    },
                    {
                        "key": "decrement",
                        "label": "Decrement",
                        "type": "integer",
                        "required": true,
                        "help": "value of the decrement",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "unit",
                        "mapping": ""
                    },
                    {
                        "key": "decrement",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Parse",
                "id": "parse",
                "inputschema":[
                    {
                        "key": "string",
                        "label": "String",
                        "help": "String to parse",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]

                    },
                    {
                        "key": "format",
                        "label": "Format",
                        "help": "Format of the string",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "format",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Format",
                "id": "format",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to format",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "format",
                        "label": "Format",
                        "help": "Format in which time is required to be formatted",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": false,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "format",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "GetDate",
                "id": "getdate",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get date from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetDay",
                "id": "getday",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get day from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetFullYear",
                "id": "getfullyear",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get fullyear from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetHours",
                "id": "gethours",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get hours from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetMilliSeconds",
                "id": "getmilliseconds",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get milliseconds from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetMinutes",
                "id": "getminutes",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get minutes from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetMonth",
                "id": "getmonth",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get month from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetSeconds",
                "id": "getseconds",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get seconds from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetTime",
                "id": "gettime",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to get time from",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetDate",
                "id": "setdate",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "date",
                        "label": "Date",
                        "help": "Date to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "date",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetFullYear",
                "id": "setfullyear",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "year",
                        "label": "Year",
                        "help": "Year to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed" : true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "year",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetHours",
                "id": "sethours",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "hours",
                        "label": "Hours",
                        "help": "Hours to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "hours",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetMilliSeconds",
                "id": "setmilliseconds",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "milliseconds",
                        "label": "MilliSeconds",
                        "help": "Milliseconds to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "milliseconds",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetMinutes",
                "id": "setminutes",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "minutes",
                        "label": "Minutes",
                        "help": "Minutes to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]

                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "minutes",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetMonth",
                "id": "setmonth",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "month",
                        "label": "Month",
                        "help": "Month to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "month",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetSeconds",
                "id": "setseconds",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "seconds",
                        "label": "Seconds",
                        "help": "Seconds to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "seconds",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "SetTime",
                "id": "settime",
                "inputschema":[
                    {
                        "key": "datetime",
                        "label": "Datetime",
                        "help": "Datetime to modify",
                        "type": "datetime",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "time",
                        "label": "Time",
                        "help": "time to set",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]

                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "datetime",
                        "mapping": ""
                    },
                    {
                        "key": "time",
                        "mapping": ""
                    }
                ]
            }
        ]      
    },
    {
        "type": "string",
        "actions":[
            {
                "name": "Uppercase",
                "id": "uppercase",
                "inputschema":[
                    {
                        "key":"string",
                        "label": "String",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Lowercase",
                "id": "lowercase",
                "inputschema":[
                    {
                        "key":"string",
                        "label": "String",
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Replace",
                "id": "replace",
                "inputschema":[
                    {
                        "key":"string",
                        "label": "String",
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    },
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "help": "",
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    },
                    {
                        "key": "replacement",
                        "label": "Replacement",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "replacement",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "ReplaceAll",
                "id": "replaceall",
                "inputschema":[
                    {
                        "key":"string",
                        "label": "String",
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    },
                    {
                        "key": "pattern",
                        "label": "Pattern",
                        "help": "",
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    },
                    {
                        "key": "replacement",
                        "label": "Replacement",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "pattern",
                        "mapping": ""
                    },
                    {
                        "key": "replacement",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Split",
                "id": "split",
                "inputschema":[
                    {
                        "key": "string",
                        "label": "String",
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "delimiter",
                        "label": "Delimitier",
                        "type": "string",
                        "required": false,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "delimiter",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "concat",
                "name": "Concat",
                "inputschema":[
                    {
                        "key": "strings",
                        "label":"Strings",
                        "help": "Strings to concat",
                        "required": true,
                        "type": "array",
                        "fillallowed": true,
                        "guided": true,
                        "guidefinshed": false,
                        "subschema":[
                            {
                                "type": "string"
                            }
                        ]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "strings",
                        "mapping": ""
                    },
                    {
                        "key": "delimiter",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "endswith",
                "name": "Endswith",
                "inputschema":[
                    {
                        "key": "string",
                        "label": "String",
                        "help": "String to search from",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[] 
                    },
                    {
                        "key": "searchstring",
                        "label": "Search String",
                        "help": "String to search",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "searchstring",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "substring",
                "name": "Substring",
                "inputschema":[
                    {
                        "key": "indexstart",
                        "label": "Index Start",
                        "help": "Index to start splicing",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    },
                    {
                        "key": "indexstop",
                        "label": "Index Stop",
                        "help": "Index to stop slicing",
                        "type": "integer",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "indexstart",
                        "mapping": ""
                    },
                    {
                        "key": "indexstop",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "startswith",
                "name": "Starts with",
                "inputschema":[
                    {
                        "key": "string",
                        "label": "String",
                        "help": "String to search from",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[] 
                    },
                    {
                        "key": "searchstring",
                        "label": "Search String",
                        "help": "String to search",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    },
                    {
                        "key": "searchstring",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "trim",
                "name": "Trim",
                "inputschema":[
                    {
                        "key": "string",
                        "label": "String",
                        "help": "String to trim",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            }
        ]
    },
    {
        "type": "array",
        "actions":[
            {
                "name": "Clone",
                "id": "clone",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "type": "array",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ] 
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "GetElement",
                "id": "getelement",
                "inputschema":[
                    {
                        "key":"array",
                        "label": "Array",
                        "type": "array",
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ],
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "index",
                        "label": "Index",
                        "type": "integer",
                        "subschema":[

                        ],
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping": ""
                    },
                    {
                        "key": "index",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "ChangeElement",
                "id": "changeelement",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "type": "array",
                        "subschema":[{
                            "type": "any"
                        }],
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "index",
                        "label": "Index",
                        "type": "integer",
                        "subschema":[],
                        "required": true, 
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "item",
                        "label": "Item",
                        "type": "string",
                        "subschema":[],
                        "required": true, 
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "editallowed": true,
                        "typechange": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping": ""
                    },
                    {
                        "key": "index",
                        "mapping": ""
                    },
                    {
                        "key": "item",
                        "mapping": ""
                    }
                ]
                
            },
            {
                "name": "Length",
                "id": "length",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "type": "array",
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ],
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "Push",
                "id": "push",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "help": "Array to be modified",
                        "type": "array",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    },
                    {
                        "key": "item",
                        "label": "Item",
                        "help": "Item to append",
                        "type": "any",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "editallowed": true,
                        "typechange": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping": ""
                    },
                    {
                        "key": "item",
                        "mapping": ""
                    }

                ],
                "outputschema":[]
            },
            {
                "name": "Unshift",
                "id": "unshift",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "help": "Array to be modified",
                        "type": "array",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    },
                    {
                        "key": "item",
                        "label": "Item",
                        "help": "Item to append",
                        "type": "any",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "editallowed": true,
                        "typechange": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping": ""
                    },
                    {
                        "key": "item",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "Remove Element",
                "id": "removeelement",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "help": "Array to be modified",
                        "type": "array",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    },
                    {
                        "key": "index",
                        "label": "Index",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping": ""
                    },
                    {
                        "key": "index",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Indexof",
                "id": "indexof",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "help": "Array to be modified",
                        "type": "array",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    },
                    {
                        "key": "of",
                        "label": "Of",
                        "type": "any",
                        "required": true,
                        "subschema": [],
                        "fillallowed": true,
                        "guided": false
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array",
                        "mapping":""
                    },
                    {
                        "key": "of",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Slice",
                "id": "slice",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "help": "Array to be modified",
                        "type": "array",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    },
                    {
                        "key": "start",
                        "label": "Start",
                        "help": "index at which to start extraction",
                        "type": "integer",
                        "required": false,
                        "default": 0,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    },
                    {
                        "key": "end",
                        "label": "End",
                        "help": "index at which to end extraction",
                        "type": "integer",
                        "required": false,
                        "default": 0,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "start",
                        "mapping": ""
                    },
                    {
                        "key": "end",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "splice",
                "name": "Splice",
                "inputschema":[
                    {
                        "key": "array",
                        "label": "Array",
                        "help": "Array to be modified",
                        "type": "array",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    },
                    {
                        "key": "start",
                        "label": "Start",
                        "help": "Index at which to start changing the array",
                        "type": "integer",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "subschema":[]
                    },
                    {
                        "key": "deletecount",
                        "label": "Delete Count",
                        "help": "Number of elements to delete",
                        "type": "integer",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    },
                    {
                        "key": "items",
                        "label": "Items",
                        "help": "Items to be inserted",
                        "type": "array",
                        "required": false,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ],
                        "editallowed": true,
                        "typechange": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "start",
                        "mapping": ""
                    },
                    {
                        "key": "deletecount",
                        "mapping": ""
                    },
                    {
                        "key": "items",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "concat",
                "name": "Concat",
                "inputschema":[
                    {
                        "key": "array1",
                        "label": "Array1",
                        "type": "array",
                        "help": "First array",
                        "required": true,
                        "guided": false,
                        "guidefinished": true,
                        "fillallowed": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    },
                    {
                        "key": "array2",
                        "label": "Array2",
                        "type": "array",
                        "help": "Second array",
                        "required": true,
                        "guided": false,
                        "guidefinshed": true,
                        "fillallowed": true,
                        "subschema":[
                            {
                                "type": "any"
                            }
                        ]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "array1",
                        "mapping": ""
                    },
                    {
                        "key": "array2",
                        "mapping": ""
                    }
                ]
            }
        ]
    },
    {
        "type": "object",
        "actions":[
            {
                "name": "Create",
                "id": "create",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "type": "object",
                        "required": true,
                        "fillallowed": true,
                        "guided": true,
                        "guidefinished": false,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Clone",
                "id": "clone",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "type": "object",
                        "subschema":[],
                        "forcevisible": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    }
                ]
            },
            {
                "name": "Getkey",
                "id": "getkey",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "help": "Object to get keys from",
                        "required": true,
                        "type": "object",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "forcevisible": true
                    },
                    {
                        "key": "key",
                        "label": "Key",
                        "help": "Key to get value of",
                        "type": "string",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    },
                    {
                        "key": "key",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Addkey",
                "id": "addkey",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "help": "Object to modify",
                        "type": "object",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "forcevisible": true 
                    },
                    {
                        "key": "keyname",
                        "label": "Key Name",
                        "help": "Key to add",
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "keyvalue",
                        "label": "Key Value",
                        "help": "Value of the key",
                        "type": "any",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "editallowed": true,
                        "typechange": true

                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    },
                    {
                        "key": "keyname",
                        "mapping": ""
                    },
                    {
                        "key": "keyvalue",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "SetKey",
                "id": "setkey",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "help": "Object to modify",
                        "type": "object",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "forcevisible": true
                    },
                    {
                        "key": "keyname",
                        "label": "Key Name",
                        "type": "string",
                        "help": "Keyname to change value of",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true 
                    },
                    {
                        "key": "keyvalue",
                        "label": "Key Value",
                        "type": "string",
                        "help": "New value of key",
                        "editallowed": true,
                        "typechange": true,        
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    },
                    {
                        "key": "keyname",
                        "mapping": ""
                    },
                    {
                        "key": "keyvalue",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Deletekey",
                "id": "deletekey",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "help": "Object to modify",
                        "type": "object",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true,
                        "forcevisible": true
                    },
                    {
                        "key": "keyname",
                        "label": "Keyname",
                        "help": "Keyname to delete", 
                        "type": "string",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinsihed": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    },
                    {
                        "key": "keyname",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Keys",
                "id": "keys",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "help": "Object to get keys of",
                        "type": "object",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "forcevisible": true 
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Values",
                "id": "values",
                "inputschema":[
                    {
                        "key": "object",
                        "label": "Object",
                        "help": "Object to get values of",
                        "type": "object",
                        "required": true,
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[],
                        "forcevisible": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "object",
                        "mapping": ""
                    }
                ],
                "outputschema":[

                ]
            }
        ]

    },
    {
        "type": "number",
        "actions":[
            {
                "name": "Increment",
                "id": "increment",
                "inputschema":[
                    {
                        "key": "number",
                        "label": "Number",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    },
                    {
                        "key": "increment",
                        "label": "Increment",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "number",
                        "mapping": ""
                    },
                    {
                        "key": "increment",
                        "mapping":""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Decrement",
                "id": "decrement",
                "inputschema":[
                    {
                        "key": "number",
                        "label": "Number",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    },
                    {
                        "key": "decrement",
                        "label": "Decrement",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "number",
                        "mapping": ""
                    },
                    {
                        "key": "decrement",
                        "mapping":""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Add",
                "id": "add",
                "inputschema":[
                    {
                        "key": "number1",
                        "label":"Number1",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true
                    },
                    {
                        "key": "number2",
                        "label": "Number2",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "number1",
                        "mapping": ""
                    },
                    {
                        "key": "number2",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "Subtract",
                "id": "subtract",
                "inputschema":[
                    {
                        "key": "number1",
                        "label":"Number1",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "number2",
                        "label": "Number2",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "number1",
                        "mapping": ""
                    },
                    {
                        "key": "number2",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "divide",
                "id": "divide",
                "inputschema":[
                    {
                        "key": "divident",
                        "label":"Divident",
                        "type": "number",
                        "help": "Number 1",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "divisor",
                        "label": "Divisor",
                        "type": "number",
                        "help": "Number 2",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinshed": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "divident",
                        "mapping": ""
                    },
                    {
                        "key": "divisor",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name":"multiply",
                "id": "multiply",
                "inputschema":[
                    {
                        "key": "number1",
                        "label":"Number1",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "number2",
                        "label": "Number2",
                        "type": "number",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "number1",
                        "mapping": ""
                    },
                    {
                        "key": "number2",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "name": "remainder",
                "id": "remainder",
                "inputschema":[
                    {
                        "key": "divident",
                        "label":"Divident",
                        "type": "number",
                        "help": "Number 1",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true
                    },
                    {
                        "key": "divisor",
                        "label": "Divisor",
                        "type": "number",
                        "help": "",
                        "required": true,
                        "subschema":[],
                        "fillallowed": true,
                        "guided": false,
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "divident",
                        "mapping": ""
                    },
                    {
                        "key": "divisor",
                        "mapping": ""
                    }
                ],
                "outputschema":[]            
            },
            {
                "id":"parseint",
                "name": "ParseInt",
                "inputschema":[
                    {
                        "key": "string",
                        "label": "String",
                        "help": "String to parse",
                        "required": true,
                        "type": "string",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[] 
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            },
            {
                "id": "parsefloat",
                "name": "ParseFloat",
                "inputschema":[
                    {
                        "key": "string",
                        "label": "String",
                        "help": "String to parse",
                        "required": true,
                        "type": "string",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema":[]
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "string",
                        "mapping": ""
                    }
                ],
                "outputschema":[]
            }
        ]
    },
    {
        "type": "boolean",
        "actions":[
            {
                "id": "and",
                "name": "And",
                "inputschema": [
                    {
                        "key": "value1",
                        "label": "Value1",
                        "help": "Value 1 of expression",
                        "type": "boolean",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": []
                    },
                    {
                        "key": "value2",
                        "label": "Value2",
                        "help": "Value 2 of expression",
                        "type": "boolean",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": []
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "value1",
                        "mapping": ""
                    },
                    {
                        "key": "value2",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "or",
                "name": "Or",
                "inputschema": [
                    {
                        "key": "value1",
                        "label": "Value1",
                        "help": "Value 1 of expression",
                        "type": "boolean",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": []
                    },
                    {
                        "key": "value2",
                        "label": "Value2",
                        "help": "Value 2 of expression",
                        "type": "boolean",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": []
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "value1",
                        "mapping": ""
                    },
                    {
                        "key": "value2",
                        "mapping": ""
                    }
                ]
            },
            {
                "id": "not",
                "name": "Not",
                "inputschema": [
                    {
                        "key": "value1",
                        "label": "Value1",
                        "help": "Value 1 of expression",
                        "type": "boolean",
                        "fillallowed": true,
                        "guided": false,
                        "guidefinished": true,
                        "subschema": []
                    }
                ],
                "inputschemamapping":[
                    {
                        "key": "value1",
                        "mapping": ""
                    }
                ]
            }
        ]
    }
]

export const availableTypes = [
    {"name": "String", "value": "string"},
    {"name": "Number", "value": "number"},
    {"name": "Object", "value": "object"},
    {"name": "Array", "value": "array"},
    {"name": "Datetime", "value": "datetime"},
    {"name": "Boolean", "value": "boolean"}
]

export const sampleAutomataApps = {
    "app": "automata_schemas",
    "name": "Automata Schemas",
    "type": "automata",
    "logo": WebIcon,
    "actions":[...availableSchemaCommonActions],
    "schemas":[]
}

export const LoopVariableSchema = [
    {
        "key": "array_to_loop",
        "label": "Array to loop over",
        "type": "array",
        "required": true,
        "guided": false,
        "guidefinished": false,
        "subschema":[{"type": "any"}],
        "fillallowed": true,
        "editallowed": true,
        "typechange": false
    }
]

export const LoopVariableSchemaMapping = [
    {
        "key": "array_to_loop",
        "mapping": ""
    }
]

export const DbConditionalOperators = [
    {
        "name": "Equal", "val": "="
    },
    {
        "name": "Greaterthan", "val": ">"
    },
    {
        "name": "Lessthan", "val": "<"
    },
    {
        "name": "Greaterthanorequal", "val": ">="
    },
    {
        "name": "Lessthanorequal", "val": "<="
    },
    {
        "name": "Notequal", "val": "<>"
    },
    {
        "name": "Search", "val": "LIKE"
    },
    {
        "name": "In array", "val": "IN"
    }
]

export const HTTPRESPONSEACTION = [
    {
        "name": "json",
        "id": "http_response_json",
        "inputschema":[
            {
                "key": "output",
                "label": "Output",
                "help": "Output the request",
                "type": "object",
                "fillallowed": true,
                "guided": true,
                "guidefinished": false,
                "subschema":[],
                "editallowed": true,
                "typechange": true
            }
        ],
        "inputschemamapping":[
            {
                "key": "output",
                "mapping": ""
            }
        ]
    },
    {
        "name": "file",
        "id": "http_response_file",
        "inputschema":[
            {
                "key": "file",
                "label": "File",
                "help": "File to be sent",
                "type": "file",
                "fillallowed": true,
                "guided": true,
                "guidefinished": false,
                "subschema": [],
                "editallowed": true,
                "typechange": true
            }
        ],
        "inputschemamapping":[
            {
                "key": "file",
                "mapping": ""
            }
        ]
    },
    {
        "name": "redirect",
        "id": "http_response_redirect",
        "inputschema":[
            {
                "key": "url",
                "label": "Url",
                "help": "Url to redirect the request to",
                "type": "string",
                "fillallowed": true,
                "guided": true,
                "guidefinished": false,
                "subschema":[],
                "editallowed": true,
                "typechange": true
            }
        ],
        "inputschemamapping":[
            {
                "key": "url",
                "mapping": ""
            }
        ],
    },
    {
        "name": "error",
        "id": "http_response_error",
        "inputschema":[
            {
                "key": "error",
                "label": "Error",
                "help": "Error object to send",
                "type": "object",
                "fillallowed": true,
                "guided": true,
                "guidefinished": false,
                "subschema":[],
                "editallowed": true,
                "typechange": true
            }
        ],
        "inputschemamapping":[
            {
                "key": "error",
                "mapping": ""
            }
       ]
    }
]

export const SUBPIPELINERESPONSE = {
    "name": "Pipeline Response",
    "id": "pipeline_response",
    "inputschema":[
        {
            "key": "output",
            "label": "Output",
            "help": "Output of the trigger",
            "type": "object",
            "fillallowed": true,
            "guided": true,
            "guidefinished": false,
            "subschema":[],
            "editallowed": true,
            "typechange": true
        }

    ],
    "inputschemamapping":[
        {
            "key": "output",
            "mapping": ""
        }
    ]
}