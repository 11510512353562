import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTemplatesCount, selectTemplatesCount } from "../../Services/interfaces/interface.slice";
import { getWebsiteCount, selectWebsiteCount } from "../../Services/websites/website.slice";
import { getAppCount, selectAndroidAppCount } from "../../Services/androidapps/android.slice";
import { useParams , Link} from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

const Interfaces = ()=>{

    const dispatch = useDispatch();

    const templatescount = useSelector(selectTemplatesCount);

    const websitecount = useSelector(selectWebsiteCount);

    const androidcount = useSelector(selectAndroidAppCount);

    const {workspace} = useParams();

    useEffect(()=>{
        dispatch(getTemplatesCount(workspace));
        dispatch(getWebsiteCount(workspace));
        dispatch(getAppCount())
    },[])


    return (
        <div>
            <BreadCrumbs
                    breadcrumbs={
                        workspace==""?
                        [
                            {
                                "name": "dashboard",
                                "url": "/"
                            }
                        ]:
                        [
                        {
                            "name": "dashboard",
                            "url": "/"
                        },
                        {
                            "name": "workspace",
                            "url": "/workspaces/"+workspace+"/"
                        }
                ]}
                
                >
            </BreadCrumbs>
            <div className="dashboard-main-container">
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Templates</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/templates/"}>{templatescount}</Link>
                        </h2>
                    </div>
                </div>
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Websites</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/websites/"}>{websitecount}</Link>
                        </h2>
                    </div>
                </div>
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Android Apps</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/androidapps/"}>{androidcount}</Link>
                        </h2>
                    </div>
                </div>
                <div className="dashboard-tiles">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h5>Ios Apps</h5>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h2>
                            <Link to={"/"+workspace+"/iosapps/"}>{0}</Link>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Interfaces;
