import "./TemplateDetailtile.css";

const TemplateDetailTile = (
    {
        element,
        addElementAction
    }
)=>{


    return (
        <>
            <div style={{padding: "20px"}}>
                <div className="container">
                    {element.workspacename!=undefined&&
                        <div className="property-level-1">
                            {element.workspacename}
                        </div>
                    }
                    {element.websitename!=undefined&&
                        <div className="property-level-2">
                            {element.websitename}
                        </div>
                    }
                    <div
                        className="property-level main-property"
                        onClick={() => {addElementAction(element, "templates")}}
                    >
                        {element.name}
                    </div>
                </div>
            </div>
        </>
    )

}


export default TemplateDetailTile;