import { useParams } from "react-router-dom";
import AppContainer from "../AppContainer";
import HtmlNativeElements from "../elements/HtmlNativeElements";
import AndroidNativeElements from "../elements/AndroidNativeElements";
import ToilerElement from "../elements/ToilerElement";

const SingleBlock = ({
                        element, 
                        elmkey, 
                        indexarr,
                        position,
                        type,
                        childs,
                        parentkey,
                        parentposition,
                        parentindexarr,
                        templateinteractions
                    })=>{

        
    return (
        <>
            {type=="android"&&
                <>
                    {element.elemtype=="native"&&
                        <AndroidNativeElements
                            element={element}
                            elmkey={elmkey}
                            indexarr={indexarr}
                            position = {position}
                            type={type}                    
                        >
                        </AndroidNativeElements>
                    }
                    {element.elemtype=="template"&&
                        <AppContainer
                            templateid={element._id}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position = {position}
                            type={type}
                        >
                        </AppContainer>
                    }
                </>
            }
            {type=="html"&&
                <>
                    {element.elemtype=="native"&&
                        <HtmlNativeElements
                            element={element}
                            elmkey={elmkey}
                            indexarr={indexarr}
                            position={position}
                            type={type}    
                            childs={childs}
                            parentkey={parentkey}  
                            parentposition={parentposition}
                            parentindexarr={parentindexarr}
                            templateinteractions={templateinteractions}                 
                        >
                        </HtmlNativeElements>
                    }
                    {element.elemtype=="toiler"&&
                        <ToilerElement
                            element={element}
                            elmkey={elmkey}
                            indexarr={indexarr}
                            position={position}
                            type={type}
                            parentkey={parentkey}
                        >
                        </ToilerElement>

                    }
                    {element.elemtype=="template"&&
                        <AppContainer
                            templateid={element._id}
                            indexarr={indexarr}
                            elmkey={elmkey}
                            position={position}
                            type={type}
                            childs={element.childs}
                            templateinteractions={element.interactions}
                        >
                        </AppContainer>
                    }
                </>
            }
        </>
    )
}

export default SingleBlock;