import { TextField, Button } from "@mui/material";
import Toiler from "./Images/logo1.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotpassrequest, resetforgotpass, selectForgotpasscreated } from "./Services/signin/signin.slice";
import { useNavigate } from "react-router-dom";


const ForgotpassRequest = ()=>{

    const [email, setEmail] = useState("");

    const dispatch = useDispatch();

    const resetPasswordAction = ()=>{
        let payload = {
            "emailid": email
        }
        dispatch(forgotpassrequest(payload));
    }

    const requestcreated = useSelector(selectForgotpasscreated);

    useEffect(()=>{
        dispatch(resetforgotpass());
    },[])


    const navigate = useNavigate();

    const navigatetomain = ()=>{
        navigate("/main")
    }


    return (
        <div style={{display: "flex", justifyContent: "center", "marginTop": 300}}>
            <div style={{width: 500}}>
                <div style={{display: "flex", justifyContent:"center", marginBottom: 20}} onClick={()=>{navigatetomain()}}>
                    <img src={Toiler}></img>
                </div>
                {requestcreated?
                    <>
                        <div>
                            We have sent you an email, please check your email for further instructions to change password.
                        </div>
                    </>:
                    <>
                        <div style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
                            <TextField value={email} label="Enter email" onChange={(e)=>{setEmail(e.target.value)}}></TextField>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
                            <Button style={{color: "#3A07CD"}} onClick={()=>{resetPasswordAction()}}>
                                Reset Password
                            </Button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}


export default ForgotpassRequest;