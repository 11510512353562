import TemplateDetailTile from "./TemplateDetailtile"
import { useState, useCallback, useEffect } from "react";
import { get } from "../../Services/signin/axios";
import { baseURL } from "../../env";
import { useParams } from "react-router-dom";
import * as _ from "lodash";
import SearchAutoComplete from "../SearchAutoComplete";

const TemplateGrid = ({
                        scrollref,
                        source,
                        addElementAction
                      })=>{
    
        const [templates, setTemplates] = useState([]); // Initial set of apps
        const [searchQuery, setSearchQuery] = useState(''); // Search query state
        const [filteredTemplates, setFilteredTemplates] = useState([]); // Filtered apps based on search
        const [page, setPage] = useState(1); // Current page for pagination
        const [loading, setLoading] = useState(false); // Loading state
        const [hasMore, setHasMore] = useState(true); // State to check if more data is available
     
        const {workspace} = useParams();

        // Load more apps when scrolling near the end
        const loadMoreTemplates = useCallback(() => {
            setPage((prevPage) => prevPage + 1); // Increase page number
        }, []);

        // Ref for the IntersectionObserver
        const observerRef = useCallback((node)=>{
            const observer = new IntersectionObserver(
                (entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    loadMoreTemplates();
                }
                },
                {
                    root: scrollref.current, 
                    threshold: 1
                }
            );
            if (node) {
                observer.observe(node);
            }
            return () => observer.disconnect();
        },[loadMoreTemplates, hasMore, scrollref]);

        // Fetch apps when page changes or search query is updated
        useEffect(() => {
            async function fetchTemplates() {
                if(loading!=true&&hasMore&&source!=undefined&&source!=""){
                    setLoading(true);
                    try {
                        const data = await get(`${baseURL}variables/gettemplates/${workspace}?q=${encodeURIComponent(searchQuery)}&page=${page}&limit=15`)
                        // Append new apps and update state
                        setTemplates((prevTemplates) => {
                            let newtemplates = [];
                            for(let i=0; i< data.results.length; i++){
                                let appindex = _.findIndex(prevTemplates, (app)=>{return data.results[i]._id==app._id});
                                if(appindex==-1){
                                    newtemplates.push(data.results[i]);
                                }
                            }
                            return [...prevTemplates, ...newtemplates]
                        });
                        setHasMore(data.results.length > 0); // Check if more data is available
                    } catch (error) {
                        console.error('Error fetching apps:', error);
                    } finally {
                        setLoading(false);
                    }
                }
            }
            fetchTemplates()
        }, [searchQuery, page, hasMore, source]);

        useEffect(() => {
            setFilteredTemplates(
              templates.filter((template) =>{
                let searchstring = template.name.toLowerCase();
                if(template.workspacename!=undefined&&template.workspacename!=""){
                    searchstring = searchstring+template.workspacename.toLowerCase()
                }
                if(template.websitename!=undefined&&template.websitename!=""){
                    searchstring = searchstring+template.websitename.toLowerCase();
                }
                return searchstring.includes(searchQuery.toLowerCase());
              }  
              )
            );
          }, [searchQuery, templates]);

        const handleSearchChange = (value) => {
            setSearchQuery(value);
            setTemplates([]); // Reset apps when search query changes
            setPage(1);  // Reset page when search query changes
            setHasMore(true); // Reset hasMore state for new search
          };
        

    return (
        <>

        <div style={{ padding: '20px' }}>
            {/* Search Bar with Autocomplete */}
            <SearchAutoComplete
                suggestions={templates.map((template) => template.name)}
                onChange={handleSearchChange}
            />
            {/* Responsive Grid */}
            <div
                style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                        gap: '20px',
                        padding: '20px',
                      }}
            >
                {filteredTemplates.map((app) => (
                    <>
                        <TemplateDetailTile
                            element={app}
                            addElementAction={addElementAction}
                        >
                        </TemplateDetailTile>
                    </>
                ))}
            </div>

            {/* Invisible element to trigger loading more apps when scrolled into view */}
            <div 
                ref={observerRef} 
                style={{ height: '20px' }} 
            />
        </div>
    </>
    )
}

export default TemplateGrid;