import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { StyleSettings } from './Settings';
import * as _ from "lodash";
import "./StylesToolbar.css";
import ColorPicker from './components/ColorPicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveElement, setElement , selectActivePosition, selectTemplateClasses, classNameFactory, updateTemplateClasses, getTemplateClasses} from '../../Services/apptemplates/apptemplates.slice';
import {conditionalActions} from "../Settings";
import {ValueCompareToField, ValuetoCompareField} from "../Motors/blocks/ConditionalStageBlock";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { filterValues } from './utils';

const StyleConditions = ({
    style,
    setStyle,
    templateid
})=>{

    const setConditions = (conditions)=>{
        let styleCopy = {...style,
                         conditions: conditions
                        }
        setStyle(styleCopy)
    }


    const selectCondition = (blockindex, conditionindex, condition)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      condition: condition
                                                     }
        setConditions(conditionsCopy);
    }

    const setGetValuetocompare = (blockindex, conditionindex)=>{
        return (val, type)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
            let allconditions = [...conditionalActions];
            let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type==type})
            let options = [...allconditions[selectedtypeindex].operators];
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                          valuetocompare:{"action": "get", "val": val.schemaval},
                                                          options: options,
                                                          "comparetotype": type
                                                         }
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValuetocompare = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
        let allconditions = [...conditionalActions];
        let selectedtypeindex = _.findIndex(allconditions, (cc)=>{return cc.type=="string"})
        let options = [...allconditions[selectedtypeindex].operators];
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                      valuetocompare:{"action": "const", "val": val},
                                                      options: options,
                                                      "comparetotype": "string"
                                                    }
        setConditions(conditionsCopy);
    }

    const getValueToCompare = (blockindex, conditionindex)=>{
        if(style.conditions[blockindex][conditionindex].valuetocompare.val){
            return style.conditions[blockindex][conditionindex].valuetocompare.val;
        }else{
            return ""
        }
        
    }

    const setGetValueCompareTo = (blockindex, conditionindex)=>{
        return (val)=>{
            let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
            conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                            valuecompareto:{"action": "get", "val": val.schemaval}
                                                         }    
            setConditions(conditionsCopy);    
        }   
    }

    const setConstValueCompareTo = (blockindex, conditionindex, val)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
        conditionsCopy[blockindex][conditionindex] = {...conditionsCopy[blockindex][conditionindex],
                                                        valuecompareto:{"action": "const", "val": val}
                                                     }
        setConditions(conditionsCopy);
    }

    const getValueCompareTo = (blockindex, conditionindex)=>{
        if(style.conditions[blockindex][conditionindex].valuecompareto.val){
            return style.conditions[blockindex][conditionindex].valuecompareto.val
        }else{
            return "";
        }
    }

    const deleteConditionBlock = (i)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
        conditionsCopy.splice(i,1);
        setConditions(conditionsCopy);
    }

    const deleteCondition = (blockindex, conditionindex)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
        let conditionBlockCopy= [...conditionsCopy[blockindex]];
        conditionBlockCopy.splice(conditionindex, 1);
        conditionsCopy[blockindex] = conditionBlockCopy;
        setConditions(conditionsCopy);    
    }

    const addConBlock = ()=>{
        let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
        conditionsCopy.push([]);
        setConditions(conditionsCopy);
    }

    const addCondition = (blockindex)=>{
        let conditionsCopy = JSON.parse(JSON.stringify(style.conditions));
        conditionsCopy[blockindex].push(
            {
                "valuetocompare":"",
                "tocompareopen":false,
                "comparetotype": "any",
                "options":[],
                "condition": "",
                "valuecompareto":"",
                "comparetoopen":false
            }   
        )
        setConditions(conditionsCopy);
    }

    return (
        <>
            {style.conditions.map((conblock, i)=>{
                return (
                    <>
                        {i==0?
                            <div>Only apply if</div>:
                            <div className="or-block-desc">
                                <div>Or apply if</div>
                                <div>
                                    <IconButton onClick={()=>{deleteConditionBlock(i)}}>
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                        }
                        {conblock.map((con, j)=>{
                            return(
                                    <div className="condition-line">

                                        <div>
                                            <ValuetoCompareField
                                                i={i}
                                                j={j}
                                                setConstValuetocompare={setConstValuetocompare}
                                                setGetValuetocompare={setGetValuetocompare}
                                                pipelinestage={0}
                                                pipelinetype={""}
                                                getValueToCompare={getValueToCompare}
                                                source={"appprops"} 
                                                templateid={templateid}                                           
                                            >
                                            </ValuetoCompareField>
                                        </div>
                                        <div>
                                            <FormControl sx={{width: 200}}>
                                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={con.condition}
                                                    label="Condition"
                                                    onChange={(e)=>{selectCondition(i,j, e.target.value)}}
                                                >
                                                    {con.options.map((op)=>{
                                                        return(
                                                            <MenuItem value={op.value}>{op.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <ValueCompareToField
                                                i={i}
                                                j={j}
                                                getValueCompareTo={getValueCompareTo}
                                                setConstValueCompareTo={setConstValueCompareTo}
                                                setGetValueCompareTo={setGetValueCompareTo}
                                                pipelinestage={undefined}
                                                pipelinetype={""}
                                                templateid={templateid}
                                                source={"appprops"}
                                            >
                                            </ValueCompareToField>
                                        </div>
                                        <div className="close-button" onClick={()=>{deleteCondition(i,j)}}>
                                            <span class="material-symbols-outlined">
                                                close
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {i==(style.conditions.length-1)?
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addConBlock()}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            Or
                                    </div>
                                </div>
                            </>:
                            <>
                                <div className="condition-operators">
                                    <div className="condition-operator" style={{cursor: "pointer"}} onClick={()=>{addCondition(i)}}>
                                        <span class="material-symbols-outlined">
                                            add
                                        </span>
                                            And
                                    </div>
                                </div>
                            </>
                        }
                        <div style={{height:"2px" , backgroundColor:"#eee", "width": "100%"}}></div>
                    </>
                )
            })}
        </>
    )
}

const NumberInput = ({
                        style,
                        propSettings,
                        setStyle
                    })=>{
    
    const setValue = (val)=>{
        let styleCopy = {...style,
                         value: val
                        };
        setStyle(styleCopy)
    }
    
    const setUnit = (val)=>{
        let styleCopy = {...style,
                         unit: val
                        }
        setStyle(styleCopy)
    }

    return (
        <>
            {propSettings!=undefined&&
                <div style={{display: "flex"}}>
                    <div style={{padding: "5px"}}>
                        <input type="number" style={{maxWidth: "60px"}} value={style.value} onChange={(e)=>{setValue(e.target.value)}}></input>
                    </div>
                    <div style={{padding: "5px"}}>
                        <select
                            value={style.unit}
                            onChange={(e)=>{setUnit(e.target.value)}}
                        >
                            {propSettings.map((s)=>{
                                return(
                                    <option
                                        value={s}
                                    >
                                        {s}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div> 
            }
        </>
    )

}

const ValuesInput = ({
                        style,
                        propSettings,
                        setStyle
                    })=>{

    const setValue = (val)=>{
        let styleCopy = {...style,
                         value: val
                        };
        setStyle(styleCopy);
    }

    
    return (
        <>
            {propSettings!=undefined&&
                <div>
                    <select
                        value={style.value}
                        onChange={(e)=>{setValue(e.target.value)}}
                    >
                        {propSettings.map((s)=>{
                            return(
                                <option
                                    value={s}
                                >
                                    {s}
                                </option>
                            )
                        })}
                    </select>
                </div> 
            }
        </>
    )

}


const TextInput = ({
    style,
    propSettings,
    setStyle
})=>{

    const setValue = (val)=>{
        let styleCopy = {...style,
                         value: val
                        };
        setStyle(styleCopy);
    }

    return (
        <>
            <div>
                <input value={style.value} onChange={(e)=>{setValue(e.target.value)}}></input>
            </div>
        </>
    )

}

const FileInput = ()=>{


}


const ColorInput = ({
    style,
    propSettings,
    setStyle
})=>{

    const setValue = (val)=>{
        let styleCopy = {...style,
                         value: val
                        }
        setStyle(styleCopy);
    }

    return (
        <>
            <ColorPicker
                color={style.value}
                setColor={setValue}
            >
            </ColorPicker>
        </>
    )
}


const StepsInput = ({
                        style,
                        propSettings,
                        setStyle
                    })=>{

    const setStyleType = (val)=>{
        let styleCopy = {...style,
                         type: val,
                         value: undefined,
                         unit: undefined
                        }
        setStyle(styleCopy);
    }

    const getActivePropSettings = ()=>{
        let activePropSettings = [];
        if(style.type!=undefined&&propSettings!=undefined){
            for(let i=0; i<propSettings.length; i++){
                if(propSettings[i].type==style.type){
                    if(propSettings[i].type=="value"){
                        activePropSettings = propSettings[i].values
                    }else if(propSettings[i].type=="number"){
                        activePropSettings = propSettings[i].units
                    }
                }
            }
            return activePropSettings;
        }
    }


    return(
        <>
            {propSettings!=undefined&&
                <div>
                    <div style={{display: "flex", cursor: "pointer"}}>
                        {propSettings.map((s)=>{
                            return (
                                <div className={style.type==s.type?"step-headers active":"step-headers"} onClick={()=>{setStyleType(s.type)}}>
                                    {s.name}
                                </div>
                            )
                        })}
                    </div>
                    <div style={{display: "flex", justifyContent:"center", "alignItems": "center"}}>
                        <div>
                            {style.type=="value"&&
                                <ValuesInput
                                    style={style}
                                    setStyle={setStyle}
                                    propSettings={getActivePropSettings()}
                                >
                                </ValuesInput>
                            }
                            {style.type=="number"&&
                                <NumberInput
                                    style={style}
                                    setStyle={setStyle}
                                    propSettings={getActivePropSettings()}
                                >
                                </NumberInput>
                            }
                            {style.type=="steps"&&
                                <StepsInput
                                    style={style}
                                    setStyle={setStyle}
                                    propSettings={getActivePropSettings()}
                                >
                                </StepsInput>                
                            }
                            {style.type=="text"&&
                                <TextInput
                                    style={style}
                                    setStyle={setStyle}
                                    propSettings={getActivePropSettings()}
                                >
                                </TextInput>
                            }
                            {style.type=="color"&&
                                <ColorInput
                                    style={style}
                                    setStyle={setStyle}
                                >
                                </ColorInput>
                            }
                        </div>
                    </div>
                    
                </div>
            }
        </>
    )
}

export const StyleProperty = ({
                        style,
                        setStylesAction,
                        removeStyleAction,
                        templateid,
                        settings
                        })=>{

    const [options, setOptions] = useState([]);

    const [styletype, setStyletype] = useState("");

    const [inputvalue, setInputValue] = useState("");

    const [propSettings, setPropSettings] = useState(undefined);

    const getProperties = ()=>{
        let props = [];
        for(let i=0; i< StyleSettings.length; i++){
            props.push(StyleSettings[i].name)
        }
        return props;
    }

    const filteroptions = (phrase)=>{
        let optionsCopy = getProperties();
        let resp = filterValues(optionsCopy, phrase);
        setOptions(resp);
        setInputValue(phrase);
    }

    const setValue = (name)=>{
        let propvalueIndex = _.findIndex(StyleSettings, (s)=>{ return s.name==name});
        if(propvalueIndex>-1){
            let prop = StyleSettings[propvalueIndex];
            let styleCopy = {...style};
            if(prop.type=="value"){
                setPropSettings(prop.values);
                styleCopy = {...styleCopy,
                             name: name,
                             type: prop.type
                            }
                setStyletype(prop.type);
            }else if(prop.type=="number"){
                setPropSettings(prop.units);
                styleCopy = {...styleCopy,
                             name: name,
                             type: prop.type
                            }
                setStyletype(prop.type);
            }else if(prop.type=="steps"){
                setPropSettings(prop.steps);
                styleCopy = {...styleCopy,
                             name: name,
                             type: prop.default
                            }
                setStyletype(prop.type);
            }else{
                styleCopy = {...styleCopy,
                             name: name,
                             type: prop.type
                            }
                setStyletype(prop.type)
                
            }
            setStylesAction(styleCopy);
        }
    }

    useEffect(()=>{
        let optionsCopy = getProperties();
        setOptions(optionsCopy);
        if(style.type!=""&&style.name!=""&&style.type!=undefined&&style.type!=undefined){
            let propvalueIndex = _.findIndex(StyleSettings, (s)=>{ return s.name==style.name});
            let prop = StyleSettings[propvalueIndex];
            if(prop.type=="value"){
                setPropSettings(prop.values);
                setStyletype(style.type);
            }else if(prop.type=="number"){
                setPropSettings(prop.units)
                setStyletype(style.type);
            }else if(prop.type=="steps"){
                setPropSettings(prop.steps);
                setStyletype("steps")
            }else{
                setStyletype(style.type);
            }
        }
    },[])

    const changeStyleBlockType = (val)=>{
        if(val=="block"){
            let styleCopy = {...style,
                             blocktype: val,
                             conditions: [[]]
                            }
            setStylesAction(styleCopy);

        }else if(val=="conditional"){
            let styleCopy = {...style,
                             blocktype: val,
                             conditions:[[
                                {
                                    "valuetocompare":"",
                                    "tocompareopen":false,
                                    "comparetotype": "any",
                                    "options":[],
                                    "condition": "",
                                    "valuecompareto":"",
                                    "comparetoopen":false
                                }
                               ]]
                            }
            setStylesAction(styleCopy);
        }
        
    }


    return (
        <div>
            <div style={{paddingRight:10,paddingLeft: 10, display: "flex", alignItems: "center"}}>
                <div>
                    <Autocomplete
                        id="combo-box-demo"
                        options={options}
                        value={style.name}
                        inputValue={inputvalue}
                        onChange={(e, val)=>{setValue(val)}}
                        filterOptions={(x) => (x)}
                        onInputChange={(event, newInputValue) => {
                            filteroptions(newInputValue);
                          }}
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref} style={{minWidth: "100px"}}>
                                <input style={{minWidth: "100px"}} type="text" {...params.inputProps} />
                            </div>
                        )}
                    />
                </div>
                <div style={{marginLeft: "5px"}}>
                    {styletype=="value"&&
                        <ValuesInput
                            style={style}
                            setStyle={setStylesAction}
                            propSettings={propSettings}
                        >
                        </ValuesInput>
                    }
                    {styletype=="number"&&
                        <NumberInput
                            style={style}
                            setStyle={setStylesAction}
                            propSettings={propSettings}
                        >
                        </NumberInput>
                    }
                    {styletype=="steps"&&
                        <StepsInput
                            style={style}
                            setStyle={setStylesAction}
                            propSettings={propSettings}
                        >
                        </StepsInput>                
                    }
                    {styletype=="text"&&
                        <TextInput
                            style={style}
                            setStyle={setStylesAction}
                            propSettings={propSettings}
                        >
                        </TextInput>
                    }
                    {styletype=="color"&&
                        <ColorInput
                            style={style}
                            setStyle={setStylesAction}
                        >
                        </ColorInput>
                    }
                </div>
                <div style={{cursor: "pointer"}} onClick={()=>{removeStyleAction()}}>
                    <span className="material-symbols-outlined">close</span>
                </div>
            </div>
        </div>
    )

}


const StylesToolbar = ({
                        templateid,
                        settings
                    })=>{

    const [scsystems, setScsystems] = useState([]);

    const [barActive, setBarActive] = useState("");
    
    const position = useSelector(selectActivePosition)

    const element = useSelector(selectActiveElement(position));

    const [userclasses, setUserclasses] = useState([]);

    const dispatch = useDispatch();
    
    const availableStates = [
        "default",
        "hover",
        "active",
        "focus",
        "focus-within",
        "visited",
        "focus-visible"
    ]

    const addUserClass = ()=>{
        let scuserCopy = [...element.scusers];
        scuserCopy.push({
            "name": "",
            "blocktype": "block",
            "conditions":[[]]
        });
        let elementCopy = {...element,
                           scusers: scuserCopy
                          }
        dispatch(setElement(
            {
                position: position,
                element: elementCopy
            }
        ))
    }

    const setUserClass = (val, index)=>{
        let scuserCopy = [...element.scusers];
        scuserCopy[index] = {...scuserCopy[index],
                             name: val
                            };
        let elementCopy = {...element,
                           scusers:  scuserCopy
                          }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    const setUserClassBlocktype = (blocktype, index)=>{
        let scuserCopy = [...element.scusers];
        scuserCopy[index] = {...scuserCopy[index],
                             blocktype: blocktype
                            }
        let elementCopy = {...element,
                            scusers: scuserCopy
                          }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    const deleteUserClass = (index)=>{
        let scuserCopy = [...element.scusers];
        scuserCopy.splice(index,1);
        let elementCopy = {...element,
                           scusers: scuserCopy
                          }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    const setUserClassConditions = (index)=>{
        return (cls)=>{
            let scuserCopy = [...element.scusers];
            scuserCopy[index] = cls;
            let elementCopy = {...element,
                                scusers: scuserCopy
                              }
            dispatch(setElement({
                position: position,
                element: elementCopy
            }))
        }
    }

    const userclassesremote = useSelector(selectTemplateClasses);

    useEffect(()=>{
        if(userclassesremote.length>0){
            let userclassescopy = [];
            for(let i=0; i < userclassesremote.length; i++){
                if(userclassesremote[i].type=="user"){
                    userclassescopy.push(userclassesremote[i].name)
                }
            }
            setUserclasses(userclassescopy);
        }
    },[userclassesremote])

    useEffect(()=>{
        if(userclassesremote!=undefined&&userclassesremote.length>0){
            let remoteclassesCopy = [...userclassesremote];
            setScsystems(remoteclassesCopy);
        }
    },[userclassesremote])

    useEffect(()=>{
        dispatch(getTemplateClasses(templateid))
    },[])

    const setScsystemsAction = (clss)=>{
        dispatch(updateTemplateClasses({
            templateid: templateid,
            classes: clss
        }))
        setScsystems(clss)
    }

    // system classes
    const addSystemClass = ()=>{
        let scsystemsCopy = [...scsystems];
        let classname = classNameFactory();
        let scsystemnames = [...element.scsystems];
        scsystemnames.push({
            "name": classname,
            "blocktype": "block",
            "conditions":[[]]
        });
        let elementCopy = {...element,
                            scsystems: scsystemnames
                           }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
        scsystemsCopy.push({
            "name": classname,
            "type": "system",
            "state": "default",
            "styles": [] 
        })
        setScsystemsAction(scsystemsCopy);
    }

    const deleteSystemClass = (index)=>{
        let scsystemsCopy = [...scsystems];
        scsystemsCopy.splice(index,1);
        let scsystemnames = [...element.scsystems];
        scsystemnames.splice(index, 1);
        let elementCopy = {...element,
                           scsystems: scsystemnames
                          }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
        setScsystemsAction(scsystemsCopy);
    }

    const changeStyleBlockType = (blocktype, index)=>{
        let scsystems = [...element.scsystems];
        scsystems[index] = {...scsystems[index],
                            blocktype: blocktype
                            }
        let elementCopy = {...element,
                            scsystems: scsystems
                          }
        dispatch(setElement({
            position: position,
            element: elementCopy
        }))
    }

    const setScSystemState = (state, index)=>{
        let scsystemsCopy = [...scsystems];
        scsystemsCopy[index] = {...scsystemsCopy[index],
                                state: state
                               }

        setScsystemsAction(scsystemsCopy)
    }

    const setClassConditions = (index)=>{
        return(scsystem)=>{
            let scsystems = [...element.scsystems];
            scsystems[index] = scsystem;
            let elementCopy = {...element,
                                scsystems: scsystems
                            }
            dispatch(setElement({
                position: position,
                element: elementCopy
            }))
        }
    }

    const setStyles= (style, index)=>{
        let scsystemsCopy = [...scsystems];
        scsystemsCopy[index] = {...scsystemsCopy[index],
                                styles: style
                                }
        setScsystemsAction(scsystemsCopy)
    }

    const addStyle = (index)=>{
        let stylesCopy = [...scsystems[index].styles];
        stylesCopy.push({
            "name": "",
            "value": "",
        })
        setStyles(stylesCopy, index);
    }

    const setStylesAction = (i, index)=>{
        return (style)=>{
            let stylesCopy = [...scsystems[index].styles];
            stylesCopy[i] = style;
            setStyles(stylesCopy, index);
        }
    }

    const removeStyle = (i, index)=>{
        return ()=>{
            let stylesCopy = [...scsystems[index].styles];
            stylesCopy.splice(i,1);
            setStyles(stylesCopy, index);
        }
    }

    const isElementClass = (name)=>{
        let iselm = false;
        let nameIndex = _.findIndex(element.scsystems, (n)=>{return n.name==name});
        if(nameIndex>-1){
            iselm = true
        }
        return iselm;
    }

    return (
        <>

            {element!=undefined&&
                <>
                    <div style={{display: "flex", "justifyContent": "space-between" , "paddingLeft": "10px", "paddingRight": "10px"}}>
                        <div 
                            className={barActive!="user"?"style-classes-chooser active":"style-classes-chooser"}
                            onClick={()=>{setBarActive("system")}}
                        >
                            styles
                        </div>
                        <div 
                            className={barActive=="user"?"style-classes-chooser active":"style-classes-chooser"}
                            onClick={()=>{setBarActive("user")}}
                        >
                            classes
                        </div>
                    </div>
                    {barActive=="user"?
                        <>
                            {element.scusers.map((cls, index)=>{
                                return(
                                    <div style={{
                                        boxShadow:"0px 4px 8px 0 rgba(0,0,0, 0.2)"
                                    }}>
                                        <div style={{
                                                    minWidth: "250px", 
                                                    display: "flex", 
                                                    justifyContent: "space-between", 
                                                    "padding": "10px",
                                                    "alignItems": "center",
                                                }}>
                                            <div>
                                                class:
                                            </div>
                                            <div>
                                                <select 
                                                    style={{minWidth: "80px"}}
                                                    onChange={(e)=>{setUserClass(e.target.value, index)}}
                                                    value={cls.name}
                                                >
                                                    <option value="">select</option>
                                                    {
                                                        userclasses.map((cl)=>{
                                                            return (
                                                                <option value={cl}>{cl}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <IconButton onClick={()=>{setUserClassBlocktype("block", index)}}>
                                                    <span className={cls.blocktype=="block"?"material-symbols-outlined active":'material-symbols-outlined'}>stack</span>
                                                </IconButton>
                                                <IconButton onClick={()=>{setUserClassBlocktype("conditional", index)}}>
                                                    <span className={cls.blocktype=="conditional"?"material-symbols-outlined active":"material-symbols-outlined"}>device_hub</span>
                                                </IconButton>
                                                <IconButton onClick={()=>{deleteUserClass(index)}}>
                                                    <span className='material-symbols-outlined'>
                                                        delete
                                                    </span>
                                                </IconButton>
                                            </div>    
                                        </div>
                                        {cls.blocktype=="conditional"&&
                                            <StyleConditions
                                                style={cls}
                                                setStyle={setUserClassConditions(index)}
                                                templateid={templateid}
                                            
                                            >
                                            </StyleConditions>
                                        }
                                    </div>
                                )
                                
                            })}
                            <div style={{
                                            display: "flex", 
                                            justifyContent: "center"
                                        }}>
                                <IconButton onClick={()=>{addUserClass()}}>
                                    <span className='material-symbols-outlined'>
                                        add
                                    </span>
                                </IconButton>
                            </div>
                        </>
                        :
                        <div>
                            {scsystems.map((scsystem, index)=>{
                                return (
                                    <>
                                        {scsystem.type=="system"&&isElementClass(scsystem.name)&&
                                            <div className='scsytem-container'>
                                                <div className="scsytemclass-header">
                                                    <div>
                                                        <select 
                                                            value={scsystem.state} 
                                                            onChange={
                                                                (e)=>{setScSystemState(e.target.value, index)}
                                                            }
                                                        >
                                                            {availableStates.map((state)=>{
                                                                return(
                                                                    <option
                                                                        value={state}
                                                                    >
                                                                        {state}
                                                                    </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="">
                                                        <div>
                                                            <IconButton onClick={()=>{changeStyleBlockType("block", index)}}>
                                                                <span className={element.scsystems[index]?.blocktype=="block"?"material-symbols-outlined active":'material-symbols-outlined'}>stack</span>
                                                            </IconButton>
                                                            <IconButton onClick={()=>{changeStyleBlockType("conditional", index)}}>
                                                                <span className={element.scsystems[index]?.blocktype=="conditional"?"material-symbols-outlined active":"material-symbols-outlined"}>device_hub</span>
                                                            </IconButton>
                                                            <IconButton onClick={()=>{deleteSystemClass(index)}}>
                                                                <span className='material-symbols-outlined'>
                                                                    delete
                                                                </span>
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                {element.scsystems[index]?.blocktype=="conditional"&&
                                                    <div>
                                                        <StyleConditions
                                                            style={element.scsystems[index]}
                                                            setStyle={setClassConditions(index)}
                                                            templateid={templateid}
                                                        >
                                                        </StyleConditions>
                                                    </div>
                                                }
                                                <div className=''>
                                                    {scsystem?.styles.map((style, i)=>{
                                                        return (
                                                            <div>
                                                                <StyleProperty
                                                                    style = {style}
                                                                    setStylesAction = {setStylesAction(i, index)}
                                                                    removeStyleAction={removeStyle(i, index)}
                                                                    templateid={templateid}
                                                                    settings={settings}
                                                                >
                                                                </StyleProperty>
                                                            </div>
                                                        )
                                                    })}
                                                    <div style={{display: "flex", "justifyContent": "center"}}>
                                                        <Tooltip>
                                                            <IconButton onClick={()=>{addStyle(index)}}>
                                                                <span className="material-symbols-outlined">
                                                                    add
                                                                </span>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>    
                                        }
                                    </>
                                    
                                )
                            })
                            }
                            <div className="scsystem-add">
                                <IconButton onClick={()=>{addSystemClass()}}>
                                    <span className='material-symbols-outlined'>
                                        add
                                    </span>
                                </IconButton>
                            </div>
                        </div>
                    }
                </> 
            }
        </>
    )


}


export default StylesToolbar;