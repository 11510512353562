import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAppCountApi, getFolderCountApi, getInterfaceCountApi, getWorkspaceCountApi, getWorkspaceDetailsApi} from "./dashboard.service";
import { loading, loadingdone } from "../loader/loader.slice";
import { showError } from "../error.slice";

// export const getAppCount = createAsyncThunk(
//     "dashboard/getAppCount",
//     async(payload,{dispatch, rejectWithValue})=>{
//         dispatch(loading());
//         let res = await getAppCountApi(payload);
//         dispatch(loadingdone());
//         return res;
//     }
// )


// export const getInterfaceCount = createAsyncThunk(
//     "dashboard/getInterfaceCount",
//     async(payload, {dispatch, rejectWithValue})=>{
//         dispatch(loading());
//         let res = await getInterfaceCountApi(payload);
//         dispatch(loadingdone());
//         return res;
//     }
// )

// export const getFolderCount = createAsyncThunk(
//     "dashboard/getfoldercount",
//     async(payload, {dispatch, rejectWithValue})=>{
//         try{
//             let res = await getFolderCountApi(payload);
//             return res;
//         }catch(error){
//             dispatch(showError("Error fetching folder count"))
//             throw error;
//         }
//     }
// )

// export const getWorkspaceCount = createAsyncThunk(
//     "dashboard/getworkspacecount",
//     async(payload, {dispatch, rejectWithValue})=>{
//         try{
//             let res = await getWorkspaceCountApi(payload);
//             return res;
//         }catch(error){
//             dispatch(showError("Error fetching workspace count"));
//             throw error;
//         }
//     }
// )

export const getWorkspaceDetails = createAsyncThunk(
    "dashborad/getworkspacedetails",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWorkspaceDetailsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error fetching workspace count"));
            throw error;
        }
    }
)

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        detials: null
    },
    reducers: {

    },
    extraReducers: (builder)=>{
        builder.addCase(getWorkspaceDetails.fulfilled, (state, action)=>{
            state.detials = action.payload;
        })
    }
})

export const selectWorkspaceDetails = state => state.dashboard.detials;

export default dashboardSlice.reducer;