import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loading, loadingdone } from "../loader/loader.slice";
import errorSlice, { showError } from "../error.slice";
import { 
            createCollaborationAPi, 
            deleteCollaborationApi, 
            getCollabWorkspacesApi, 
            getCollaboratorApi, 
            getCollaboratorsApi, 
            getDomainsApi, 
            makeCollaborationActiveApi, 
            updateCollaborationApi 
        } from "./collaborators.service";

export const createCollaboration = createAsyncThunk(
    "collaborators/createcollaboration",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createCollaborationAPi(payload);
            if(res.status){
                return res;
            }else{
                dispatch(showError(res.message));
                throw new Error("Error creating collaboration");
            }
        }catch(error){
            dispatch(showError("Error creating collaboration"));
            throw error
        }

    }
)

export const getCollaborators = createAsyncThunk(
    "collaborators/getcollaborators",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getCollaboratorsApi();
            return res;
        }catch(error){
            dispatch(showError("Error fetching collaborators"));
            throw error;
        }
    }
)


export const getCollaborator = createAsyncThunk(
    "collaborators/getcollaborator",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getCollaboratorApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error fetching collaborator"));
            throw error;
        }
    }
)

export const updateCollaboration = createAsyncThunk(
    "collaborators/upadtecollaboration",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateCollaborationApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating collaborator"));
            throw error;
        }
    }
)


export const deleteCollaboration = createAsyncThunk(
    "collaborators/deletecollaboration",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteCollaborationApi(payload);
            dispatch(getCollaborators());
            return res;
        }catch(error){
            dispatch(showError("Error deleting collaborator"));
            throw error;
        }
    }
)

export const getCollabWorkspaces = createAsyncThunk(
    "collaborators/getworkspaces",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getCollabWorkspacesApi();
            return res;
        }catch(error){
            dispatch(showError("Error getting workspaces details"));
            throw error;
        }
    }
)

export const makeCollaborationActive = createAsyncThunk(
    "collaborators/makecollaborationactive",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await makeCollaborationActiveApi(payload);
            if(res.status){
                return res;
            }else{
                dispatch(showError(res.message));
                throw new Error(res.message);
            }
        }catch(error){
            dispatch(showError("Error making collaboration active"));
            throw error;
        }
    }
)

export const getDomains = createAsyncThunk(
    "collaborators/getdomains",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getDomainsApi();
            return res;
        }catch(error){
            dispatch(showError("Error getting hosts"));
            throw error;
        }
    }
)


const collaborationSlice = createSlice({
    name: "collaborators",
    initialState: {
        workspaces:[],
        collaborationcreated: false,
        collaborationupdated: false,
        collaborationdeleted: false,
        collaborators: [],
        collaborator: null,
        activeaction: "init",
        domains: []
    },
    reducers: {
        resetcollaborationcreated: (state, action)=>{
            state.collaborationcreated = false;
        },
        resetcollaborationupdated: (state, action)=>{
            state.collaborationupdated = false;
        },
        resetcollaborationdeleted: (state, action)=>{
            state.collaborationdeleted = false;
        },
        resetcollabactiveaction: (state, action)=>{
            state.activeaction = "init";
        },
        resetcollaborator: (state, action)=>{
            state.collaborator = null;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(createCollaboration.fulfilled, (state, action)=>{
            state.collaborationcreated = true
        })
        builder.addCase(getCollaborators.fulfilled, (state, action)=>{
            state.collaborators = action.payload;
        })
        builder.addCase(getCollaborator.fulfilled, (state, action)=>{
            state.collaborator = action.payload;
        })
        builder.addCase(updateCollaboration.fulfilled, (state, action)=>{
            state.collaborationupdated = true
        })
        builder.addCase(deleteCollaboration.fulfilled, (state, action)=>{
            state.collaborationdeleted = true;
        })
        builder.addCase(getCollabWorkspaces.fulfilled, (state, action)=>{
            state.workspaces = action.payload;
        })
        builder.addCase(makeCollaborationActive.fulfilled, (state, action)=>{
            state.activeaction = action.payload.action;
        })
        builder.addCase(getDomains.fulfilled, (state, action)=>{
            state.domains = action.payload;
        })
    }
})


export const {
                resetcollaborationcreated, 
                resetcollaborationdeleted, 
                resetcollaborationupdated,
                resetcollabactiveaction,
                resetcollaborator
            } = collaborationSlice.actions;

export const selectCollaborationcreated = (state) =>state.collaborators.collaborationcreated;
export const selectCollaborationupdated = state => state.collaborators.collaborationupdated;
export const selectCollaborationdeleted = state => state.collaborators.collaborationdeleted;
export const selectCollaborators = state => state.collaborators.collaborators;
export const selectCollaborator = state => state.collaborators.collaborator;
export const selectworkspaces = state => state.collaborators.workspaces;
export const selectapps = state => state.collaborators.apps;
export const selectfolders = state => state.collaborators.folders;
export const selectwebsites = state => state.collaborators.websites;
export const selectCollabWorkspaces = state => state.collaborators.workspaces;
export const selectCollabActiveAction = state => state.collaborators.activeaction;
export const selectDomains = state => state.collaborators.domains;

export default collaborationSlice.reducer;