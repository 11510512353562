import { IconButton } from "@mui/material";
import AppContainer from "../AppContainer";
import "./Preview.css";
import { useParams } from "react-router-dom";

const Preview = ({handleClose})=>{

    const {__source, __id} = useParams();
    
    return (
        <div className="main-container">
            <div className="preview-header">
                <IconButton onClick={()=>{handleClose()}}>
                    <span className="material-symbols-outlined" style={{"color": "#fff"}}>
                        close
                    </span>
                </IconButton>
            </div>
            <AppContainer
                templateid={__id}
                indexarr={[]}
                elmkey={""}
            
            >

            </AppContainer>
        </div>

    )



}

export default Preview;